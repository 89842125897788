import React, { useState, MouseEvent } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Popover from "@mui/material/Popover";
import Checkbox from "@mui/material/Checkbox";
import IconComponent from "../Icons";
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";
import CalendarComponent from "./calendarComponent";

type GetFilter = {
  file_status?: string;
  from_date?: string;
  to_date?: string;
  subject_id?: string;
  type_id?: string;
  alert_status?: string;
};
interface Option {
  label: string;
  sub_code: string;
  id: number;
}

interface OptionAlert {
  label: string;
}

interface TypeOption {
  file_type: string;
  id: number;
}

interface FilterComponentProps {
  setFilters: React.Dispatch<React.SetStateAction<GetFilter>>;
  subjectData: Option[];
  typeData: TypeOption[];
}

interface CustomCheckboxProps extends SvgIconProps {
  checked: boolean;
}

const fileStatusOptions: OptionAlert[] = [
  { label: "Draft" },
  { label: "Scheduled" },
  { label: "Published" },
];

const CustomCheckbox: React.FC<CustomCheckboxProps> = ({
  checked,
  ...props
}) => (
  <SvgIcon {...props}>
    {checked ? (
      <>
        <rect width="20" height="20" rx="6" fill="#FFA737" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.7535 5.20711C16.0605 5.50374 16.0839 6.00965 15.8058 6.3371L9.01173 14.3371C8.86955 14.5045 8.66765 14.6 8.45588 14.6C8.24412 14.6 8.04222 14.5045 7.90004 14.3371L4.19416 9.97346C3.91607 9.64601 3.9395 9.1401 4.24648 8.84347C4.55346 8.54684 5.02776 8.57182 5.30585 8.89927L8.45588 12.6084L14.6942 5.26291C14.9722 4.93546 15.4465 4.91048 15.7535 5.20711Z"
          fill="#333333"
        />
      </>
    ) : (
      <rect
        x="0.5"
        y="0.5"
        width="19"
        height="19"
        rx="5.5"
        fill="white"
        stroke="#D2D2D0"
      />
    )}
  </SvgIcon>
);

const FilterComponent: React.FC<FilterComponentProps> = ({
  setFilters,
  subjectData,
  typeData,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
  const [anchorEl1, setAnchorEl1] = useState<null | HTMLElement>(null);
  const [selectedOptions1, setSelectedOptions1] = useState<number[]>([]);
  const [anchorEl2, setAnchorEl2] = useState<null | HTMLElement>(null);
  const [selectedOptions2, setSelectedOptions2] = useState<string[]>([]);
  const [anchorEl4, setAnchorEl4] = useState<null | HTMLElement>(null);

  const [fromDateFocused, setFromDateFocused] = useState(false);
  const [toDateFocused, setToDateFocused] = useState(false);

  const handleClick = (event: MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setFilters((prevFilters) => {
      const updatedFilters = { ...prevFilters };
      if (selectedOptions.length > 0) {
        updatedFilters.file_status = selectedOptions
          .map((option) => encodeURIComponent(option.toLowerCase()))
          .join(",");
      } else {
        delete updatedFilters.file_status;
      }
      return updatedFilters;
    });
    setAnchorEl(null);
  };

  const handleOptionClick = (optionLabel: string) => {
    if (selectedOptions.includes(optionLabel)) {
      setSelectedOptions(
        selectedOptions.filter((item) => item !== optionLabel)
      );
    } else {
      setSelectedOptions([...selectedOptions, optionLabel]);
    }
  };

  const handleClick1 = (event: MouseEvent<HTMLDivElement>) => {
    setAnchorEl1(event.currentTarget);
  };

  const handleOptionClick1 = (optionLabel: number) => {
    if (selectedOptions1.includes(optionLabel)) {
      setSelectedOptions1(
        selectedOptions1.filter((item) => item !== optionLabel)
      );
    } else {
      setSelectedOptions1([...selectedOptions1, optionLabel]);
    }
  };
  const handleClose1 = () => {
    setFilters((prevFilters) => {
      const updatedFilters = { ...prevFilters };
      if (selectedOptions1.length > 0) {
        updatedFilters.subject_id = selectedOptions1.join(",");
      } else {
        delete updatedFilters.subject_id;
      }
      return updatedFilters;
    });
    setAnchorEl1(null);
  };

  const handleClearAll1 = () => {
    setSelectedOptions1([]);
  };

  const handleClick2 = (event: MouseEvent<HTMLDivElement>) => {
    setAnchorEl2(event.currentTarget);
  };

  const handleClose2 = () => {
    setFilters((prevFilters) => {
      const updatedFilters = { ...prevFilters };
      if (selectedOptions2.length > 0) {
        updatedFilters.type_id = selectedOptions2.join(",");
      } else {
        delete updatedFilters.type_id;
      }
      return updatedFilters;
    });
    setAnchorEl2(null);
  };

  const handleOptionClick2 = (optionLabel: string) => {
    if (selectedOptions2.includes(optionLabel)) {
      setSelectedOptions2(
        selectedOptions2.filter((item) => item !== optionLabel)
      );
    } else {
      setSelectedOptions2([...selectedOptions2, optionLabel]);
    }
  };

  const handleClearAll2 = () => {
    setSelectedOptions2([]);
  };

  const handleClearAll = () => {
    setSelectedOptions([]);
    setSelectedOptions1([]);
    setSelectedOptions2([]);
    handleReset();
    setFilters((prevFilters) => ({
      ...prevFilters,
      file_status: "",
      from_date: "",
      to_date: "",
      subject_id: "",
      type_id: "",
      alert_status: "",
    }));
  };

  const open = Boolean(anchorEl);
  const open1 = Boolean(anchorEl1);
  const open2 = Boolean(anchorEl2);
  const open4 = Boolean(anchorEl4);
  const id = open ? "filter-popover" : undefined;
  const id1 = open1 ? "filter-popover1" : undefined;
  const id2 = open2 ? "filter-popover2" : undefined;
  const id4 = open4 ? "filter-popover4" : undefined;

  const [selectedStartDate, setSelectedStartDate] = useState<Date | null>(null);
  const [selectedEndDate, setSelectedEndDate] = useState<Date | null>(null);
  const [key, setKey] = useState<number>(0);

  console.log("selectedddd", selectedStartDate, selectedEndDate);

  const handleDateChange = (dates: [Date | null, Date | null]) => {
    const [start, end] = dates;
    setSelectedStartDate(start);
    setSelectedEndDate(end);
    setFromDateFocused(false);
    setToDateFocused(true);
  };

  const handleReset = () => {
    setSelectedStartDate(null);
    setSelectedEndDate(null);
    setKey((prevKey) => prevKey + 1);
    setFromDateFocused(false);
    setToDateFocused(false);
  };

  const handleClick4 = (event: MouseEvent<HTMLDivElement>) => {
    setAnchorEl4(event.currentTarget);
    setFromDateFocused(true);
  };

  const handleClose4 = () => {
    setFilters((prevFilters) => {
      const updatedFilters = { ...prevFilters };
      if (selectedStartDate && selectedEndDate) {
        const fromUTCDate = new Date(
          selectedStartDate.getTime() -
          selectedStartDate.getTimezoneOffset() * 60000
        );
        const toUTCDate = new Date(
          selectedEndDate.getTime() -
          selectedEndDate.getTimezoneOffset() * 60000
        );

        const fromDate = fromUTCDate.toISOString().split("T")[0];
        const toDate = toUTCDate.toISOString().split("T")[0];

        updatedFilters.from_date = fromDate;
        updatedFilters.to_date = toDate;
        console.log("form date", fromDate, toDate);
      } else {
        delete updatedFilters.from_date;
        delete updatedFilters.to_date;
      }
      return updatedFilters;
    });
    setAnchorEl4(null);
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
      }}
    >
      <Box
        onClick={handleClick}
        sx={{
          width: "125px",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          borderRadius: "8px",
          border: anchorEl !== null ? "1px solid #FFA737" : "1px solid #D2D2D0",
          padding: "4.5px 12px 4.5px 12px",
          fontSize: "14px",
          fontWeight: "400",
          marginRight: "8px",
          color: "#696969",
          cursor: "pointer",
          backgroundColor:
            anchorEl !== null
              ? "#FFF8EC"
              : selectedOptions.length > 0
                ? "#F4F6FF"
                : "transparent",
          "&:hover": {
            backgroundColor: selectedOptions.length > 0 ? "" : "#ffa7371f",
          },
        }}
      >
        File status{" "}
        {selectedOptions.length > 0 && `(${selectedOptions.length})`}
        <Box
          sx={{
            marginTop: "-3px",
          }}
        >
          <IconComponent name="userArrowDown" fill={"#8E8D8A"} />
        </Box>
      </Box>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        sx={{
          ".MuiPaper-root": {
            minWidth: "165px",
            border: "1px solid #D2D2D0",
            top: "406px",
          },
          ".MuiButtonBase-root": {
            padding: "0px !important",
            marginRight: "8px",
            marginTop: "3px",
          },
          ".css-0": {
            padding: "8px 16px 8px 12px",
            cursor: "pointer",
            borderBottom: "1px solid #D2D2D0",
            "&:hover": {
              background: "#ffa7371f",
            },
          },
          ".css-3bmhjh-MuiPaper-root-MuiPopover-paper": {
            border: "1px solid #D2D2D0",
            borderRadius: "8px",
          },
          ".css-1s8ndmz": {
            borderBottom: "1px solid #D2D2D0",
            cursor: "pointer",
            "&:hover": {
              backgroundColor: "#ffa7371f",
            },
          },
        }}
      >
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              background: "#F4F6FF",
              padding: "12px 16px 12px 12px",
            }}
          >
            <Typography
              sx={{
                fontSize: "12px",
                fontWeight: "500",
                color: "#696969",
              }}
            >
              {selectedOptions.length > 0
                ? `${selectedOptions.length} Selected`
                : "0 Selected"}
            </Typography>
            {selectedOptions.length > 0 && (
              <button
                style={{
                  padding: "0px",
                  background: "transparent",
                  border: "none",
                  fontSize: "12px",
                  fontWeight: "600",
                  color: "#333",
                  borderBottom: "2px solid #FFA737",
                  cursor: "pointer",
                }}
                onClick={handleClearAll}
              >
                Clear All
              </button>
            )}
          </div>

          {fileStatusOptions.map((option, index) => {
            let backgroundColor;
            let textColor;
            switch (option.label) {
              case "Scheduled":
                backgroundColor = "#E6F2FF";
                textColor = "#0069DB";
                break;
              case "Draft":
                backgroundColor = "#EEE8FF";
                textColor = "#512EC6";
                break;
              case "Published":
                backgroundColor = "#E6F6ED";
                textColor = "#007634";
                break;
              default:
                backgroundColor = "transparent";
                textColor = "#333";
            }

            return (
              <Box
                key={index}
                style={{
                  background: selectedOptions.includes(option.label)
                    ? "#FFF8EC"
                    : "",
                }}
                onClick={() => handleOptionClick(option.label)}
              >
                <Checkbox
                  checked={selectedOptions.includes(option.label)}
                  onChange={() => handleOptionClick(option.label)}
                  icon={<CustomCheckbox checked={false} />}
                  checkedIcon={
                    <CustomCheckbox checked style={{ fill: "##333333" }} />
                  }
                />
                <span
                  style={{
                    fontSize: "12px",
                    fontWeight: "500",
                    padding: "4px 8px",
                    color: textColor,
                    background: backgroundColor,
                    borderRadius: "16px",
                  }}
                >
                  <Box
                    width={"6px"}
                    height={"6px"}
                    bgcolor={textColor}
                    borderRadius={"50%"}
                    marginBottom={"1px"}
                    marginRight={"6px"}
                    display={"inline-block"}
                  />
                  {option.label}
                </span>
              </Box>
            );
          })}
        </>
      </Popover>

      <Box
        onClick={handleClick1}
        sx={{
          width: "110px",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          borderRadius: "8px",
          border:
            anchorEl1 !== null ? "1px solid #FFA737" : "1px solid #D2D2D0",
          padding: "4.5px 12px 4.5px 12px",
          fontSize: "14px",
          fontWeight: "400",
          marginRight: "8px",
          color: "#696969",
          cursor: "pointer",
          backgroundColor:
            anchorEl1 !== null
              ? "#FFF8EC"
              : selectedOptions1.length > 0
                ? "#F4F6FF"
                : "transparent",
          "&:hover": {
            backgroundColor: selectedOptions1.length > 0 ? "" : "#ffa7371f",
          },
        }}
      >
        Subject {selectedOptions1.length > 0 && `(${selectedOptions1.length})`}
        <Box
          sx={{
            marginTop: "-3px",
          }}
        >
          <IconComponent name="userArrowDown" fill={"#8E8D8A"} />
        </Box>
      </Box>

      <Popover
        id={id1}
        open={open1}
        anchorEl={anchorEl1}
        onClose={handleClose1}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        sx={{
          ".MuiPaper-root": {
            minWidth: "165px",
            border: "1px solid #D2D2D0",
            maxHeight: "275px",
            top: "406px",
          },
          ".MuiButtonBase-root": {
            padding: "0px !important",
            marginRight: "8px",
            marginTop: "3px",
          },
          ".css-3bmhjh-MuiPaper-root-MuiPopover-paper": {
            border: "1px solid #D2D2D0",
            borderRadius: "8px",
            overflowY: "scroll",
          },
          ".css-1s8ndmz": {
            padding: "8px 16px 8px 12px",
            borderBottom: "1px solid #D2D2D0",
            cursor: "pointer",
            "&:hover": {
              backgroundColor: "#ffa7371f",
            },
          },
        }}
      >
        <Box>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              background: "#F4F6FF",
              padding: "12px 16px 12px 12px",
            }}
          >
            <Typography
              sx={{
                fontSize: "12px",
                fontWeight: "500",
                color: "#696969",
              }}
            >
              {selectedOptions1.length > 0
                ? `${selectedOptions1.length} Selected`
                : "0 Selected"}
            </Typography>
            {selectedOptions1.length > 0 && (
              <button
                style={{
                  padding: "0px",
                  background: "transparent",
                  border: "none",
                  fontSize: "12px",
                  fontWeight: "600",
                  color: "#333",
                  borderBottom: "2px solid #FFA737",
                  cursor: "pointer",
                }}
                onClick={handleClearAll1}
              >
                Clear All
              </button>
            )}
          </div>

          {subjectData.map((option) => (
            <Box
              key={option.id}
              sx={{
                ".MuiBox-root": {
                  padding: "12px 12px 16px 12px",
                },
              }}
              style={{
                background: selectedOptions1.includes(option.id)
                  ? "#FFF8EC"
                  : "",
              }}
              onClick={() => handleOptionClick1(option.id)}
            >
              <Checkbox
                checked={selectedOptions1.includes(option.id)}
                onChange={() => handleOptionClick1(option.id)}
                icon={<CustomCheckbox checked={false} />}
                checkedIcon={
                  <CustomCheckbox checked style={{ fill: "##333333" }} />
                }
              />
              <span
                style={{
                  fontSize: "14px",
                  fontWeight: "400",
                  color: "#333",
                }}
              >
                {option.sub_code}
              </span>
            </Box>
          ))}
        </Box>
      </Popover>

      <Box
        onClick={handleClick2}
        sx={{
          width: "118px",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          borderRadius: "8px",
          border:
            anchorEl2 !== null ? "1px solid #FFA737" : "1px solid #D2D2D0",
          padding: "4.5px 12px 4.5px 12px",
          fontSize: "14px",
          fontWeight: "400",
          marginRight: "8px",
          color: "#696969",
          cursor: "pointer",
          backgroundColor:
            anchorEl2 !== null
              ? "#FFF8EC"
              : selectedOptions2.length > 0
                ? "#F4F6FF"
                : "transparent",
          "&:hover": {
            backgroundColor: selectedOptions2.length > 0 ? "" : "#ffa7371f",
          },
        }}
      >
        File type{" "}
        {selectedOptions2.length > 0 && `(${selectedOptions2.length})`}
        <Box
          sx={{
            marginTop: "-3px",
          }}
        >
          <IconComponent name="userArrowDown" fill={"#8E8D8A"} />
        </Box>
      </Box>

      <Popover
        id={id2}
        open={open2}
        anchorEl={anchorEl2}
        onClose={handleClose2}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        sx={{
          ".MuiPaper-root": {
            minWidth: "165px",
            border: "1px solid #D2D2D0",
            maxHeight: "275px",
            top: "406px",
          },
          ".MuiButtonBase-root": {
            padding: "0px !important",
            marginRight: "8px",
            marginTop: "3px",
          },
          ".css-3bmhjh-MuiPaper-root-MuiPopover-paper": {
            border: "1px solid #D2D2D0",
            borderRadius: "8px",
            overflowY: "scroll",
          },
          ".css-1s8ndmz": {
            padding: "8px 16px 8px 12px",
            borderBottom: "1px solid #D2D2D0",
            cursor: "pointer",
            "&:hover": {
              backgroundColor: "#ffa7371f",
            },
          },
        }}
      >
        <div>
          <Box
            sx={{
              ".MuiBox-root": {
              },
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                background: "#F4F6FF",
                padding: "12px 16px 12px 12px",
              }}
            >
              <Typography
                sx={{
                  fontSize: "12px",
                  fontWeight: "500",
                  color: "#696969",
                }}
              >
                {selectedOptions2.length > 0
                  ? `${selectedOptions2.length} Selected`
                  : "0 Selected"}
              </Typography>
              {selectedOptions2.length > 0 && (
                <button
                  style={{
                    padding: "0px",
                    background: "transparent",
                    border: "none",
                    fontSize: "12px",
                    fontWeight: "600",
                    color: "#333",
                    borderBottom: "2px solid #FFA737",
                    cursor: "pointer",
                  }}
                  onClick={handleClearAll2}
                >
                  Clear All
                </button>
              )}
            </div>
          </Box>
          {/* )} */}

          {typeData.map((option: any, index: number) => (
            <Box
              key={index}
              sx={{
                ".MuiBox-root": {
                  padding: "12px 12px 16px 12px",
                },
              }}
              style={{
                background: selectedOptions2.includes(option.file_type)
                  ? "#FFF8EC"
                  : "",
              }}
              onClick={() => handleOptionClick2(option.file_type)}
            >
              <Checkbox
                checked={selectedOptions2.includes(option.file_type)}
                onChange={() => handleOptionClick2(option.file_type)}
                icon={<CustomCheckbox checked={false} />}
                checkedIcon={
                  <CustomCheckbox checked style={{ fill: "##333333" }} />
                }
              />
              <span
                style={{
                  fontSize: "14px",
                  fontWeight: "400",
                  color: "#333",
                }}
              >
                {option.file_type}
              </span>
            </Box>
          ))}
        </div>
      </Popover>

      <Box
        onClick={handleClick4}
        sx={{
          width: "230px",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          borderRadius: "8px",
          border: selectedStartDate ? "1px solid #FFA737" : "1px solid #D2D2D0",
          padding: "4.5px 12px 4.5px 12px",
          fontSize: "14px",
          fontWeight: "400",
          marginRight: "8px",
          color: "#333333",
          cursor: "pointer",
          backgroundColor: selectedStartDate ? "#FFF8EC" : "transparent",
          "&:hover": {
            backgroundColor:
              selectedStartDate && selectedEndDate ? "" : "#ffa7371f",
          },
        }}
      >
        {selectedStartDate && selectedEndDate
          ? `Upload: ${selectedStartDate.toLocaleDateString("en-GB", {
            year: "2-digit",
            month: "2-digit",
            day: "2-digit",
          })} - ${selectedEndDate.toLocaleDateString("en-GB", {
            year: "2-digit",
            month: "2-digit",
            day: "2-digit",
          })}`
          : "Upload date"}
        <Box
          sx={{
            marginTop: "-3px",
          }}
        >
          <IconComponent name="userArrowDown" fill={"#8E8D8A"} />
        </Box>
      </Box>

      <Popover
        id={id4}
        open={open4}
        anchorEl={anchorEl4}
        onClose={handleClose4}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        sx={{
          ".MuiPaper-root": {
            width: "444px",
            border: "1px solid #D2D2D0",
          },
          ".css-3bmhjh-MuiPaper-root-MuiPopover-paper": {
            width: "444px",
            height: "auto",
            border: "1px solid #D2D2D0",
            borderRadius: "8px",
          },
          ".css-1s8ndmz": {
            padding: "24px",
            boxShadow: "0px 4px 20px 0px rgba(45, 45, 45, 0.16)",
            cursor: "pointer",
          },
        }}
      >
        <Box
          sx={{
            ".MuiBox-root": {
              padding: "12px 12px 16px 12px",
            },
          }}
        >
          <div>
            <CalendarComponent
              selectedStartDate={selectedStartDate}
              selectedEndDate={selectedEndDate}
              handleDateChange={handleDateChange}
              handleReset={handleReset}
              key={key}
              handleClose4={handleClose4}
              fromDateFocused={fromDateFocused}
              setFromDateFocused={setFromDateFocused}
              toDateFocused={toDateFocused}
              setToDateFocused={setToDateFocused}
            />
          </div>
        </Box>
      </Popover>
      <Typography
        onClick={handleClearAll}
        sx={{
          fontSize: "14px",
          fontWeight: "600",
          color: "#333",
          borderBottom: "2px solid #FFA737",
          cursor: "pointer",
        }}
      >
        Clear all
      </Typography>
    </Box>
  );
};

export default FilterComponent;
