import { Box, Typography } from "@mui/material";
import React from "react";
import UploadImg from "../../assets/images/upload.png";
interface FileInputProps {
  onSvgContentChange: (svgContent: File | string) => void;
  iconData?: string | File | null | undefined;
}

const FileInput = ({ onSvgContentChange, iconData }: FileInputProps) => {
  const [svgContent, setSvgContent] = React.useState<string | null | undefined>(
    ""
  );
  const [invalidType, setInvalidType] = React.useState(false);
  const [isDragging, setIsDragging] = React.useState(false);
  const [showLoader, setShowLoader] = React.useState(false);

  console.log("svgContent", svgContent);

  React.useEffect(() => {
    if (typeof iconData === "string") {
      setSvgContent(iconData);
    } else if (iconData instanceof File) {
      const reader = new FileReader();
      reader.onload = () => {
        setSvgContent(reader.result as string);
      };
      reader.readAsDataURL(iconData);
    } else {
      setSvgContent(null);
    }
  }, [iconData]);

  const handleDragEnter = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsDragging(true);
  };
  const handleDragLeave = () => {
    setIsDragging(false);
  };
  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };
  const isValidFileType = (file: File) => {
    const allowedFileTypes = ["image/svg+xml"];
    return allowedFileTypes.includes(file.type);
  };

  const handleDrop = async (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    const droppedFiles = Array.from(e.dataTransfer.files);
    const invalidFiles = droppedFiles.filter((file) => !isValidFileType(file));
    if (invalidFiles.length > 0) {
      setIsDragging(false);
      setInvalidType(true);
      return;
    }

    setInvalidType(false);
    setIsDragging(false);
    setShowLoader(true);
    const reader = new FileReader();

    reader.onload = (e) => {
      setSvgContent(e.target?.result as string);
      onSvgContentChange(droppedFiles[0]);
    };

    reader.readAsText(droppedFiles[0]);
    setShowLoader(false);
  };
  const handleFileClick = () => {
    const fileInput = document.getElementById("fileInput");
    if (fileInput) {
      fileInput.setAttribute("accept", ".svg");
      fileInput.click();
    }
  };

  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFiles = e.target.files;

    if (selectedFiles) {
      const allowedFileTypes = ["image/svg+xml"];
      const fileList = Array.from(selectedFiles) as File[];
      const isValidFile = fileList.every((file) =>
        allowedFileTypes.includes(file.type)
      );

      if (isValidFile) {
        setInvalidType(false);
        setShowLoader(true);

        const reader = new FileReader();

        reader.onload = (e) => {
          setSvgContent(e.target?.result as string);
          onSvgContentChange(selectedFiles[0]);
        };

        reader.readAsText(selectedFiles[0]);

        setShowLoader(false);
      } else {
        setInvalidType(true);
      }
    }
  };

  return (
    <>
      {svgContent ? (
        <Box
          position={"relative"}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
          flexDirection={"row"}
          height={"100%"}
          className="subPopIcon"
        >
          <img src={svgContent} height={52} width={52} alt="" crossOrigin="anonymous" />
          <Box
            position={"absolute"}
            top={"-8px"}
            right={"-8px"}
            component={"div"}
            onClick={() => {
              setSvgContent("");
              onSvgContentChange("");
            }}
            sx={{ height: "100%", cursor: "pointer" }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
            >
              <path
                d="M0 4C0 1.79086 1.79086 0 4 0H16C18.2091 0 20 1.79086 20 4V16C20 18.2091 18.2091 20 16 20H4C1.79086 20 0 18.2091 0 16V4Z"
                fill="#8E8D8A"
              />
              <path
                d="M14.1248 15.3025L10 11.1777L5.87521 15.3025C5.55112 15.6266 5.02079 15.6266 4.6967 15.3025C4.37261 14.9784 4.37261 14.4481 4.6967 14.124L8.82149 9.99918L4.6967 5.87439C4.37261 5.5503 4.37261 5.01997 4.6967 4.69588C5.02079 4.37179 5.55112 4.37179 5.87521 4.69588L10 8.82067L14.1248 4.69588C14.4489 4.37179 14.9792 4.37179 15.3033 4.69588C15.6274 5.01997 15.6274 5.5503 15.3033 5.87439L11.1785 9.99918L15.3033 14.124C15.6274 14.4481 15.6274 14.9784 15.3033 15.3025C14.9792 15.6266 14.4489 15.6266 14.1248 15.3025Z"
                fill="white"
              />
            </svg>
          </Box>
        </Box>
      ) : (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            border: "2px dashed #D2D2D0",
            borderColor: invalidType ? "red" : "#D2D2D0",
            borderRadius: "8px",
            padding: "24px 12px",
            cursor: "pointer",

            backgroundColor: isDragging || showLoader ? "#007AFF" : "#fff",
            "&:hover": {
              backgroundColor:
                isDragging || showLoader ? "#007AFF" : "#ffa7371f",
            },
          }}
          onDragEnter={handleDragEnter}
          onDragLeave={handleDragLeave}
          onDragOver={handleDragOver}
          onDrop={handleDrop}
          onClick={handleFileClick}
        >
          <input
            type="file"
            id="fileInput"
            style={{ display: "none" }}
            onChange={handleFileChange}
          />
          <>
            <img style={{ marginBottom: "5px" }} src={UploadImg} alt="" />
            <Typography
              sx={{
                fontSize: "12px",
                fontWeight: "500",
                color: "#333",
                lineHeight: "15px",
              }}
            >
              Upload a file
            </Typography>

            <Typography
              sx={{
                fontSize: "12px",
                fontWeight: "400",
                color: "#696969",
                lineHeight: "15px",
                textAlign: "center",
              }}
            >
              Click to browse, or drag & drop a file here
            </Typography>
          </>
        </Box>
      )}
    </>
  );
};

export default FileInput;
