import client from "./client";

export const createSubject = async (payload: object, token: string | null) =>
  await client.post("/subjects/add-subjects", payload, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const markCompleteStatus = async (
  payload: object,
  token: string | null,
) =>
  await client.post("/tasks/get-file-id-and-store-task", payload, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const createSubjectGroup = async (
  payload: object,
  token: string | null,
) =>
  await client.post("/subject-group/add-subject-group", payload, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
export const createAos = async (payload: object, token: string | null) =>
  await client.post("/areaofstudy/add-aos", payload, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const createTopic = async (payload: object, token: string | null) =>
  await client.post("/topic/add-topic", payload, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const closeSubject = async (
  payload: number | string,
  token: string | null,
) =>
  await client.delete(`/subjects/close-subject/${payload}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const deletSubject = async (
  payload: number | string,
  token: string | null,
) =>
  await client.delete(`/subjects/${payload}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const viewEditSubject = async (token: string, id: number) =>
  await client.get(`/subjects/view-subject/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const viewEditAos = async (token: string, id: number) =>
  await client.get(`/areaofstudy/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const viewEditTopic = async (token: string, id: number) =>
  await client.get(`/topic/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  
export const viewEditSubjectGroup = async (token: string, id: number) =>
  await client.get(`/subject-group/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });   

export const updateEditSubjectGroup = async (
  payload: object,
  token: string | null,
  id: number | null,
) =>
  await client.put(`/subject-group/edit-subject-group/${id}`, payload, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });


export const updateEditSubject = async (
  payload: object,
  token: string | null,
  id: number | null,
) =>
  await client.put(`/subjects/${id}`, payload, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const updateEditAOS = async (
  payload: object,
  token: string | null,
  id: number | null,
) =>
  await client.put(`/areaofstudy/${id}`, payload, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const updateEditTopic = async (
  payload: object,
  token: string | null,
  id: number | null,
) =>
  await client.put(`/topic/update-topic/${id}`, payload, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const getSubjectData = async (payload: object, token: string) =>
  await client.post("/subjects/get-subjects", payload, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const getAOSList = async (token: string) =>
  await client.get("/areaofstudy/view-aos", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const getTypeList = async (token: string) =>
  await client.get("/filetype/view-filetypes", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const getTopicList = async (token: string) =>
  await client.get("/topic/view-topics", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const openSubject = async (
  payload: number | string,
  token: string | null,
) =>
  await client.delete(`/subjects/open-subject/${payload}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
