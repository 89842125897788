import React, { useState } from "react";
import Box from "@mui/material/Box";
import { Popover, Typography } from "@mui/material";
import Icons from "../Icons";
import CustomizedSwitchComponent from "../Switch";
import { updateStuSubAccess } from "../../services/api/student";
import Cookies from "js-cookie";
import { useDispatch } from "react-redux";
import { setRefetchContent } from "../../store/student.slice";

interface SubjectCode {
  id: number;
  sub_code: string;
  label: string;
  access_status: boolean;
}

interface SubjectCodeDropDownProps {
  codeArray: SubjectCode[];
  stuId: number;
  setToast: React.Dispatch<
    React.SetStateAction<{
      type: string;
      message: string;
    } | null>
  >;
}

const SubjectCodeDropDown: React.FC<SubjectCodeDropDownProps> = ({
  codeArray,
  stuId,
  setToast,
}) => {
  const dispatch=useDispatch()
  const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);
  const [isHovering, setIsHovering] = React.useState(false);
  const [switchStates, setSwitchStates] = useState<{ [key: string]: boolean }>(
    {}
  );
  const [selectedswitchStates, setSelectedSwitchStates] = useState<{ [key: string]: boolean }>(
    {}
  );
  const verifyAccessString = Cookies.get("authAccess");
  const verifyAccess = verifyAccessString
    ? JSON.parse(verifyAccessString)
    : null;

  React.useEffect(() => {
    const initialSwitchStates: { [key: string]: boolean } = {};
    codeArray.forEach(({ sub_code, access_status }) => {
      initialSwitchStates[sub_code] = access_status || false;
    });
    setSwitchStates(initialSwitchStates);
  }, [codeArray]);

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = async () => {
    const studentId = stuId;
    const subjectsArray:Array<{subject_id:number
      access_status:boolean}> = []
    codeArray.map(({ id, sub_code }) => {
      if(typeof selectedswitchStates === "object" && Object.prototype.hasOwnProperty.call(selectedswitchStates, sub_code))
      subjectsArray.push({
      subject_id: id,
      access_status: selectedswitchStates[sub_code]
      })
    });

    try {
      const token = verifyAccess.token;
      const payload = {
        studentId,
        subjectsArray,
      };
      const response = await updateStuSubAccess(payload, token);
      if (response && response.data) {
        const successMessage = response.data.message;
        dispatch((setRefetchContent({shouldRefetch:new Date().getMilliseconds()})))
        setToast({
          type: "success",
          message: successMessage,
        });
      }
      console.log("updateStuSubAccess", response);
    } catch (error) {
      console.error("Error fetching student details:", error);
    }
    setAnchorEl(null);
  };

  const handleMouseOver = () => {
    setIsHovering(true);
  };

  const handleMouseOut = () => {
    setIsHovering(false);
  };

  const handleSwitchToggle = (name: string) => {
    setSwitchStates((prevState) => ({
      ...prevState,
      [name]: !prevState[name],
    }));
    setSelectedSwitchStates((prevState) => ({
      ...prevState,
      [name]: !prevState[name],
    }));
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <>
      <Box
        aria-describedby={id}
        style={{ display: "flex" }}
        onClick={handleClick}
        onMouseOver={handleMouseOver}
        onMouseOut={handleMouseOut}
        sx={{
          position: "relative",
          cursor: "pointer",
          background: anchorEl ? "#FFEDD1" : "",
          padding: "12px",
          width: "100%",
          "& > div:nth-child(n+4)": {
            marginTop: "8px",
          },
        }}
        className="adminSubject"
      >
        {codeArray.map(({ sub_code }, index) => (
          <React.Fragment key={index}>
            {switchStates[sub_code] ? (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  paddingX: "8px",
                  paddingY: "3.5px",
                  background: "#E3F5F5",
                  borderRadius: "4px",
                  marginRight: "8px",
                }}
              >
                <Icons
                  name="checkTick"
                  fill={"#8E8D8A"}
                  width={14}
                  height={14}
                />
                <Typography sx={{ marginLeft: "6px" }} variant="detail">
                  {sub_code}
                </Typography>
              </Box>
            ) : (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  paddingX: "8px",
                  paddingY: "3.5px",
                  marginRight: "8px",
                }}
              >
                <Typography
                  sx={{ marginRight: "8px" }}
                  color={"#696969"}
                  variant="detail"
                >
                  {sub_code}
                </Typography>
              </Box>
            )}
          </React.Fragment>
        ))}
        {isHovering || anchorEl ? (
          <Box
            sx={{
              position: "absolute",
              right: anchorEl ? "10px" : "0",
              top: 0,
              bottom: 0,
              display: "flex",
              alignItems: "center",
            }}
          >
            <Icons name="userArrowDown" fill={"#8E8D8A"} />
          </Box>
        ) : null}
      </Box>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Box
          sx={{
            borderRadius: "16px !important",
            width: "281px",
          }}
          display={"flex"}
          flexDirection={"column"}
        >
          <Typography
            sx={{
              paddingY: "8px",
              paddingX: "12px",
              borderBottom: "1px solid #D2D2D0",
              background: "#F4F6FF",
            }}
            variant="detail"
          >
            Subject access in LMS
          </Typography>

          {codeArray.map(({ sub_code, label }, index) => (
            <Box
              key={index}
              sx={{
                paddingX: "12px",
                borderBottom: "1px solid #D2D2D0",
                background: switchStates[sub_code] ? "#FFF8EC" : "transparent",
                ":hover": {
                  background: "#FFF8EC",
                },
              }}
            >
              <CustomizedSwitchComponent
                label={""}
                onToggle={() => handleSwitchToggle(sub_code)}
                defaultChecked={switchStates[sub_code]}
                key={sub_code}
              />
              <Typography
                sx={{
                  borderBottom: "0",
                  fontWeight: switchStates[sub_code] ? "600" : "400",
                }}
                variant="detail"
              >
                {sub_code} - {label}
              </Typography>
            </Box>
          ))}
        </Box>
      </Popover>
    </>
  );
};

export default SubjectCodeDropDown;
