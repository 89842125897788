import React, { useState, useRef } from "react";
import { Box, Typography } from "@mui/material";
import screenfull from "screenfull";
import "rc-slider/assets/index.css";
import Icons from "../Icons";

const ImageViewer = ({ file }: any) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const docViewerRef = useRef<HTMLDivElement>(null);

  const handlePrevImage = () => {
    setActiveIndex((prevIndex) =>
      prevIndex === 0 ? file.length - 1 : prevIndex - 1
    );
  };

  const handleNextImage = () => {
    setActiveIndex((prevIndex) =>
      prevIndex === file.length - 1 ? 0 : prevIndex + 1
    );
  };

  const toggleFullScreen = () => {
    if (docViewerRef.current) {
      screenfull.toggle(docViewerRef.current);
    }
  };

  return (
    <Box
      sx={{
        position: "relative",
        maxHeight: screenfull.isFullscreen ? "100vh" : "433px",
        height: screenfull.isFullscreen ? "100vh" : "433px",
        background: "#2B3258",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
      ref={docViewerRef}
    >
      <Box
        sx={{
          marginBottom: "10px",
        }}
      >
        <img
          src={file[activeIndex]}
          alt={`Image ${activeIndex + 1}`}
          style={{
            width: screenfull.isFullscreen ? "50vw" : "263px",
            height: screenfull.isFullscreen ? "calc(100vh - 85px)" : "371px",
          }}
          crossOrigin="anonymous"
        />
      </Box>
      <Box
        sx={{
          position: "absolute",
          bottom: "0px",
          display: "flex",
          width: "100%",
          alignItems: "center",
          justifyContent: "space-between",
          paddingLeft: "14px",
          paddingRight: "14px",
        }}
      >
        <Typography
          sx={{
            color: "#fff",
            fontSize: "14px",
            fontWeight: "500",
          }}
        >{`${activeIndex + 1}/${file.length}`}</Typography>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Typography
            onClick={handlePrevImage}
            sx={{
              rotate: "-180deg",
              marginBottom: "1px",
              marginRight: "20px",
              cursor: "pointer",
            }}
          >
            <Icons name="chevronRight" fill="#fff" />
          </Typography>
          <Typography
            onClick={handleNextImage}
            sx={{
              cursor: "pointer",
            }}
          >
            <Icons name="chevronRight" fill="#fff" />
          </Typography>
        </Box>
        <Typography
          onClick={toggleFullScreen}
          sx={{
            cursor: "pointer",
          }}
        >
          <Icons name="expandVideoFull" fill="#8E8D8A" />
        </Typography>
      </Box>
    </Box>
  );
};

export default ImageViewer;
