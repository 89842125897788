import React, { useEffect } from "react";
import Snackbar from "@mui/material/Snackbar";
import { styled } from "@mui/system";
import IconComponent from "../Icons";
import { Box, Typography } from "@mui/material";
import { RootState } from "../../store";
import { useDispatch, useSelector } from "react-redux";
import { clearNotification } from "../../store/error.slice";

const StyledSnackbar = styled(Snackbar)(() => ({
  "&.MuiSnackbar-root": {
    top: "15%",
    borderRadius: "4px",
    width: "361px !important",
    padding: "12px 8px !important",
    display: "block !important",
  },
  "&.MuiSnackbar-root .css-10akmkd-MuiPaper-root-MuiAlert-root": {
    width: "361px !important",
    padding: "8px 12px !important",
  },
  "&.MuiSnackbar-root .css-1pxa9xg-MuiAlert-message": {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "0px !important",
  },
}));


const Toast: React.FC = () => {
  const notify = useSelector((state: RootState) => state.error);
  const dispatch=useDispatch()
  const [message, setMessage] = React.useState<string|null>(null);
  const [type, setType] = React.useState<string|null>(null);
  const [open, setOpen] = React.useState<boolean>(false);
  const onClose=()=>{
    setOpen(false)
    dispatch(clearNotification())
  }
  useEffect(() => {
    const {message,type}=notify
    if (message !== null) {
      setMessage(message as string);
      setOpen(true);
      setType(type)
    }
  }, [notify.time]);
  return type!==null &&( <StyledSnackbar
      open={open}
      autoHideDuration={5500}
      onClose={onClose}
      className={type}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      sx={{
        background: type === "success" ? "#E6F6ED" : "#FFEEF2",
        border: type === "success" ? "1px solid #007634" : "1px solid #C61341",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box sx={{ display: "flex" }}>
          {type === "success" ? (
            <IconComponent
              name="checkCircle"
              fill={"#007634"}
              style={{ marginRight: "12px" }}
            />
          ) : (
            <IconComponent
              name="cancel"
              fill={"#C61341"}
              style={{ marginRight: "12px" }}
            />
          )}
          <Typography
            sx={{
              fontFamily: "Inter",
              fontSize: "14px",
              fontWeight: "600",
              color: "#181A20",
            }}
          >
            {message}
          </Typography>
        </Box>
        <Box onClick={onClose} sx={{ cursor: "pointer" }}>
          {type === "success" ? (
            <IconComponent name="close" fill={"#007634"} />
          ) : (
            <IconComponent name="close" fill={"#C61341"} />
          )}
        </Box>
      </Box>
    </StyledSnackbar>)
  ;
};

export default Toast;
