import React from "react";

import Tooltip from "@mui/material/Tooltip";
import Box from "@mui/material/Box";
import ClickAwayListener from "@mui/material/ClickAwayListener";

interface tipProps {
  title: React.ReactNode | string;
  children: React.ReactElement;
  html?: boolean;
}

const TextTooltip = ({ title, children, html }: tipProps) => {
  const [open, setOpen] = React.useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const ctitle = html ? <React.Fragment>{title}</React.Fragment> : title;

  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <Tooltip
        arrow
        title={ctitle}
        placement="left"
        PopperProps={{
          disablePortal: true,
        }}
        onClose={handleTooltipClose}
        open={open}
        disableFocusListener
        disableHoverListener
        disableTouchListener
        componentsProps={{
          tooltip: {
            sx: {
              backgroundColor: "#3B4478",
              color: "#FFF",
              fontFamily: "Inter",
              fontSize: " 12px",
              fontStyle: "normal",
              fontWeight: 400,
              lineHeight: "15px",
              borderRadius: "4px",
            },
          },
          arrow: {
            sx: {
              "&:before": {
                bgcolor: "#3B4478",
              },
            },
          },
        }}
      >
        <Box
          onClick={handleTooltipOpen}
          component={"div"}
          sx={{ cursor: "pointer" }}
        >
          {children}
        </Box>
      </Tooltip>
    </ClickAwayListener>
  );
};

export default TextTooltip;
