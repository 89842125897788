import { Box, Typography, Skeleton } from "@mui/material";
import React, { useEffect, useState } from "react";
import Tab from "../Tab";
import BorderText from "../Label/BorderText";
import DataTable from "./FilesDashboard";
import { useNavigate } from "react-router-dom";
import { getFileListData } from "../../services/api/dashboard";
import Cookies from "js-cookie";
import ToastMessage from "../ToastMessage";

type DataFile = {
  id: number;
  file_name: string;
  file_url: string;
  publish_status: string;
  file_status: string;
};

const Files = () => {
  const [tab, setTab] = useState(0);
  const [loading, setLoading] = useState(true);
  const [fileData, setFileData] = useState<DataFile[]>([]);
  const [scheduledCount, setScheduledCount] = useState<number>(0);
  const [draftCount, setDraftCount] = useState<number>(0);
  const navigate = useNavigate();
  const [toast, setToast] = React.useState<{
    type: string;
    message: string;
  } | null>(null);

  const handleRoute = () => navigate("/file-library");
  const verifyAccessString = Cookies.get("authAccess");
  const verifyAccess = verifyAccessString
    ? JSON.parse(verifyAccessString)
    : null;

  useEffect(() => {
    let publish_status = "";
    if (tab === 0) {
      publish_status = "SCHEDULED";
    } else if (tab === 1) {
      publish_status = "DRAFT";
    } else if (tab === 2) {
      publish_status = "PUBLISHED";
    }
    fetchFileData(publish_status);
  }, [tab]);

  const fetchFileData = async (publish_status: string) => {
    try {
      const response = await getFileListData(
        verifyAccess.token,
        publish_status,
      );
      if (response.data.success) {
        setFileData(response.data.data);
        setScheduledCount(response.data.scheduledcount);
        setDraftCount(response.data.draftcount);
        setLoading(false);
      }
    } catch (error) {
      console.error("Error fetching file data:", error);
    } finally {
      setLoading(false);
    }
  };

  const fileTabs = [
    {
      label: "Scheduled",
      count: scheduledCount,
      value: "scheduled",
    },
    {
      label: "Drafts",
      count: draftCount,
      value: "draft",
    },
  ];

  return (
    <>
      <Box
        sx={{
          bgcolor: "white",
          padding: "28px",
          borderRadius: "16px",
          flexGrow: 1,
          gap: "24px",
          display: "flex",
          flexDirection: "column",
          position: "absolute",
          right: 0,
          left: 0,
          bottom: 0,
          top: 0,
        }}
      >
        <Box
          sx={{
            flexDirection: "row",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography color={"contour.title4"} variant="title4">
            Files
          </Typography>
          <BorderText text={"View All"} onClick={handleRoute} />
        </Box>
        {loading ? (
          <>
            <Skeleton
              variant="rounded"
              width={"100%"}
              height={"calc(100vh - 300px)"}
            />
          </>
        ) : (
          <>
            <Box>
              <Tab tabData={fileTabs} selected={tab} setSelectedTab={setTab} />
            </Box>
            <Box sx={{ height: "400px" }} position={"relative"}>
              <DataTable
                fileData={fileData}
                tabIndex={tab}
                setToast={setToast}
              />
            </Box>
          </>
        )}
      </Box>
      {toast && (
        <ToastMessage
          type={toast.type as "success" | "error"}
          message={toast.message}
          onClose={() => setToast(null)}
          style={{
            position: "abosolute",
            top: "90px",
            textTransform: "capitalize",
          }}
        />
      )}
    </>
  );
};

export default Files;
