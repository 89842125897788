import React from "react";
import Drawer from "@mui/material/Drawer";
import { Box, Typography, Link } from "@mui/material";
import Icons from "../Icons";

interface drawerProps {
  title: string;
  children: React.ReactNode;
  open: boolean;
  handleClose: () => void;
  handleButton?: () => void;
  btnText?: string;
  extra?: string;
}

const RightDrawer = ({
  title,
  children,
  open,
  handleClose,
  btnText = "",
  handleButton,
  extra,
}: drawerProps) => {
  return (
    <Drawer
      anchor={"right"}
      open={open}
      PaperProps={{
        sx: {
          height: "calc(100% - 28px)",
          top: 14,
          bgcolor: "white",
          borderRadius: "16px",
          boxShadow: " 5px 20px 30px 0px rgba(45, 45, 45, 0.20)",
          right: "14px",
          width: "100%",
          maxWidth: "35%",
          padding: 0,
        },
      }}
      sx={{
        zIndex: 1202,
      }}
      onClose={handleClose}
      disableEscapeKeyDown={false}
    >
      <Box
        sx={{
          bgcolor: "white",
          borderBottom: "1px solid #EFEFEF",
          paddingX: "24px",
          paddingY: "28px",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography color={"#333"} variant="title3">
          {title}
        </Typography>
        <Box sx={{ lineHeight: 0, cursor: "pointer" }} onClick={handleClose}>
          <Icons width={20} height={20} name="close" fill="#8E8D8A" />
        </Box>
      </Box>
      <Box sx={{ height: "100%", position: "relative" }}>
        <Box
          sx={{
            position: "absolute",
            top: "24px",
            bottom: "24px",
            left: "0px",
            right: "12px",
            overflowY: "auto",
          }}
        >
          {children}
        </Box>
      </Box>
      {btnText && extra && (
        <Box
          display={"flex"}
          flexDirection={"row"}
          alignItems={"flex-end"}
          justifyContent={"end"}
          flex={1}
          sx={{ paddingX: "16px", paddingY: "8px", marginRight: "12px",marginBottom:"18px" }}
        >
          <Link
            onClick={handleButton}
            underline="none"
            sx={{
              paddingX: "20px",
              bgcolor: "#FFA737",
              borderRadius: "8px",
              height: "44px",
            }}
            component={"button"}
          >
            <Typography color={"#333"} variant="cbutton">
              {btnText}
            </Typography>
          </Link>
        </Box>
      )}
    </Drawer>
  );
};

export default RightDrawer;
