import React, { useState, ChangeEvent, useEffect } from "react";
import {
  Box,
  TextField,
  Typography,
  IconButton,
  InputAdornment,
} from "@mui/material";
import Loginlogo from "../../assets/images/ContourLogo.png";
import BorderText from "../../components/Label/BorderText";
import Button from "../../components/Button";
import Loginlayout from "../../layouts/LoginLayout";
import { useNavigate } from "react-router-dom";
import IconComponent from "../../components/Icons";
import { useDispatch } from "react-redux";
import { authenticateWithCredentials } from "../../store/AuthSlice";
import { AppDispatch } from "../../store/index";
import Cookies from "js-cookie";
import ToastMessage from "../../components/ToastMessage";
interface FormData {
  email: string;
  password: string;
}

const validateEmail = (email: string): boolean => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

const validatePassword = (password: string): boolean => {
  const specialChars = /^(?=.*[a-z])(?=.*[!@#$%^&*(),.?":{}|<>]).{8,}$/;
  return specialChars.test(password);
};

export const Login: React.FC = () => {
  const navigate = useNavigate();
  const verifyAccessString = Cookies.get("authAccess");
  const verifyAccess = verifyAccessString
    ? JSON.parse(verifyAccessString)
    : null;
  const dispatch: AppDispatch = useDispatch();
  const [formData, setFormData] = useState<FormData>({
    email: "",
    password: "",
  });
  const [toast, setToast] = React.useState<{
    type: string;
    message: string;
  } | null>(null);

  useEffect(() => {
    if (verifyAccess) {
      navigate("/dashboard");
    }
  }, [verifyAccess]);

  const [isEmailValid, setIsEmailValid] = useState(true);
  const [isPasswordValid, setIsPasswordValid] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const savedUserData = sessionStorage.getItem("userData");
    if (savedUserData) {
      setFormData(JSON.parse(savedUserData));
    }
  }, []);

  const handleSignIn = async () => {
    if (formData.email !== "" && formData.password !== "") {
      const isEmailValid = validateEmail(formData.email);
      const isPasswordValid = validatePassword(formData.password);

      setIsEmailValid(isEmailValid);
      setIsPasswordValid(isPasswordValid);

      if (isEmailValid && isPasswordValid) {
        setLoading(true);
        try {
          const action = await dispatch(
            authenticateWithCredentials({
              email: formData.email,
              password: formData.password,
            }),
          );
          if (authenticateWithCredentials.fulfilled.match(action)) {
            sessionStorage.setItem("userData", JSON.stringify(formData));
            navigate("/verify-login");
            setLoading(false);
          } else {
            console.log("asd", action.error);
            const errorMessage =
              (action.error.message as string) || "Unknown error";
            setLoading(false);
            setToast({
              type: "error",
              message: errorMessage,
            });
          }
        } catch (error) {
          console.error("Authentication failed", error);
          setLoading(false);
        }
      }
    }
  };

  const handleForgotPswrd = () => {
    sessionStorage.setItem("userData", JSON.stringify(formData));
    navigate("/forgot-password");
  };

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { id, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [id]: value,
    }));
    if (id === "email") {
      const isValid = validateEmail(value);
      setIsEmailValid(isValid);
    } else {
      const isValidd = validatePassword(value);
      setIsPasswordValid(isValidd);
    }
  };

  const handleTogglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const handleKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      if (formData.email.trim() !== "" && formData.password.trim() !== "") {
        handleSignIn();
      }
    }
  };

  const isResetButtonDisabled =
    formData.email && formData.password && isEmailValid && isPasswordValid;

  return (
    <Loginlayout>
      {toast && (
        <ToastMessage
          type={toast.type as "success" | "error"}
          message={toast.message}
          onClose={() => setToast(null)}
          style={{
            position: "absolute",
            top: "5%",
          }}
        />
      )}
      <Box
        sx={{ marginBottom: "60px", width: "90%" }}
        component="img"
        src={Loginlogo}
        alt="brand-logo"
      />
      <div>
        <Typography color={"contour.title3"} variant="title2">
          Sign in
        </Typography>
        <Box
          width={"100%"}
          sx={{ paddingY: "40px" }}
          display={"flex"}
          flexDirection={"column"}
        >
          <Typography
            color={"#333333"}
            variant="detailBold"
            sx={{ marginBottom: "4px" }}
          >
            Email
          </Typography>
          <TextField
            id="email"
            value={formData.email}
            onChange={handleInputChange}
            variant="outlined"
            error={!isEmailValid}
            onKeyDown={handleKeyPress}
            helperText={
              !isEmailValid ? (
                <span
                  style={{ display: "flex", alignItems: "center", gap: "6px" }}
                >
                  <IconComponent name="warningRed" fill={"#8E8D8A"} />
                  Enter a valid email address
                </span>
              ) : (
                ""
              )
            }
            sx={{
              marginBottom: "8px",
              width: "350px",
              ".MuiOutlinedInput-notchedOutline": {
                borderRadius: "8px",
                border: "1px solid #D2D2D0",
              },
              ".css-3j0wgu-MuiInputBase-root-MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline":
                {
                  border: "1px solid #C61341",
                },
              "& .css-5ifmys-MuiFormHelperText-root.Mui-error": {
                color: "#181A20",
              },
              "& .css-5ifmys-MuiFormHelperText-root": {
                marginLeft: 0,
              },
              "& .css-19z3763-MuiInputBase-input-MuiOutlinedInput-input": {
                height: "11px",
              },
            }}
          />
          <Typography
            color={"#333333"}
            variant="detailBold"
            sx={{ marginBottom: "4px" }}
          >
            Password
          </Typography>
          <TextField
            id="password"
            value={formData.password}
            onChange={handleInputChange}
            variant="outlined"
            type={showPassword ? "text" : "password"}
            error={!isPasswordValid}
            onKeyDown={handleKeyPress}
            helperText={
              !isPasswordValid ? (
                <span
                  style={{ display: "flex", alignItems: "center", gap: "6px" }}
                >
                  <IconComponent name="warningRed" fill={"#8E8D8A"} />
                  Enter a valid Password
                </span>
              ) : (
                ""
              )
            }
            sx={{
              marginBottom: "8px",
              width: "350px",
              ".MuiOutlinedInput-notchedOutline": {
                borderRadius: "8px",
                border: "1px solid #D2D2D0",
              },
              ".css-3j0wgu-MuiInputBase-root-MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline":
                {
                  border: "1px solid #C61341",
                },
              "& .css-5ifmys-MuiFormHelperText-root.Mui-error": {
                color: "#181A20",
              },
              "& .css-5ifmys-MuiFormHelperText-root": {
                marginLeft: 0,
              },
              "& .css-70vwbq-MuiInputBase-input-MuiOutlinedInput-input": {
                height: "11px",
              },
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={handleTogglePasswordVisibility}
                    edge="end"
                  >
                    {showPassword ? (
                      <IconComponent name="show" fill={"#8E8D8A"} />
                    ) : (
                      <IconComponent name="hide" fill={"#8E8D8A"} />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <div>
            <BorderText text={"Forgot password"} onClick={handleForgotPswrd} />
          </div>
        </Box>
        <Box sx={{ maxWidth: "168px" }}>
            <Button
            loading={loading}
              text="Sign in"
              textStyle={{
                color: "#333",
                cursor: !isResetButtonDisabled ? "not-allowed" : "pointer",
                opacity: !isResetButtonDisabled ? "0.5" : "1",
                pointerEvents: !isResetButtonDisabled ? "none" : "auto",
              }}
              handleClick={handleSignIn}
            />
        </Box>
      </div>
    </Loginlayout>
  );
};
