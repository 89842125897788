import { Box } from "@mui/material";
import React from "react";
import Icons from "../Icons";

type IconBtnComponentProps = {
  name: string;
  width?: number;
  height?: number;
  clickButton?: () => void;
};

const IconBtnComponent: React.FC<IconBtnComponentProps> = ({
  name,
  width,
  height,
  clickButton,
}) => {
  return (
    <Box
      sx={{
        padding: "10px",
        display: "flex",
        alignItems: "center",
        border: "1px solid transparent",
        ":Hover": {
          border: "1px solid #D2D2D0",
          background: "#FFF8EC1A",
          borderRadius: "8px",
          cursor: "pointer",
        },
      }}
      onClick={clickButton}
    >
      <Icons name={name} fill={"#FFFFFF"} width={width} height={height} />
    </Box>
  );
};

export default IconBtnComponent;
