import {
  Box,
  Checkbox,
  FormControl,
  Grid,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material";
import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import Icons from "../Icons";
import TransparentBdrBtn from "../Button/TranparentBdrBtn";
import Button from "../Button";
import { SearchSubjectComponent } from "./SearchSubjectComponent";
import BorderText from "../Label/BorderText";
import CustomizedSwitchComponent from "../Switch";
import { getCampusList, getSubjectList } from "../../services/api/student";

type SubPayload = {
  subject_id: number;
  access_status: boolean;
};

interface addStuPayload {
  email: string;
  first_name: string;
  last_name: string;
  phone: string;
  campus_id: number;
  enrolment_status: string;
  subjects: SubPayload[];
}

type AddNewStudentComponentProps = {
  isAddingStudent: boolean;
  HandAddSubject: () => void;
  onBack: () => void;
  updateStudentData: (data: Partial<addStuPayload>) => void;
};

interface Campus {
  id: number;
  campus_name: string;
}

interface SubjectList {
  id: number;
  sub_code: string;
  subject: string;
}


const AddNewStudentComponent: React.FC<AddNewStudentComponentProps> = ({
  isAddingStudent,
  HandAddSubject,
  onBack,
  updateStudentData,
}) => {
  const verifyAccessString = Cookies.get("authAccess");
  const verifyAccess = verifyAccessString
    ? JSON.parse(verifyAccessString)
    : null;
  const [selectCampus, setSelectCampus] = React.useState("");
  const [firstName, setFirstName] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [phoneNumber, setPhoneNumber] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [
    ,
    setIsEmailValid,
  ] = React.useState(true);
  const [checkboxStates, setCheckboxStates] = useState<{
    [key: string]: boolean;
  }>({});
  const [campusData, setCampusData] = useState<Campus[]>([]);
  const [subjectData, setSubjectData] = useState<SubjectList[]>([]);
  const [filteredSubjects, setFilteredSubjects] = useState<SubjectList[]>(
    subjectData && subjectData,
  );

  const [selectedCodes, setSelectedCodes] = useState<
    { id: number; subject: string; sub_code: string }[]
  >([]);
  const [showSubList, setShowSubList] = useState(false);
  const handleSearchChange = (value: string) => {
    const filteredData = subjectData.filter(
      (subject) =>
        subject?.subject?.toLowerCase().includes(value.toLowerCase()) ||
        subject?.sub_code?.toLowerCase().includes(value.toLowerCase()),
    );
    setFilteredSubjects(filteredData);
  };

  const handleCheckState = (id: number) => {
    setCheckboxStates((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));

    setSelectedCodes((prev) => {
      if (!checkboxStates[id]) {
        const selectedSubject = subjectData.find(
          (subject) => subject.id === id,
        );
        return selectedSubject ? [...prev, selectedSubject] : prev;
      }
      return prev.filter((subject) => subject.id !== id);
    });

    setSelectedCodes((updatedSelectedCodes) => {
      updateStudentData({
        subjects: updatedSelectedCodes.map((subject) => ({
          subject_id: subject.id,
          access_status: true,
        })),
      });
      return updatedSelectedCodes;
    });
  };

  const handleRemoveSelectedSubject = (id: number) => {
    setCheckboxStates((prev) => ({
      ...prev,
      [id]: false,
    }));

    setSelectedCodes((prev) => prev.filter((subject) => subject.id !== id));

    setSelectedCodes((updatedSelectedCodes) => {
      updateStudentData({
        subjects: updatedSelectedCodes.map((subject) => ({
          subject_id: subject.id,
          access_status: true,
        })),
      });
      return updatedSelectedCodes;
    });
  };

  const handleAddSub = () => {
    onBack();
    setShowSubList(true);
  };

  useEffect(() => {
    fetchCampusData();
    fetchSubjectData();
  }, []);

  const fetchCampusData = async () => {
    try {
      const response = await getCampusList(verifyAccess.token);
      setCampusData(response.data.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchSubjectData = async () => {
    try {
      const response = await getSubjectList(verifyAccess.token);
      setSubjectData(response.data.data);
      setFilteredSubjects(response.data.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const label = { inputProps: { "aria-label": "Checkbox demo" } };

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    setter: React.Dispatch<React.SetStateAction<string>>,
  ) => {
    const { id, value } = event.target;
    const input = event.target.value;
    if (id === "firstName") {
      updateStudentData({ first_name: value });
    } else {
      updateStudentData({ last_name: value });
    }
    if (/^[a-zA-Z]+$/.test(input) || input === "") {
      setter(input);
    }
  };
  const handleInputNumberChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    setter: React.Dispatch<React.SetStateAction<string>>,
    isNumeric: boolean = false,
  ) => {
    let input = event.target.value;
    updateStudentData({ phone: input });
    if (isNumeric) {
      input = input.replace(/\D/g, "");
    }
    setter(input);
  };
  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputEmail = event.target.value;
    const isValid = /\S+@\S+\.\S+/.test(inputEmail);
    setEmail(inputEmail);
    setIsEmailValid(isValid);
    updateStudentData({ email: inputEmail });
  };
  const handleChange = (event: SelectChangeEvent) => {
    setSelectCampus(event.target.value as string);
    updateStudentData({ campus_id: parseInt(event.target.value as string) });
  };

  return (
    <Box>
      {isAddingStudent ? (
        <Box>
          <Grid container spacing={2}>
            <Grid item md={6}>
              <Typography
                color={"#333333"}
                variant="detailBold"
                sx={{ marginBottom: "4px" }}
                component={"div"}
              >
                First name
              </Typography>
              <TextField
                id="firstName"
                value={firstName}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  handleInputChange(event, setFirstName)
                }
                variant="outlined"
                sx={{
                  width: "100%",
                  color: "#333333",
                  ".MuiInputBase-input": {
                    color: "#333333",
                    paddingX: "16px",
                    paddingY: "13px",
                    fontSize: "14px",
                    fontWeight: "400",
                  },
                  ".MuiOutlinedInput-notchedOutline": {
                    borderRadius: "8px",
                    border: "1px solid #D2D2D0",
                  },
                  ".css-3j0wgu-MuiInputBase-root-MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline":
                  {
                    border: "1px solid #C61341",
                  },
                  "& .css-5ifmys-MuiFormHelperText-root.Mui-error": {
                    color: "#181A20",
                  },
                  "& .css-5ifmys-MuiFormHelperText-root": {
                    marginLeft: 0,
                  },
                }}
              />
            </Grid>
            <Grid item md={6}>
              <Typography
                color={"#333333"}
                variant="detailBold"
                sx={{ marginBottom: "4px" }}
                component={"div"}
              >
                Last name
              </Typography>
              <TextField
                id="lastName"
                value={lastName}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  handleInputChange(event, setLastName)
                }
                variant="outlined"

                sx={{
                  width: "100%",
                  ".MuiInputBase-input": {
                    color: "#333333",
                    paddingX: "16px",
                    paddingY: "13px",
                    fontSize: "14px",
                    fontWeight: "400",
                  },
                  ".MuiOutlinedInput-notchedOutline": {
                    borderRadius: "8px",
                    border: "1px solid #D2D2D0",
                  },
                  ".css-3j0wgu-MuiInputBase-root-MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline":
                  {
                    border: "1px solid #C61341",
                  },
                  "& .css-5ifmys-MuiFormHelperText-root.Mui-error": {
                    color: "#181A20",
                  },
                  "& .css-5ifmys-MuiFormHelperText-root": {
                    marginLeft: 0,
                  },
                }}
              />
            </Grid>
            <Grid item md={6}>
              <Typography
                color={"#333333"}
                variant="detailBold"
                sx={{ marginBottom: "4px" }}
                component={"div"}
              >
                Phone
              </Typography>
              <TextField
                id="number"
                value={phoneNumber}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  handleInputNumberChange(event, setPhoneNumber, true)
                }
                variant="outlined"

                sx={{
                  width: "100%",
                  ".MuiInputBase-input": {
                    color: "#333333",
                    paddingX: "16px",
                    paddingY: "13px",
                    fontSize: "14px",
                    fontWeight: "400",
                  },
                  ".MuiOutlinedInput-notchedOutline": {
                    borderRadius: "8px",
                    border: "1px solid #D2D2D0",
                  },
                  ".css-3j0wgu-MuiInputBase-root-MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline":
                  {
                    border: "1px solid #C61341",
                  },
                  "& .css-5ifmys-MuiFormHelperText-root.Mui-error": {
                    color: "#181A20",
                  },
                  "& .css-5ifmys-MuiFormHelperText-root": {
                    marginLeft: 0,
                  },
                }}
              />
            </Grid>
            <Grid item md={6}>
              <Typography
                color={"#333333"}
                variant="detailBold"
                sx={{ marginBottom: "4px" }}
                component={"div"}
              >
                Email
              </Typography>
              <TextField
                id="email"
                value={email}
                onChange={handleEmailChange}
                variant="outlined"

                sx={{
                  width: "100%",
                  ".MuiInputBase-input": {
                    color: "#333333",
                    paddingX: "16px",
                    paddingY: "13px",
                    fontSize: "14px",
                    fontWeight: "400",
                  },
                  ".MuiOutlinedInput-notchedOutline": {
                    borderRadius: "8px",
                    border: "1px solid #D2D2D0",
                  },
                  ".css-3j0wgu-MuiInputBase-root-MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline":
                  {
                    border: "1px solid #C61341",
                  },
                  "& .css-5ifmys-MuiFormHelperText-root.Mui-error": {
                    color: "#181A20",
                  },
                  "& .css-5ifmys-MuiFormHelperText-root": {
                    marginLeft: 0,
                  },
                }}
              />
            </Grid>
            <Grid item md={6}>
              <Typography
                color={"#333333"}
                variant="detailBold"
                sx={{ marginBottom: "4px" }}
                component={"div"}
              >
                Campus
              </Typography>
              <FormControl sx={{ width: "100%" }}>
                <Select
                  value={selectCampus}
                  onChange={handleChange}
                  displayEmpty
                  IconComponent={() => (
                    <Box sx={{ marginRight: "20px", display: "flex" }}>
                      <Icons name="userArrowDown" fill={"#8E8D8A"} />
                    </Box>
                  )}
                  inputProps={{ "aria-label": "Without label" }}
                  sx={{
                    fontSize: "14px",
                    fontWeight: "400",
                    color: "#333333",
                    borderRadius: "8px",
                    minWidth: "184px",
                    textTransform: "capitalize",
                    ".MuiSelect-select": {
                      fontSize: "14px",
                      fontWeight: "400",
                      color: "#333333",
                      paddingX: "16px",
                      paddingY: "13px",
                    },
                    ".css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
                      border: "1px solid #D2D2D0",
                    },
                  }}
                >

                  {selectCampus === "" && (
                    <MenuItem value="" disabled>
                      Select Campus
                    </MenuItem>
                  )}
                  {campusData &&
                    campusData.map((option) => (
                      <MenuItem
                        key={option.id}
                        value={option.id}
                        sx={{
                          textTransform: "capitalize",
                        }}
                      >
                        {option.campus_name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Box
            sx={{
              background: "#D2D2D0",
              width: "100%",
              height: "1px",
              marginTop: "20px",
            }}
          />
          <Typography
            color={"#333333"}
            sx={{
              fontSize: "16px",
              fontWeight: 600,
              fontFamily: "Inter",
              marginTop: "16px",
            }}
            component={"div"}
          >
            Subjects
          </Typography>

          {showSubList && (
            <>
              {selectedCodes.map((subject, index) => (
                <Box
                  key={index}
                  sx={{
                    border: "1px solid #D2D2D0",
                    paddingX: "12px",
                    paddingY: "8px",
                    borderRadius: "4px",
                    marginTop: "16px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "14px",
                      fontWeight: "400",
                      color: "#333333",
                    }}
                  >
                    {subject.sub_code} - {subject.subject}
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      marginLeft: "auto",
                      alignItems: "center",
                    }}
                  >
                    <CustomizedSwitchComponent
                      onToggle={() => console.log("On and off ")}
                      defaultChecked={true}
                      key={subject.id.toString()}
                    />
                    <Typography
                      sx={{
                        fontSize: "12px",
                        fontWeight: "400",
                        color: "#333333",
                        marginRight: "16px",
                      }}
                    >
                      Access enabled
                    </Typography>
                    <Box
                      sx={{ display: "contents", cursor: "pointer" }}
                      component={"div"}
                    >
                      <Icons
                        name="trash"
                        fill="#8E8D8A"
                        width={20}
                        height={20}
                        onClick={() => handleRemoveSelectedSubject(subject.id)}
                      />
                    </Box>
                  </Box>
                </Box>
              ))}
            </>
          )}

          <Box sx={{ width: "145px", marginTop: "16px" }}>
            <TransparentBdrBtn
              text="Add subject"
              icon={<Icons name="add" fill="#333" width={16} height={16} />}
              textStyle={{ color: "#333" }}
              handleClick={HandAddSubject}
            />
          </Box>
        </Box>
      ) : (
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Box
            onClick={onBack}
            display={"flex"}
            flexDirection={"row"}
            alignItems={"center"}
            gap={"4px"}
            sx={{ cursor: "pointer" }}
          >
            <Icons name="chevronLeft" fill="#8E8D8A" width={16} height={16} />
            <BorderText text={"Back"} />
          </Box>
          <Box>
            <Typography
              sx={{ paddingY: "16px" }}
              color="#333333"
              variant="title4"
              component={"div"}
            >
              Add new subject
            </Typography>
            <SearchSubjectComponent onSearchChange={handleSearchChange} />
          </Box>

          <Box
            marginTop={"16px"}
            height={"calc(75vh - 390px)"}
            sx={{ overflowY: "auto", overflowX: "hidden" }}
          >
            {filteredSubjects.map((subject, index) => (
              <Box
                key={index}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "8px",
                  padding: "12px",
                  borderBottom: "1px solid #D2D2D0",
                  background: checkboxStates[subject.id] ? "#FFF8EC" : "",
                  ":hover": {
                    background: "#FFF8EC",
                  },
                }}
              >
                <Checkbox
                  {...label}
                  checked={checkboxStates[subject.id] || false}
                  onChange={() => handleCheckState(subject.id)}
                  sx={{
                    color: "#D2D2D0",
                    padding: "0",
                    width: "20px",
                    height: "20px",
                    "&.Mui-checked": {
                      color: "#FFA737",
                    },
                  }}
                />
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: checkboxStates[subject.id] ? "600" : "400",
                    color: "#333333",
                  }}
                >
                  {subject.sub_code} - {subject.subject}
                </Typography>
              </Box>
            ))}
          </Box>

          <Box
            sx={{
              maxWidth: "720px",
              width: "100%",
              borderTop: "1px solid #EFEFEF",
              position: "absolute",
              bottom: "0px",
              right: "0px",
              paddingTop: "16px",
              background: "#FFF",
              paddingX: "28px",
              paddingBottom: "16px",
            }}
          >
            <Typography
              sx={{ fontSize: "12px", fontWeight: "500", color: "#333333" }}
              component={"div"}
            >
              {selectedCodes.length} Selected
            </Typography>

            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Box
                sx={{
                  display: "flex",
                  gap: "4px",
                  maxWidth: "370px",
                  overflowX: "auto",
                }}
              >
                {selectedCodes.map((subject, index) => (
                  <Box
                    key={index}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      border: "1px solid #D2D2D0",
                      borderRadius: "4px",
                      paddingX: "8px",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "12px",
                        fontWeight: "500",
                        color: "#333333",
                        marginRight: "4px",
                      }}
                    >
                      {subject.sub_code}
                    </Typography>
                    <Box
                      sx={{ lineHeight: 0, cursor: "pointer" }}
                      onClick={() => handleRemoveSelectedSubject(subject.id)}
                    >
                      <Icons
                        width={14}
                        height={14}
                        name="close"
                        fill="#8E8D8A"
                      />
                    </Box>
                  </Box>
                ))}
              </Box>

              <Box
                sx={{
                  marginLeft: "auto",
                  cursor: selectedCodes.length === 0 ? "default" : "pointer",
                  opacity: selectedCodes.length === 0 ? "0.5" : "1",
                  pointerEvents: selectedCodes.length === 0 ? "none" : "auto",
                }}
              >
                <Button
                  text="Add"
                  textStyle={{ color: "#333" }}
                  handleClick={handleAddSub}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default AddNewStudentComponent;
