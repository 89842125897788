import client from "./client";

interface LoginPayload {
  email: string;
  password: string;
}

interface resetPayload {
  newpwd: string;
  confirmpwd: string;
}

interface ForgotPayload {
  email: string;
}

interface OtpPayload {
  otp: string;
}

export const loginCredentials = async (payload: LoginPayload) =>
  await client.post("/employees/login", payload);

export const verifyOtp = async (payload: OtpPayload, token: string) =>
  await client.post("/employees/verify", payload, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const forgotPassword = async (payload: ForgotPayload) =>
  await client.post("/employees/forgot-password", payload);

export const resetPassword = async (payload: resetPayload, token: string) =>
  await client.post("/employees/reset-password", payload, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const resendOtp = async (payload: object, token: string) =>
  await client.post("/employees/resend-otp", payload, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
