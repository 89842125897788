import React from "react";
import Dashboard from "../../layouts/Dashboard";
import { Box, Typography } from "@mui/material";
import { FileLibraryComponent } from "../../components/FileLibrary";

export const FileLibrary = () => {
  return (
    <Dashboard>
      <Box
        display={"flex"}
        flexDirection={"column"}
        flexGrow={1}
        gap={"24px"}
        paddingRight={"24px"}
      >
        <Typography color={"contour.title2"} variant="title2" component={"div"}>
          File Library
        </Typography>
        <FileLibraryComponent />
      </Box>
    </Dashboard>
  );
};
