export const timeValue = [
  { time: "9:00AM", value: "9AM" },
  { time: "10:00AM", value: "10AM" },
  { time: "11:00AM", value: "11AM" },
  { time: "12:00PM", value: "12PM" },
  { time: "1:00PM", value: "1PM" },
  { time: "2:00PM", value: "2PM" },
  { time: "3:00PM", value: "3PM" },
  { time: "4:00PM", value: "4PM" },
  { time: "5:00PM", value: "5PM" },
  { time: "6:00PM", value: "6PM" },
  { time: "7:00PM", value: "7PM" },
  { time: "8:00PM", value: "8PM" },
  { time: "9:00PM", value: "9PM" },
];
