import React from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Popover from "@mui/material/Popover";
import IconComponent from "../Icons";
import Popup from "../Popup";

type RowData = {
  id: number;
  File_name: string;
  Publish_status: string;
  Publish_timestamp: string;
  upload_timestamp: string;
  file_status: string;
  bug_count: number;
  file_description: string;
};

interface FilterComponentProps {
  handleSelectedFilter: (type: string, dynamicValue: string) => Promise<void>;
  selectedRowCount: number;
  selectedRowIds: number[];
  rowData: RowData[];
}

interface Option {
  label: string;
}

const fileStatusOptions: Option[] = [
  { label: "Not Started" },
  { label: "In review" },
  { label: "Completed" },
];

const SelectedFilter: React.FC<FilterComponentProps> = ({
  handleSelectedFilter,
  selectedRowCount,
  rowData,
  selectedRowIds,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null,
  );
  const [selectedOptions, setSelectedOptions] = React.useState<string>("");
  const [anchorEl1, setAnchorEl1] = React.useState<HTMLButtonElement | null>(
    null,
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick1 = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl1(event.currentTarget);
  };
  const handleClose1 = () => {
    setAnchorEl1(null);
  };

  const [openPublish, setOpenPublish] = React.useState(false);
  const [openUnpublish, setOpenUnpublish] = React.useState(false);
  const [openSchedule, setOpenSchedule] = React.useState(false);
  const handlePopupPublish = () => {
    setAnchorEl1(null);
    setOpenPublish(true);
  };
  const handlePopupPublishClose = () => {
    setOpenPublish(false);
  };

  const handlePopupPublishApi = () => {
    handleSelectedFilter("published", "published");
    setOpenPublish(false);
  };

  const handlePopupUnpublish = () => {
    setAnchorEl1(null);
    setOpenUnpublish(true);
  };
  const handlePopupUnpublishClose = () => {
    setOpenUnpublish(false);
  };

  const handlePopupUnpublishApi = () => {
    handleSelectedFilter("published", "draft");
    setOpenUnpublish(false);
  };

  const handlePopupSchedule = () => {
    setAnchorEl1(null);
    setOpenSchedule(true);
  };

  const handlePopupScheduleApi = () => {
    handleSelectedFilter("published", "scheduled");
    setOpenSchedule(false);
  };

  const handlePopupScheduleClose = () => {
    setOpenSchedule(false);
  };

  const publish = [
    {
      label: "Publish files",
      onClick: handlePopupPublish,
    },
    {
      label: "Unpublish files",
      onClick: handlePopupUnpublish,
    },
    {
      label: "Schedule files",
      onClick: handlePopupSchedule,
    },
  ];

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const open1 = Boolean(anchorEl1);
  const id1 = open1 ? "simple-popover1" : undefined;

  const handleClickExport = () => {
    const selectedRows = rowData.filter((row) =>
      selectedRowIds.includes(row.id),
    );

    const csvContent =
      "data:text/csv;charset=utf-8," +
      selectedRows.map((row) => Object.values(row).join(",")).join("\n");
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "selected_rows.csv");
    document.body.appendChild(link);
    link.click();
  };

  const handleOptionClick = (option: string) => {
    const lowerCaseOption = option.toLowerCase();
    handleSelectedFilter("status", lowerCaseOption);
    setSelectedOptions(option);
    setAnchorEl(null);
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
      }}
    >
      <Typography
        sx={{
          display: "flex",
          alignItems: "center",
          border: "1px solid #FFA737",
          height: "34px",
          borderRadius: "8px",
          fontSize: "14px",
          fontWeight: "600",
          color: "#333",
          paddingLeft: "12px",
          cursor: "pointer",
          marginRight: "8px",
          backgroundColor: anchorEl !== null ? "#FFEDD1" : "transparent",
          "&:hover": {
            backgroundColor: "#ffa7371f",
          },
        }}
        onClick={handleClick}
      >
        Edit file status
        <Box
          sx={{
            borderLeft: "1px solid #FFA737",
            height: "34px",
            display: "flex",
            alignItems: "center",
            paddingLeft: "12px",
            paddingRight: "12px",
            marginLeft: "12px",
          }}
        >
          <IconComponent name="userArrowDown" fill={"#333333"} />
        </Box>
      </Typography>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        sx={{
          ".css-0": {
            padding: "8px 16px 8px 12px",
            cursor: "pointer",
            borderBottom: "1px solid #D2D2D0",
            "&:hover": {
              background: "#ffa7371f",
            },
          },
          ".css-3bmhjh-MuiPaper-root-MuiPopover-paper": {
            border: "1px solid #D2D2D0",
            width: "158px",
            borderRadius: "8px",
          },
          ".css-1s8ndmz": {
            borderBottom: "1px solid #D2D2D0",
            cursor: "pointer",
            "&:hover": {
              backgroundColor: "#ffa7371f",
            },
          },
          ".css-12xgrea-MuiButtonBase-root-MuiCheckbox-root": {
            padding: "0px",
            marginRight: "8px",
          },
          ".css-oq7pu-MuiButtonBase-root-MuiCheckbox-root": {
            padding: "0px",
            marginRight: "8px",
            marginTop: "3px",
          },
        }}
      >
        <>
          {fileStatusOptions.map((option, index) => {
            let backgroundColor;
            let textColor;
            switch (option.label) {
              case "Not Started":
                backgroundColor = "#EBECF1";
                textColor = "#3B4478";
                break;
              case "In review":
                backgroundColor = "#FFF0E2";
                textColor = "#B85800";
                break;
              case "Completed":
                backgroundColor = "#E3F5F5";
                textColor = "#007668";
                break;
              default:
                backgroundColor = "transparent";
                textColor = "#333";
            }

            return (
              <Box
                key={index}
                style={{
                  background: selectedOptions.includes(option.label)
                    ? "#FFF8EC"
                    : "",
                }}
                onClick={() => handleOptionClick(option.label)}
              >
                <span
                  style={{
                    fontSize: "12px",
                    fontWeight: "500",
                    padding: "4px 8px",
                    color: textColor,
                    background: backgroundColor,
                    borderRadius: "16px",
                  }}
                >
                  <Box
                    width={"6px"}
                    height={"6px"}
                    bgcolor={textColor}
                    borderRadius={"50%"}
                    marginBottom={"1px"}
                    marginRight={"6px"}
                    display={"inline-block"}
                  />
                  {option.label}
                </span>
              </Box>
            );
          })}
        </>
      </Popover>

      <Typography
        sx={{
          display: "flex",
          alignItems: "center",
          border: "1px solid #FFA737",
          height: "34px",
          borderRadius: "8px",
          fontSize: "14px",
          fontWeight: "600",
          color: "#333",
          paddingLeft: "12px",
          cursor: "pointer",
          marginRight: "8px",
          backgroundColor: anchorEl1 !== null ? "#FFEDD1" : "transparent",
          "&:hover": {
            backgroundColor: "#ffa7371f",
          },
        }}
        onClick={handleClick1}
      >
        Publish
        <Box
          sx={{
            borderLeft: "1px solid #FFA737",
            height: "34px",
            display: "flex",
            alignItems: "center",
            paddingLeft: "12px",
            paddingRight: "12px",
            marginLeft: "12px",
          }}
        >
          <IconComponent name="userArrowDown" fill={"#333333"} />
        </Box>
      </Typography>
      <Popover
        id={id1}
        open={open1}
        anchorEl={anchorEl1}
        onClose={handleClose1}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        sx={{
          ".css-0": {
            padding: "8px 16px 8px 12px",
            cursor: "pointer",
            borderBottom: "1px solid #D2D2D0",
            "&:hover": {
              background: "#ffa7371f",
            },
          },
          ".css-3bmhjh-MuiPaper-root-MuiPopover-paper": {
            border: "1px solid #D2D2D0",
            width: "168px",
            borderRadius: "8px",
          },
          ".css-1s8ndmz": {
            borderBottom: "1px solid #D2D2D0",
            cursor: "pointer",
            "&:hover": {
              backgroundColor: "#ffa7371f",
            },
          },
          ".css-12xgrea-MuiButtonBase-root-MuiCheckbox-root": {
            padding: "0px",
            marginRight: "8px",
          },
          ".css-oq7pu-MuiButtonBase-root-MuiCheckbox-root": {
            padding: "0px",
            marginRight: "8px",
            marginTop: "3px",
          },
        }}
      >
        <>
          {publish.map((option, index) => {
            return (
              <Box
                key={index}
                onClick={option.onClick}
              >
                <span
                  style={{
                    fontSize: "14px",
                    fontWeight: "400",
                    padding: "4px 8px",
                    borderRadius: "16px",
                  }}
                >
                  {option.label}
                </span>
              </Box>
            );
          })}
        </>
      </Popover>

      <Popup
        open={openPublish}
        handleClose={handlePopupPublishClose}
        title={`Publish ${selectedRowCount} files?`}
        btnText="Publish"
        handleButton={handlePopupPublishApi}
        width="405px"
        heading={true}
        canText={true}
      >
        The file will be uploaded to the student portal.
      </Popup>

      <Popup
        open={openUnpublish}
        handleClose={handlePopupUnpublishClose}
        title={`Unpublish ${selectedRowCount} files?`}
        btnText="Unpublish"
        handleButton={handlePopupUnpublishApi}
        width="397px"
        heading={true}
        canText={true}
      >
        The file will be unpublished.
      </Popup>

      <Popup
        open={openSchedule}
        handleClose={handlePopupScheduleClose}
        title={`Schedule ${selectedRowCount} files?`}
        btnText="Schedule"
        handleButton={handlePopupScheduleApi}
        width="397px"
        heading={true}
        canText={true}
      >
        The file will be scheduled.
      </Popup>

      <Typography
        sx={{
          display: "flex",
          alignItems: "center",
          border: "1px solid #FFA737",
          height: "34px",
          borderRadius: "8px",
          fontSize: "14px",
          fontWeight: "600",
          color: "#333",
          paddingLeft: "8px",
          paddingRight: "12px",
          cursor: "pointer",
          "&:hover": {
            backgroundColor: "#ffa7371f",
          },
        }}
        onClick={handleClickExport}
      >
        <Box
          sx={{
            height: "34px",
            display: "flex",
            alignItems: "center",
            paddingRight: "8px",
          }}
        >
          <IconComponent
            name="export"
            style={{ width: "16px", height: "16px" }}
          />
        </Box>
        Export CSV
      </Typography>
    </Box>
  );
};

export default SelectedFilter;
