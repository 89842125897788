import { Box, Typography } from "@mui/material";
import React from "react";

interface cardProps {
  type: string;
  title: string;
}

const OnHoverBtnsFullscreen: React.FC<cardProps> = ({ type, title }) => {
  return (
    <Box
      sx={{
        background: "#FFF",
        padding: "8px",
        borderRadius: "8px",
        display: "flex",
        alignItems: "center",
        border: "1px solid #D2D2D0",
        maxWidth: "327px",
        position: "fixed",
        bottom: "68px",
        zIndex: "10",
      }}
    >
      <Box sx={{ marginLeft: "12px", textAlign: "start !important" }}>
        <Typography
          color={"#696969"}
          sx={{ fontSize: "12px", fontWeight: "500", lineHeight: "15px" }}
        >
          {type}
        </Typography>
        <Typography
          color={"#333333"}
          sx={{ fontSize: "12px", fontWeight: "500", lineHeight: "15px" }}
        >
          {title}
        </Typography>
      </Box>
    </Box>
  );
};

export default OnHoverBtnsFullscreen;
