import React, { useState, useEffect, useRef, ChangeEvent } from "react";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import IconComponent from "../Icons";

const useDebounce = (value: string, delay: number) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  React.useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
};

export const SearchComponent: React.FC<{
  searchQuery?: string;
  setSearchQuery?: React.Dispatch<React.SetStateAction<string>>;
}> = ({ searchQuery, setSearchQuery }) => {
  const [searchTerm, setSearchTerm] = useState<string>("");
  const inputRef = useRef<HTMLInputElement | null>(null);

  const debouncedSearchTerm = useDebounce(searchTerm, 300);

  useEffect(() => {
    setSearchQuery && setSearchQuery(debouncedSearchTerm);
  }, [debouncedSearchTerm, setSearchQuery]);

  const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSearchTerm(value);
  };

  const handleClearSearch = () => {
    setSearchTerm("");
    inputRef.current && inputRef.current.focus();
  };

  return (
    <TextField
      inputRef={inputRef}
      type="text"
      placeholder="Search files"
      variant="outlined"
      sx={{
        ".Mui-focused": {
          background: "#FFF8EC",
          ".css-9sors8-MuiButtonBase-root-MuiIconButton-root:hover": {
            background: "transparent",
          },
          ".css-1yq5fb3-MuiButtonBase-root-MuiIconButton-root:hover": {
            background: "transparent",
          },
          ".css-14sxf79-MuiInputBase-input-MuiOutlinedInput-input": {
            color: "#333",
            fontSize: "14px",
            fontWeight: "400",
          },
        },
        ".MuiInputBase-root": {
          maxWidth: "220px",
          maxHeight: "34px",
          borderRadius: "8px",
          "&:focus-visible": {
            outline: "none !important",
          },
        },
        ".css-9sors8-MuiButtonBase-root-MuiIconButton-root": {
          padding: "0px",
          marginLeft: "-7px",
          pointerEvents: "none",
        },
      }}
      value={searchTerm}
      onChange={handleSearchChange}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <IconButton aria-label="search icon" edge="start">
              <IconComponent name="search" fill={"#8E8D8A"} />
            </IconButton>
          </InputAdornment>
        ),
        endAdornment: searchQuery && (
          <InputAdornment position="end">
            <IconButton
              aria-label="clear search"
              onClick={handleClearSearch}
              edge="end"
            >
              <IconComponent name="close" fill={"#8E8D8A"} />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};
