import * as React from "react";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { Box, FormControl, Link, MenuItem, Modal, Select, Typography } from "@mui/material";
import Icons from "../Icons";
import StatusDropdown from "../Dropdown/StatusDropdown";
import TextTooltip from "../Tooltips/TextTooltip";
import FileDetail from "../FileLibrary/fileDetails";
import ScheduleCalendarComponent from "../FileUpload/SceduleCalendar";
import { timeValue } from "../../utils/uploadTime";

type ToastState = {
  type: string;
  message: string;
};

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  borderRadius: "8px",
  bgcolor: '#F4F6FF',
  p: 4,
};

interface DataTableProps {
  fileData: {
    id: number;
    file_name: string;
    file_url: string;
    publish_status: string;
    file_status: string;
  }[];
  tabIndex: number;
  setToast: React.Dispatch<React.SetStateAction<ToastState | null>>;
}

type RowData = {
  id: number;
  File_name: string;
  Publish_status: string;
  Publish_timestamp: string;
  upload_timestamp: string;
  file_status: string;
  bug_count: number;
  file_description: string;
};


const handleCellClick = (
  params: unknown,
  event: { stopPropagation: () => void },
) => {
  event.stopPropagation();
};

export default function DataTable({
  fileData,
  tabIndex,
  setToast,
}: DataTableProps) {
  const getData =
    fileData.length > tabIndex
      ? fileData.filter(
        (item) => item.publish_status === fileData[tabIndex].publish_status,
      )
      : [];
  console.log("aaaa", fileData, getData);
  const [drawer, setDrawer] = React.useState(false);
  const [selectedRowData, setSelectedRowData] = React.useState<number>();
  const [isEditing, setIsEditing] = React.useState(false);
  const [openModal, setOpenModal] = React.useState(false);
  const [selectedEndDate, setSelectedEndDate] = React.useState<Date | null>(null);

  const handleBug = (val: string) => {
    console.log(val);
    if (val === 'scheduled') {
      setOpenModal(true);
    }
  };
  const handleCloseModal = () => {
    setOpenModal(false);
  };
  const handleDateChange = (date: Date | null) => {
    setSelectedEndDate(date);
  };

  const handleFileNameClick = (rowData: RowData) => {
    setSelectedRowData(rowData.id);
    setDrawer(true);
  };

  const handleFileDescription = (rowData: RowData) => {
    setSelectedRowData(rowData.id);
    setDrawer(true);
    setIsEditing(true);
  };

  const columns: GridColDef[] = [
    {
      field: "file_name",
      renderHeader: () => (
        <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
          <Typography variant="body1">File name</Typography>
          <Icons name="descImage" fill="#8E8D8A" width={10} height={12} />
        </Box>
      ),
      renderCell: (params) => {
        return (
          <Box
            display={"flex"}
            flexDirection={"row"}
            alignItems={"center"}
            justifyContent={"space-between"}
            width={"100%"}
          >
            <Box
              display={"flex"}
              gap={"8px"}
              flexDirection={"row"}
              alignItems={"center"}
              sx={{
                opacity: params.row.file_name == "" ? 0.5 : 1,
                cursor: "pointer",
              }}
              onClick={() => handleFileNameClick(params.row)}
            >
              <Icons name="textFile" fill="#8E8D8A" width={18} height={18} />
              <Typography variant="detail" sx={{ whiteSpace: 'initial' }}>{params.row.file_name}</Typography>
            </Box>
            <Box
              display={"flex"}
              gap={"8px"}
              flexDirection={"row"}
              alignItems={"center"}
            >
              {params.row.bug_count !== null && params.row.bug_count > 0 && (
                <TextTooltip
                  title={`${params.row.bug_count} Bug${params.row.bug_count > 1 ? "s" : ""
                    } reported`}
                >
                  <Box
                    sx={{
                      paddingX: "8px",
                      paddingY: "4px",
                      borderRadius: "4px",
                      bgcolor: "#FFEEF2",
                      lineHeight: 0,
                    }}
                    component={"div"}
                  >
                    <Icons name="bug" fill="#C61341" width={16} height={16} />
                  </Box>
                </TextTooltip>
              )}
            
              {params.row.file_description !== null &&
                params.row.file_description !== "" ? (
                <TextTooltip
                  title={
                    <Box
                      p={"12px"}
                      display={"flex"}
                      flexDirection={"row"}
                      gap={"8px"}
                      alignItems={"stretch"}
                    >
                      <Box
                        display={"flex"}
                        flexDirection={"column"}
                        gap={"8px"}
                      >
                        <Typography component={"p"} variant="detailBold">
                          File description
                        </Typography>
                        <Typography
                          whiteSpace={"normal"}
                          component={"div"}
                          variant="detail"
                        >
                          {params.row.file_description}
                        </Typography>
                      </Box>
                      <Box
                        sx={{ cursor: "pointer" }}
                        component={"div"}
                        onClick={() =>
                          params.row.file_description !== null &&
                            params.row.file_description !== ""
                            ? handleFileDescription(params.row)
                            : null
                        }
                        display={"flex"}
                        alignItems={"flex-end"}
                      >
                        <Icons
                          name="edit"
                          fill="#FFA737"
                          width={20}
                          height={20}
                        />
                      </Box>
                    </Box>
                  }
                  html
                >
                  <Box
                    sx={{
                      paddingX: "8px",
                      paddingY: "4px",
                      borderRadius: "4px",
                      bgcolor: "#F4F6FF",
                      cursor:
                        params.row.file_description !== null &&
                          params.row.file_description !== ""
                          ? "pointer"
                          : "default",
                      lineHeight: 0,
                    }}
                  >
                    <Box
                      sx={{
                        opacity:
                          params.row.file_description !== null &&
                            params.row.file_description !== ""
                            ? 1
                            : 0.3,
                        cursor:
                          params.row.file_description !== null &&
                            params.row.file_description !== ""
                            ? "pointer"
                            : "default",
                        lineHeight: 0,
                      }}
                    >
                      <Icons
                        name="description"
                        fill="#3B4478"
                        width={16}
                        height={16}
                      />
                    </Box>
                  </Box>
                </TextTooltip>
              ) : (
                <Box
                  sx={{
                    paddingX: "8px",
                    paddingY: "4px",
                    borderRadius: "4px",
                    bgcolor: "#F4F6FF",
                    cursor:
                      params.row.file_description !== null &&
                        params.row.file_description !== ""
                        ? "pointer"
                        : "default",
                    lineHeight: 0,
                  }}
                >
                  <Box
                    sx={{
                      opacity:
                        params.row.file_description !== null &&
                          params.row.file_description !== ""
                          ? 1
                          : 0.3,
                      cursor:
                        params.row.file_description !== null &&
                          params.row.file_description !== ""
                          ? "pointer"
                          : "default",
                      lineHeight: 0,
                    }}
                  >
                    <Icons
                      name="description"
                      fill="#3B4478"
                      width={16}
                      height={16}
                    />
                  </Box>
                </Box>
              )}

            </Box>
          </Box>
        );
      },
      width: 400,
    },
    {
      field: "publish_status",
      renderHeader: () => (
        <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
          <Typography variant="body1">File Status</Typography>
          <Icons name="descImage" fill="#8E8D8A" width={10} height={12} />
        </Box>
      ),
      width: 155,
      renderCell: (params) => {
        console.log("aanail", params.row.publish_status);
        return params.row.publish_status ? (
          <Box display={"flex"} alignItems={"center"} flexGrow={1}>
            <StatusDropdown
              sx={{ width: "100%" }}
              setToast={setToast}
              fileId={params.row.id}
              status={params.row.publish_status.toLowerCase()}
              type={"published"}
              timestamp={""}
              onClickCallback={handleBug}
            />
          </Box>
        ) : (
          <Icons name="minus" fill="#8E8D8A" width={16} height={16} />
        );
      },
    },
    {
      field: "file_status",
      headerName: "Status",
      description: "This column has a value getter and is not sortable.",
      sortable: false,
      width: 195,
      renderCell: (params) => {
        return params.row.file_status != "" ? (
          <Box display={"flex"} alignItems={"center"} flexGrow={1}>
            <StatusDropdown
              sx={{ width: "100%" }}
              setToast={setToast}
              fileId={params.row.id}
              status={params.row.file_status}
              type={"status"}
              timestamp={""}
              onClickCallback={handleBug}
            />
          </Box>
        ) : (
          <Icons name="minus" fill="#8E8D8A" width={16} height={16} />
        );
      },
    },
  ];

  return (
    <>
      {fileData.length === 0 ? (
        <Typography variant="body1">No Data</Typography>
      ) : (
        <DataGrid
          disableRowSelectionOnClick
          sx={{
            "& .MuiDataGrid-cell": {
              transition: "background-color 0.3s",
              "&:hover": {
                backgroundColor: "#FFF8EC",
              },
            },
            border: "none",
            "& .MuiDataGrid-menuIcon": {
              display: "none",
            },
            "& .MuiDataGrid-columnHeaderTitle": {
              textOverflow: "unset",
              color: "#1A1C1F",
              fontSize: 12,
              fontWeight: "600",
            },
            ".MuiDataGrid-columnHeaders": {
              bgcolor: "#F4F6FF",
              borderTopLeftRadius: "12px",
              borderTopRightRadius: "12px",
              minHeight: "44px !important",
              border: "none",
            },
            ".MuiDataGrid-columnSeparator": {
              display: "none",
            },
            "& .MuiDataGrid-withBorderColor:focus": {
              outline: "none",
            },
            "& .MuiCheckbox-root": {
              borderRadius: "8px",
            },
            ".MuiDataGrid-row.Mui-selected": {
              background: "#FFF8EC",
            },
            ".css-12xgrea-MuiButtonBase-root-MuiCheckbox-root:hover": {
              background: "none",
            },
            ".MuiDataGrid-row:hover": {
              background: "transparent",
            },
            ".MuiDataGrid-row.Mui-selected:hover": {
            },
            "& .MuiCheckbox-colorPrimary.Mui-checked": {
              "& .MuiSvgIcon-root": {
                color: "#FFA737",
              },
            },
            "& .MuiDataGrid-withBorderColor:focus-within": {
              outline: "none",
            },
            ".MuiButtonBase-root.MuiIconButton-root.MuiIconButton-sizeMedium": {
              backgroundColor: "unset",
              color: "unset",
            },
            ".css-78trlr-MuiButtonBase-root-MuiIconButton-root:hover": {
              backgroundColor: "unset !important",
              color: "unset !important",
            },
            ".MuiDataGrid-iconButtonContainer": {
              display: "none",
            }
          }}
          rows={fileData}
          columns={columns}
          hideFooter
          disableColumnMenu
          onCellClick={handleCellClick}
        />
      )}

      <FileDetail
        drawer={drawer}
        setDrawer={setDrawer}
        selectedRowData={selectedRowData}
        isEditing={isEditing}
        setIsEditing={setIsEditing}
      />

      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={style}
        >
          <Typography
            sx={{
              fontSize: "16px",
              fontWeight: "600",
            }}
          >
            Schedule files
          </Typography>
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: "400",
              marginBottom: "10px",
            }}
          >
            Select date and time for scheduling
          </Typography>
          <Box>
            <ScheduleCalendarComponent
              selectedStartDate={null}
              handleDateChange={handleDateChange}
              selectedEndDate={selectedEndDate} 
            />
          </Box>
          <Typography
            sx={{
              marginBottom: "10px",
              marginTop: "4px",
            }}
          >
            <label
              style={{
                display: "block",
                fontSize: "12px",
                fontWeight: "500",
                color: "#333",
              }}
            >
              Time
            </label>
            <FormControl
              sx={{
                "&.MuiFormControl-root": {
                  margin: "0px",
                  width: "100%",
                },
                "&.MuiFormControl-root .MuiInputBase-root": {
                  borderRadius: "8px",
                },
                "&.MuiFormControl-root .MuiSelect-select": {
                  padding: "12px 16px",
                },
              }}
            >
              <Select
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
                sx={{
                  ".MuiSvgIcon-root": {
                    color: "#8E8D8A !important",
                    fill: "#8E8D8A !important",
                  },
                }}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {timeValue.map((tm: { value: string; time: string }, index) => (
                  <MenuItem key={index} value={tm.value}>
                    {tm.time}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "end",
            }}
          >
            <Link
              onClick={() => console.log('schedule file')}
              underline="none"
              sx={{
                paddingX: "20px",
                bgcolor: "#FFA737",
                borderRadius: "8px",
                height: "44px",
              }}
              component={"button"}
            >
              <Typography color={"#333"} variant="cbutton">
                Confirm & Schedule
              </Typography>
            </Link>
          </Box>
        </Box>
      </Modal>
    </>
  );
}