import React, { useState } from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Box, Typography } from "@mui/material";
import Icons from "../Icons";
import SubjectDetailsModal from "../Subject/subjectDetailsModal";
import ToastMessage from "../ToastMessage";
import {
    closeSubject,
    deletSubject,
    openSubject,
} from "../../services/api/subjects";
import GlobalPopupModal from "../Popup/dialog";
import { useAppSelector } from "../../store/hooks";


type GetSub = {
    subject_group_id?: number;
    subject_group?: number;
    subject_id?: number;
    area_of_id?: number;
    topic_id?: number;
    type_id?: number;
    file_type_id?: number;
    search_key?: string;
};
interface EditSub {
    id?: number;
    mst_subject_group_id?: number;
    subject?: string;
    subject_description?: string | null;
    aos_id?: string | null;
    area_of_study?: string | null;
    area_of_study_description?: string | null;
    subject_icon?: string | null;
    subject_colour?: string;
    updated_by?: string | null;
    record_status?: string;
    createdAt?: string;
    updatedAt?: string;
    sub_code?: string;
}
interface subProps {
    children: React.ReactNode;
    boxSx: object;
    clicked: boolean;
    fetchEditSubjectData?: (id: number) => Promise<void>;
    closed?: boolean;
    noIcon?: boolean;
    fetchEditSubjectGroupData?: (id: number) => Promise<void>;
    type?: boolean;
    subId?: number;
    editSubData?: EditSub;
    setSeleTabData: React.Dispatch<React.SetStateAction<GetSub>>;
    handeleSetSubject?: () => void;
    subName?: string;
}

const SubjectFirstGrid = ({
    children,
    boxSx,
    clicked,
    fetchEditSubjectData,
    subId,
    editSubData,
    type,
    setSeleTabData,
    handeleSetSubject,
    subName
}: subProps) => {
    const [contextMenu, setContextMenu] = React.useState<{
        mouseX: number;
        mouseY: number;
    } | null>(null);
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
        null,
    );
    const [isHovering, setIsHovering] = React.useState(false);
    const [toast, setToast] = React.useState<{
        type: string;
        message: string;
    } | null>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMouseOver = () => {
        setIsHovering(true);
    };

    const handleMouseOut = () => {
        setIsHovering(false);
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;
    const auth = useAppSelector((state) => state.auth);

    const handleContextMenu = (event: React.MouseEvent) => {
        event.preventDefault();
        setContextMenu(
            contextMenu === null
                ? {
                    mouseX: event.clientX + 2,
                    mouseY: event.clientY - 6,
                }
                : 
                null,
        );
    };
    const [isEditCourseDetailsModalOpen, setEditCoursetDetailsModalOpen] =
        useState(false);

    const handleEditSubject = (val: number) => {
        fetchEditSubjectData && fetchEditSubjectData(val);
        setEditCoursetDetailsModalOpen(true);
    };
    const [isSubjectDetailsModalOpen, setIsSubjectDetailsModalOpen] =
        useState(false);

    const handleCloseModal = () => {
        setIsSubjectDetailsModalOpen(false);
    };

    const handleConfirm = async () => {
        const id =
            subId !== undefined ? subId.toString() : (undefined as unknown as string);
        await closeSubject(id, auth?.token)
            .then((response) => {
                handeleSetSubject && handeleSetSubject();
                setSeleTabData &&
                    setSeleTabData((prevFilters) => {
                        return {
                            subject_group: prevFilters.subject_group,
                        };
                    });
                setToast({
                    type: "success",
                    message: response.data.message,
                });
            })
            .catch((err) => {
                setToast({
                    type: "error",
                    message: err.response.data.error,
                });
            });

        setIsSubjectDetailsModalOpen(false);
    };


    const handleOpen = async () => {
        const id =
            subId !== undefined ? subId.toString() : (undefined as unknown as string);
        await openSubject(id, auth?.token)
            .then((response) => {
                handeleSetSubject && handeleSetSubject();
                setSeleTabData &&
                    setSeleTabData((prevFilters) => {
                        return {
                            subject_group: prevFilters.subject_group,
                        };
                    });
                setToast({
                    type: "success",
                    message: response.data.message,
                });
            })
            .catch((err) => {
                setToast({
                    type: "error",
                    message: err.response.data.error,
                });
            });

        setIsSubjectDetailsModalOpen(false);
    };

    const handleClose = async (val: string) => {
        setContextMenu(null);
        setAnchorEl(null);
        console.log(val);

        if (val === "Close" || val === "Open") {
            setIsSubjectDetailsModalOpen(true);
        }
        if (val == "delete") {
            const id =
                subId !== undefined
                    ? subId.toString()
                    : (undefined as unknown as string);
            await deletSubject(id, auth?.token)
                .then((response) => {
                    console.log(response);
                    handeleSetSubject && handeleSetSubject();
                    setSeleTabData &&
                        setSeleTabData((prevFilters) => {
                            return {
                                subject_group: prevFilters.subject_group,
                            };
                        });
                    setToast({
                        type: "success",
                        message: response.data.message,
                    });
                })
                .catch((err) => {
                    setToast({
                        type: "error",
                        message: err.response.data.error,
                    });
                });
        }
    };


    return (
        <Box
            display={"flex"}
            flexDirection={"row"}
            gap={"8px"}
            alignItems={"center"}
            onMouseOver={handleMouseOver}
            onMouseOut={handleMouseOut}
            aria-describedby={id}
            onContextMenu={handleContextMenu}
            style={{ cursor: type ? "pointer" : "context-menu" }}
            component={"button"}
            onClick={handleClick}
            sx={{ padding: "16px", width: "100%", ...boxSx }}
        >
            <Box flex={1}>{children}</Box>
            {isHovering || anchorEl || clicked ? (
                <Icons name="chevronRight" fill="#333333" />
            ) : (
                <Icons name="chevronRight" fill="#8E8D8A" />
            )}
            <Menu
                open={type ? false : contextMenu !== null}
                onClose={handleClose}
                anchorReference="anchorPosition"
                anchorPosition={
                    contextMenu !== null
                        ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
                        : undefined
                }
                sx={{
                    "& .MuiPaper-root": {
                        borderRadius: "8px",
                        minWidth: "135px",

                        boxShadow: "0px 4px 20px 0px rgba(45, 45, 45, 0.16)",
                        "& .MuiMenu-list": {
                            padding: "0px",
                        },
                    },
                }}
            >
                <MenuItem
                    sx={{
                        gap: "8px",
                        padding: "12px",
                        alignItems: "center",
                        flexDirection: "row",
                        borderBottom: "1px solid #D2D2D0",
                    }}
                    onClick={() => {
                        handleClose("Edit");
                        subId && handleEditSubject(subId);
                    }}
                >
                    <Icons name="edit" fill="#8E8D8A" width={20} height={20} />
                    <Typography color={"#333"} variant="bodySm">
                        Edit
                    </Typography>
                </MenuItem>
            </Menu>
            <GlobalPopupModal
                titleName={closed ? "Open subject" : "Close subject"}
                open={isSubjectDetailsModalOpen}
                btnText={closed ? "Confirm open" : "Confirm close"}
                handleCloseModal={handleCloseModal}
                handleFooterCancle={handleCloseModal}
                handleFooterBtn={closed ? handleOpen : handleConfirm}
                modalWidth={"396px"}
            >
                <Typography color="contour.title3" variant="title4">
                    Are you sure you want to {closed ? "open" : "close"} {subName} ?
                </Typography>
                <Typography
                    color="contour.inactive"
                    variant="bodyLg"
                    sx={{ fontWeight: "400", display: "block", marginTop: "8px" }}
                >
                    All subsequent folders will become{" "}
                    {closed ? "available" : "unavailable"} in the student portal.
                </Typography>
            </GlobalPopupModal>
            <SubjectDetailsModal
                open={isEditCourseDetailsModalOpen}
                handleCloseModal={() => setEditCoursetDetailsModalOpen(false)}
                titleName={"Edit Subject group"}
                name={"Subject group name"}
                description={"Subject group description"}
                iconName={"Subject group icon"}
                setSeleTabData={setSeleTabData}
                type={"edit sub-group"}
                editSubData={editSubData}
                setToast={setToast}
            />
            {toast && (
                <ToastMessage
                    type={toast.type as "success" | "error"}
                    message={toast.message}
                    onClose={() => setToast(null)}
                    style={{
                        position: "absolute",
                        top: "5%",
                    }}
                />
            )}
        </Box>
    );
};

export default SubjectFirstGrid;

