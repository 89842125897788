import React, { useState } from "react";
import Dashboard from "../../layouts/Dashboard";
import { Box, Typography } from "@mui/material";
import { StudentComponent } from "../../components/Student";
import GlobalPopupModal from "../../components/Popup/dialog";
import AddNewStudentComponent from "../../components/Student/AddNewSubjectComponent";
import { addStudentApi } from "../../services/api/student";
import Cookies from "js-cookie";
import ToastMessage from "../../components/ToastMessage";
import TransparentBdrBtn from "../../components/Button/TranparentBdrBtn";
import Icons from "../../components/Icons";

type SubPayload = {
  subject_id: number;
  access_status: boolean;
};

interface addStuPayload {
  email: string;
  first_name: string;
  last_name: string;
  phone: string;
  campus_id: number;
  enrolment_status: string;
  subjects: SubPayload[];
}

export const Student = () => {
  const verifyAccessString = Cookies.get("authAccess");
  const verifyAccess = verifyAccessString
    ? JSON.parse(verifyAccessString)
    : null;
  const [isStudentModalOpen, setIsStudentModalOpen] = useState(false);
  const [addStudent, setAddSubject] = useState(true);
  const [loading,setLoading]=useState(false)
  const initialStudentData: addStuPayload = {
    email: "",
    first_name: "",
    last_name: "",
    phone: "",
    campus_id: 1,
    enrolment_status: "true",
    subjects: [],
  };
  const [toast, setToast] = React.useState<{
    type: string;
    message: string;
  } | null>(null);
  const [studentData, setStudentData] = useState(initialStudentData);

  const isCreateButtonDisabled = !(
    studentData.first_name &&
    studentData.last_name &&
    studentData.phone &&
    studentData.email &&
    studentData.campus_id &&
    studentData.subjects.length > 0
  );

  const updateStudentData = (data: Partial<addStuPayload>) => {
    setStudentData((prevData) => ({ ...prevData, ...data }));
  };
  const handleCloseModal = () => {
    setIsStudentModalOpen(false);
  };
  const handleAddStudent = () => {
    setIsStudentModalOpen(true);
  };
  const handleFooterBtn = () => {
    setAddSubject(false);
  };
  const onBack = () => {
    setAddSubject(true);
  };
  

  const handleCreateNewStudent = async () => {

    if (
      !studentData.first_name ||
      !studentData.last_name ||
      !studentData.phone ||
      !studentData.email ||
      !studentData.campus_id ||
      studentData.subjects.length === 0
    ) {
      console.error("Please fill in all required fields");
      return;
    }

    setLoading(true);
    try {
      const token = verifyAccess.token;
      const response = await addStudentApi(studentData, token);
      handleCloseModal();
      const successMessage = response.data.message;
      setToast({
        type: "success",
        message: successMessage,
      });
    } catch (error) {
      console.error("Error adding student", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dashboard>
      <Box
        display={"flex"}
        flexDirection={"column"}
        flexGrow={1}
        gap={"24px"}
        paddingRight={"24px"}
      >
        {toast && (
          <ToastMessage
            type={toast.type as "success" | "error"}
            message={toast.message}
            onClose={() => setToast(null)}
            style={{
              position: "abosolute",
              top: "90px",
              textTransform: "capitalize",
            }}
          />
        )}
        <Box sx={{ display: "flex" }}>
          <Typography
            sx={{ marginRight: "auto" }}
            color={"contour.title2"}
            variant="title2"
            component={"div"}
          >
            Students
          </Typography>
          <TransparentBdrBtn
            text="Add a student"
            icon={<Icons name="add" fill="#333" width={16} height={16} />}
            textStyle={{ color: "#333" }}
            handleClick={handleAddStudent}
          />
        </Box>
        <StudentComponent />
      </Box>

      <GlobalPopupModal
        titleName={addStudent ? "New Student" : "New Student"}
        btnText={addStudent ? "Create new student" : ""}
        open={isStudentModalOpen}
        handleCloseModal={handleCloseModal}
        loading={loading}
        handleFooterBtn={
          addStudent ? handleCreateNewStudent : handleCreateNewStudent
        }
        modalWidth={"720px"}
        isCreateButtonDisabled={isCreateButtonDisabled}
      >
        <AddNewStudentComponent
          isAddingStudent={addStudent}
          HandAddSubject={handleFooterBtn}
          onBack={onBack}
          updateStudentData={updateStudentData}
        />
      </GlobalPopupModal>
    </Dashboard>
  );
};
