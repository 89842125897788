import React, { useState, useEffect } from "react";
import { Box, Typography, Skeleton, CircularProgress } from "@mui/material";
import { SearchComponent } from "../FileLibrary/searchComponent";
import FilteredComponentDD from "./filteredComponentDD";
import IconComponent from "../Icons";
import TableComponent from "./tableComponent";
import Cookies from "js-cookie";
import {
  disableStuSubAccess,
  getCampusList,
  getStudentSubject,
  getSubjectList,
  refreshStudentData,
} from "../../services/api/student";
import ToastMessage from "../ToastMessage";
import { useSelector } from "react-redux";
import { RootState } from "../../store";

type Student = {
  lastAccessed: any;
  last_access_date: any;
  time_spent: any;
  id: string;
  mst_student_id: number;
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  access_start_date: string | null;
  access_end_date: string | null;
  access_status: boolean;
  campus_id: string;
};

type TableData = {
  lastAccessed: any;
  last_access_date: any;
  time_spent: any;
  id: number;
  sub_code: string;
  label: string;
  students: Student[];
};

type GetStudent = {
  campus_name?: string;
  subject_name?: string;
  enrolment_status?: string;
  visit_by?: string;
  global_search_keyword?: string;
  page?: number;
  limit?: number;
};

interface Campus {
  id: number;
  campus_name: string;
}

interface SubjectList {
  id: number;
  sub_code: string;
  subject: string;
}

export const StudentComponent = () => {
  const verifyAccessString = Cookies.get("authAccess");
  const { shouldRefetch } = useSelector((state: RootState) => state.studentLibrary)
  const verifyAccess = verifyAccessString
    ? JSON.parse(verifyAccessString)
    : null;
  const [selectedTab, setSelectedTab] = useState("Student");
  const [stuData, setStuData] = useState<TableData[]>([]);
  const [selectedCount, setSelectedCount] = useState<number>(0);
  const [subjectsSelectedHide, setSubjectsSelectedHide] =
    useState<boolean>(true);
  const [filters, setFilters] = useState<GetStudent>({});
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [campusData, setCampusData] = useState<Campus[]>([]);
  const [subjectData, setSubjectData] = useState<SubjectList[]>([]);
  const [loading, setLoading] = useState(true);
  const [loadingRefresh, setLoadingRefresh] = useState(false);
  const [checkData, setCheckData] = useState<number[]>();
  console.log("Filter main", selectedTab);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 15
  });
  const [tableLoading, setTableLoading] = useState(true);
  const [totallCount, setTotallCount] = React.useState<string>("0");
  const [rowCountState, setRowCountState] = React.useState(totallCount);


  const handleSelectionChange = (count: number, countData: number[]) => {
    setSelectedCount(count);
    setSubjectsSelectedHide(count === 0);
    setCheckData(countData);
  };
  const [toast, setToast] = React.useState<{
    type: string;
    message: string;
  } | null>(null);
  useEffect(() => {
    fetchData(selectedTab);
  }, [selectedTab, filters, searchQuery]);
  useEffect(() => {
    shouldRefetch !== null && fetchData(selectedTab);
  }, [shouldRefetch]);

  useEffect(() => {
    fetchCampusData();
    fetchSubjectData();
  }, []);

  const fetchData = async (select: string) => {
    try {
      setTableLoading(true);
      const visitBy = select === "Student" ? "student" : "subject";
      const response = await getStudentSubject(
        {
          visit_by: visitBy,
          ...filters,
          global_search_keyword: searchQuery !== "" ? searchQuery : undefined,
        },
        verifyAccess.token,
        paginationModel.page,
        paginationModel.pageSize,
        select,
      );

      if (!response.data.success || response.data.data.length === 0) {
        setStuData([]);
      } else {
        setStuData(response.data.data.students);
        setTotallCount(response.data.data.totalCount)
      }
      setLoading(false);
      setTableLoading(false);
    } catch (error) {
      setLoading(false);
      setTableLoading(false);
      console.error("Error fetching data:", error);
    }
  };
  React.useEffect(() => {
    fetchData(selectedTab);
  }, [paginationModel, selectedTab, filters, searchQuery]);

  React.useEffect(() => {
    setRowCountState
  }, [setRowCountState]);

  const fetchCampusData = async () => {
    try {
      const response = await getCampusList(verifyAccess.token);
      setCampusData(response.data.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchRefreshStudentData = async () => {
    setLoadingRefresh(true);
    try {
      const response = await refreshStudentData(verifyAccess.token);
      if (response && response.data.success) {
        fetchData("Student");
        setLoadingRefresh(false);
        const successMessage = response.data.message;
        setToast({
          type: "success",
          message: successMessage,
        });
      } else {
        const errorMessage =
          response && response.data.message
            ? response.data.message
            : "An error occured";
        setToast &&
          setToast({
            type: "error",
            message: errorMessage,
          });
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleDisable = async () => {
    try {
      const token = verifyAccess.token;
      const payload = {
        subjectIds: checkData,
      };
      setLoading(true);
      const response = await disableStuSubAccess(payload, token);
      if (response && response.data.success) {
        const successMessage = response.data.message;
        setToast({
          type: "success",
          message: successMessage,
        });
        fetchData(selectedTab);
        setLoading(false);
        fetchData("subject");
        setSelectedCount(0);
        setSubjectsSelectedHide(true);
      } else {
        const errorMessage =
          response && response.data.message
            ? response.data.message
            : "An error occured";
        setToast &&
          setToast({
            type: "error",
            message: errorMessage,
          });
      }
      console.log("updateStuSubAccess", response);
    } catch (error) {
      console.error("Error fetching student details:", error);
    }
  };

  const fetchSubjectData = async () => {
    try {
      const response = await getSubjectList(verifyAccess.token);
      setSubjectData(response.data.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  React.useEffect(() => {
    setRowCountState((prevRowCountState: any) =>
      totallCount !== undefined
        ? totallCount
        : prevRowCountState,
    );
  }, [totallCount, setRowCountState]);

  return (
    <>
      {toast && (
        <ToastMessage
          type={toast.type as "success" | "error"}
          message={toast.message}
          onClose={() => setToast(null)}
          style={{
            top: "85px",
          }}
        />
      )}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            border: "1px solid #D2D2D0",
            borderRadius: "4px",
            maxWidth: "413px",
            paddingX: "8px",
            paddingY: "12px",
            alignItems: "center",
          }}
        >
          <IconComponent name="info" fill={"#8E8D8A"} />
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: "400",
              color: "#181A20",
              paddingX: "12px",
            }}
          >
            View Only: Edit student details via monday.com
          </Typography>
        </Box>
        {!loadingRefresh ? (
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: "500",
              color: "#181A20",
              height: "40px",
              paddingX: "12px",
              border: "1px solid #D2D2D0",
              background: "#FFA737",
              cursor: "pointer",
              borderRadius: "8px",
              display: "flex",
              alignItems: "center",
            }}
            onClick={fetchRefreshStudentData}
          >
            Refresh Student Data
          </Typography>
        ) : (
          <Typography
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              background: "#FFA737",
              borderRadius: "8px",
              width: "168px",
              height: "38px",
            }}
          >
            <CircularProgress
              size={27}
              thickness={5}
              sx={{
                color: "#fff",
              }}
            />
          </Typography>
        )}
      </Box>
      {subjectsSelectedHide ? (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              display: "flex",
            }}
          >
            <Typography
              sx={{
                marginRight: "8px",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                fontSize: "12px",
                fontWeight: "500",
                color: "#333",
              }}
            >
              Filter by
            </Typography>
            <FilteredComponentDD
              setFilters={setFilters}
              campusData={campusData}
              subjectData={subjectData}
            />
          </Box>

          <Box sx={{ display: "flex" }}>
            <Typography
              sx={{
                marginRight: "8px",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                fontSize: "12px",
                fontWeight: "500",
                color: "#333",
              }}
            >
              View list by
            </Typography>
            <Box
              sx={{
                display: "flex",
                border: "1px solid #D2D2D0",
                borderRadius: "8px",
                alignItems: "center",
                marginRight: "20px",
              }}
            >
              <div
                style={{
                  background: selectedTab === "Student" ? "#FFA737" : "#FFF",
                  borderTopLeftRadius: "7px",
                  borderBottomLeftRadius: "7px",
                }}
                onClick={() => {
                  fetchData("Student");
                  setSelectedTab("Student");
                }}
              >
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: selectedTab === "Student" ? "600" : "400",
                    color: selectedTab === "Student" ? "#33333" : "#696969",
                    paddingX: "12px",
                    cursor: "pointer",
                    paddingY: "4px",
                  }}
                >
                  Student
                </Typography>
              </div>
              <Box
                sx={{ background: "#D2D2D0", width: "1px", height: "100%" }}
              />
              <div
                style={{
                  background: selectedTab === "Subject" ? "#FFA737" : "#FFF",
                  borderTopRightRadius: "7px",
                  borderBottomRightRadius: "7px",
                }}
                onClick={() => {
                  fetchData("Subject");
                  setSelectedTab("Subject");
                }}
              >
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: selectedTab === "Subject" ? "600" : "400",
                    color: selectedTab === "Subject" ? "#33333" : "#696969",
                    paddingX: "12px",
                    cursor: "pointer",
                    paddingY: "4px",
                  }}
                >
                  Subject
                </Typography>
              </div>
            </Box>
            <SearchComponent
              searchQuery={searchQuery}
              setSearchQuery={setSearchQuery}
            />
          </Box>
        </Box>
      ) : (
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Typography
            variant="detail"
            color="#333333"
          >{`${selectedCount} Selected`}</Typography>
          <Box
            sx={{
              border: "1px solid #FFA737",
              marginLeft: "12px",
              cursor: "pointer",
              borderRadius: "8px",
            }}
            component={"div"}
            onClick={handleDisable}
          >
            <Typography
              color={"#333333"}
              sx={{
                fontSize: "14px",
                fontWeight: "600",
                paddingX: "12px",
                cursor: "pointer",
                paddingY: "4px",
              }}
            >
              Disable access for all student
            </Typography>
          </Box>
        </Box>
      )}
      {loading ? (
        <Skeleton
          variant="rounded"
          width={"100%"}
          height={"calc(100vh - 330px)"}
        />
      ) : (
        <TableComponent
          selectedTab={selectedTab}
          handleSelectionChange={handleSelectionChange}
          stuData={stuData}
          loading={loading}
          fetchData={fetchData}
          tableLoading={tableLoading}
          rowCountState={rowCountState}
          paginationModel={paginationModel}
          setPaginationModel={setPaginationModel}
        />
      )}
    </>
  );
};
