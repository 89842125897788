import React from "react";
import Modal from "@mui/material/Modal";
import { Box, Typography, Link, CircularProgress } from "@mui/material";
import Icons from "../Icons";

interface PopupProps {
  title: string;
  children: React.ReactNode;
  open: boolean;
  handleClose: () => void;
  handleButton?: () => void;
  btnText?: string;
  heading?: boolean;
  canText?: boolean;
  width: string;
  upload?: boolean;
  uploadingFile?: boolean;
  setShowProgress?: (newState: boolean) => void;
  loading?: boolean;
}

const Popup = ({
  title,
  children,
  open,
  handleClose,
  btnText = "",
  handleButton,
  width,
  heading,
  canText,
  upload,
  loading,
}: PopupProps) => {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: width,
    bgcolor: "background.paper",
    borderRadius: "16px",
    boxShadow: "5px 20px 30px 0px rgba(45, 45, 45, 0.20)",
    maxHeight: "100vh",
    overflowY: "auto",
  };
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      disableEscapeKeyDown={true}
      hideBackdrop={true}
    >
      <Box sx={style}>
        <Box
          sx={{
            borderBottom: heading ? "none" : "1px solid #EFEFEF",
            paddingX: "28px",
            paddingY: "16px",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography
            color={"#333"}
            sx={{
              fontSize: "28px",
              fontWeight: "600",
              fontFamily: "PolySans_Median",
            }}
          >
            {title}
          </Typography>
          {!heading && (
            <Box
              sx={{ lineHeight: 0, cursor: "pointer" }}
              onClick={handleClose}
            >
              <Icons width={20} height={20} name="close" fill="#8E8D8A" />
            </Box>
          )}
        </Box>
        <Box
          sx={{
            backgroundColor: upload ? "#F4F6FF" : "transparent",
            borderBottomRightRadius: "16px",
            borderBottomLeftRadius: "16px",
            height: "100%",
            position: "relative",
            padding: "24px 28px",
          }}
        >
          <Box
            sx={{
              top: "24px",
              bottom: "24px",
              left: "12px",
              right: "12px",
              overflowY: "auto",
            }}
          >
            {children}
          </Box>
        </Box>

        {btnText && (
          <Box
            display={"flex"}
            flexDirection={"row"}
            alignItems={"flex-end"}
            justifyContent={"end"}
            flex={1}
            sx={{ paddingX: "28px", paddingY: "16px" }}
          >
            {canText && (
              <Link
                onClick={handleClose}
                underline="none"
                sx={{
                  paddingX: "20px",
                  marginRight: "8px",
                  border: "1px solid #FFA737",
                  borderRadius: "8px",
                  height: "30px",
                }}
                component={"button"}
              >
                <Typography color={"#333"} variant="cbutton">
                  Cancel
                </Typography>
              </Link>
            )}
            <Link
              onClick={handleButton}
              underline="none"
              disabled={loading ? true : false}
              sx={{
                paddingX: "20px",
                bgcolor: "#FFA737",
                borderRadius: "8px",
                height: "30px",
                pointerEvents: loading ? 'none' : 'pointer',
                opacity: loading ? '0.5' : '1',
              }}
              component={"button"}
            >
              <Typography sx={{ display: "flex", alignItems: "center" }} color={"#333"} variant="cbutton">
                {btnText}
                {
                  loading ? (
                    <CircularProgress sx={{ marginLeft: "8px" }} size={14} color="inherit" />
                  ) : ''
                }
              </Typography>
            </Link>
          </Box>
        )}
      </Box>
    </Modal>
  );
};

export default Popup;
