import React from "react";
import { Box } from "@mui/material";
import DocViewer, { PDFRenderer } from "@cyntler/react-doc-viewer";

interface FilesPreviewProps {
  file?: string;
}

const FilesPreview: React.FC<FilesPreviewProps> = React.memo(({ file }) => {
  const [docs, setDocs] = React.useState<any>([{ uri: "" }]);
  React.useEffect(() => {
    console.log("file123", file)
    const baseUrl = process.env.REACT_APP_API_URL;
    const fullUrl = baseUrl + "/" + file;
    setDocs([{ uri: fullUrl }]);
  }, [file]);
  return (
    <Box
    >
      <DocViewer
        pluginRenderers={[PDFRenderer]}
        documents={docs}
        config={{
          pdfVerticalScrollByDefault: true,
          header: {
            disableHeader: true,
            disableFileName: false,
            retainURLParams: false,
          },
        }}
        style={{
          height: "433px",
          backgroundColor: "#2B3258",
          overflowY: "hidden",
        }}
        theme={{
          disableThemeScrollbar: false,
        }}
      />
    </Box>
  );
});

export default FilesPreview;
