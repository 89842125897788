import React, { useState, useEffect } from "react";
import { Box, Typography, Skeleton } from "@mui/material";
import { SearchComponent } from "../FileLibrary/searchComponent";
import FilteredComponent from "./filteredComponent";
import CustomFileFinder from "./customOpenSubject";
import Cookies from "js-cookie";
import { getSubjectList } from "../../services/api/student";
import {
  getAOSList,
  getSubjectData,
  getTopicList,
  getTypeList,
} from "../../services/api/subjects";
import ToastMessage from "../ToastMessage";

interface SubjectList {
  id: number;
  sub_code: string;
  subject: string;
}
interface AosList {
  id: number;
  area_of_study: string;
}
interface TypeList {
  id: number;
  file_type: string;
}
interface TopicList {
  id: number;
  topic: string;
}

type Sub = {
  subject_id?: number;
  record_status?: string;
  subject_colour?: string;
  subject_icon?: string;
  subject_description?: string;
  subject_group_id?: number;
  subject_group?: string;
  subject?: string;
  area_of_study_id?: number;
  area_of_study?: string;
  topic_id?: number;
  topic?: string;
  topic_description?: string;
  type_id?: number;
  type?: string;
  file_id?: number;
  file_names?: string;
  file_type?: string;
  file_type_id?: number;
  area_of_study_icon?: string;
  area_of_study_description?: string;
};

type GetSub = {
  subject_group_id?: number;
  subject_group?: string;
  subject_id?: string;
  area_of_id?: string;
  topic_id?: string;
  type_id?: string;
  search_key?: string;
};

type GetSubb = {
  subject_group_id?: number;
  subject_group?: number;
  subject_id?: number;
  area_of_id?: number;
  topic_id?: number;
  type_id?: number;
  search_key?: string;
};

export const SubjectComponent = () => {
  const verifyAccessString = Cookies.get("authAccess");
  const verifyAccess = verifyAccessString
    ? JSON.parse(verifyAccessString)
    : null;
  const [selected, setSelected] = useState<number | null>(null);
  const [subjectData, setSubjectData] = useState<SubjectList[]>([]);
  const [aosData, setAosData] = useState<AosList[]>([]);
  const [typeData, setTypeData] = useState<TypeList[]>([]);
  const [topicData, setTopicData] = useState<TopicList[]>([]);
  const [subTableData, setSubTableData] = useState<Sub[]>([]);
  const [subjectTableData, setSubjectTableData] = useState<Sub[]>([]);
  const [aosTableData, setAosTableData] = useState<Sub[]>([]);
  const [topicTableData, setTopicTableData] = useState<Sub[]>([]);
  const [typeTableData, setTypeTableData] = useState<Sub[]>([]);
  const [fileTableData, setFileTableData] = useState<Sub[]>([]);
  const [filters, setFilters] = useState<GetSub>({});
  const [seleTabData, setSeleTabData] = useState<GetSubb>({});
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [loading, setLoading] = useState(true);
  const [toast, setToast] = React.useState<{
    type: string;
    message: string;
  } | null>(null);

  const fetchSubjectData = async () => {
    try {
      const response = await getSubjectList(verifyAccess.token);
      setSubjectData(response.data.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchAOSData = async () => {
    try {
      const response = await getAOSList(verifyAccess.token);
      setAosData(response.data.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchTopicData = async () => {
    try {
      const response = await getTopicList(verifyAccess.token);
      setTopicData(response.data.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchTypeData = async () => {
    try {
      const response = await getTypeList(verifyAccess.token);
      setTypeData(response.data.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleApplyFilter = () => {
    setSeleTabData({});
    setSubjectTableData([]);
    setAosTableData([]);
    setTopicTableData([]);
    setTypeTableData([]);
    setFileTableData([]);
  };

  const fetchData = async () => {
    try {
      const response = await getSubjectData(
        {
          subject_group: seleTabData.subject_group || undefined,
          area_of_id: seleTabData.area_of_id || undefined,
          topic_id: seleTabData.topic_id || undefined,
          type_id: seleTabData.type_id || undefined,
          search_key: searchQuery !== "" ? searchQuery : undefined,
          subject_id: seleTabData.subject_id || undefined,
          filters: {
            subject_id: filters.subject_id
              ? filters.subject_id.split(",").map((id) => parseInt(id))
              : null,
            topic_id: filters.topic_id
              ? filters.topic_id.split(",").map((id) => parseInt(id))
              : null,
            type_id: filters.type_id
              ? filters.type_id.split(",").map((id) => id)
              : null,
            area_of_id: filters.area_of_id
              ? filters.area_of_id.split(",").map((id) => parseInt(id))
              : null,
          },
        },
        verifyAccess.token,
      );
      if (!response.data.success || response.data.data.length === 0) {
        setSubTableData([]);
        setSubjectTableData([]);
        setAosTableData([]);
        setTopicTableData([]);
        setTypeTableData([]);
        setFileTableData([]);
        setLoading(false);
        setSelected(null);
        setToast({
          type: "error",
          message: "No data found",
        });
      } else {
        if (
          seleTabData.subject_group &&
          seleTabData.subject_id &&
          seleTabData.area_of_id &&
          seleTabData.topic_id &&
          seleTabData.type_id
        ) {
          setFileTableData(response.data.data);
          setLoading(false);
        } else if (
          seleTabData.subject_group &&
          seleTabData.subject_id &&
          seleTabData.area_of_id &&
          seleTabData.topic_id
        ) {
          setTypeTableData(response.data.data);
        } else if (
          seleTabData.subject_group &&
          seleTabData.subject_id &&
          seleTabData.area_of_id
        ) {
          setTopicTableData(response.data.data);
        } else if (seleTabData.subject_group && seleTabData.subject_id) {
          setAosTableData(response.data.data);
        } else if (seleTabData.subject_group) {
          setSubjectTableData(response.data.data);
        } else if (searchQuery) {
          setSubTableData(response.data.data);
        } else {
          setSubTableData(response.data.data);
          setSelected(null);
        }
      }
      setLoading(false);
    } catch (error) {
      console.log("error", error);
      setLoading(false);
      setToast({
        type: "error",
        message: "No data found",
      });
    }
  };

  useEffect(() => {
    fetchData();
  }, [filters, searchQuery, seleTabData]);

  useEffect(() => {
    fetchSubjectData();
    fetchTopicData();
    fetchTypeData();
    fetchAOSData();
  }, []);

  return (
    <>
      {toast && (
        <ToastMessage
          type={toast.type as "success" | "error"}
          message={toast.message}
          onClose={() => setToast(null)}
          style={{
            position: "abosolute",
            top: "90px",
            textTransform: "capitalize",
          }}
        />
      )}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            display: "flex",
          }}
        >
          <Typography
            sx={{
              marginRight: "8px",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              fontSize: "12px",
              fontWeight: "500",
              color: "#333",
            }}
          >
            Filter by
          </Typography>
          <FilteredComponent
            subjectData={subjectData}
            aosData={aosData}
            typeData={typeData}
            topicData={topicData}
            filters={filters}
            setFilters={setFilters}
            handleApplyFilter={handleApplyFilter}
          />
        </Box>

        <SearchComponent
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
        />
      </Box>
      {loading ? (
        <Skeleton
          variant="rounded"
          width={"100%"}
          height={"calc(100vh - 255px)"}
        />
      ) : (
        <CustomFileFinder
          subTableData={subTableData}
          subjectTableData={subjectTableData}
          aosTableData={aosTableData}
          topicTableData={topicTableData}
          typeTableData={typeTableData}
          fileTableData={fileTableData}
          setSeleTabData={setSeleTabData}
          setSelected={setSelected}
          selected={selected}
          seleTabData={seleTabData}
          setAosTableData={setAosTableData}
          setTopicTableData={setTopicTableData}
          setTypeTableData={setTypeTableData}
          setFileTableData={setFileTableData}
        />
      )}
    </>
  );
};
