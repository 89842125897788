import client from "./client";

export const getBugListData = async (token: string) =>
  await client.get("/bug-report/view-bug-report", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const getNotificationData = async (token: string) =>
  await client.get("/topic/fetch-notifications", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const getFileListData = async (
  token: string,
  publish_status?: string,
) => {
  const params = publish_status ? { publish_status } : {};
  return await client.get("/bug-report/view-dashboard-files", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params,
  });
};

export const viewBugDetail = async (token: string, id: number) =>
  await client.get(`/bug-report/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const bugStautusUpdate = async (payload: object, token: string | null) =>
  await client.post("/bug-report/update-bug-status", payload, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const notificationReadUpdate = async (
  payload: object,
  token: string | null,
) =>
  await client.post("/topic/mark-as-read", payload, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
