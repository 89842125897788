import React from "react";
import { Box, Typography, Link } from "@mui/material";
import Icons from "../Icons";

type File = {
  name: string;
  deleted?: boolean;
  type: string;
  size: number;
  lastModified: number;
  edited?: boolean;
};

type BatchType = {
  files: File[];
  isPublished: boolean;
  filesAdd?: File[];
  addedToBatch?: boolean;
  uploadStatus?: string[];
};

interface SuccessUploadProps {
  publishedDateTime: string[];
  batches: BatchType[];
  successUploadMore: () => void;
}

const SuccessUploadScreen: React.FC<SuccessUploadProps> = ({
  publishedDateTime,
  batches,
  successUploadMore,
}) => {
  return (
    <Box
      sx={{
        background: "#F4F6FF",
        height: "390px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      <Icons
        name="checkCircle"
        fill="#007634"
        width={36}
        height={36}
        style={{}}
      />
      <Typography
        sx={{
          fontSize: "22px",
          fontWeight: "600",
          marginBottom: "32px",
          marginTop: "32px",
          width: "255px",
          textAlign: "center",
        }}
      >
        {publishedDateTime
          ? "Files are uploaded & scheduled successfully"
          : "Files are published successfully"}
      </Typography>
      <Box
        sx={{
          background: "#fff",
          padding: "16px",
          borderRadius: "16px",
          width: "390px",
        }}
      >
        <Typography
          sx={{
            fontSize: "12px",
            fontWeight: "500",
          }}
        >
          Upload details
        </Typography>
        {batches.map((batch, index) => (
          <Box
            key={index}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "8px",
            }}
          >
            <Typography
              sx={{
                width: "100px",
                fontSize: "14px",
                fontWeight: "600",
              }}
            >
              Batch {index + 1}
            </Typography>
            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: "400",
                color: "#696969",
              }}
            >
              {batch.files.length}
            </Typography>
            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: "400",
                color: "#696969",
              }}
            >
              {publishedDateTime && publishedDateTime[index]
                ? `${publishedDateTime[index]}`
                : "Publish immediately"}
            </Typography>
          </Box>
        ))}
      </Box>
      <Box
        sx={{
          marginTop: "32px",
        }}
      >
        <Link
          onClick={successUploadMore}
          underline="none"
          sx={{
            paddingX: "20px",
            borderRadius: "8px",
            background: "#fff",
            border: "1px solid #FFA737",
            marginRight: "12px",
            height: "44px",
          }}
          component={"button"}
        >
          <Typography color={"#333"} variant="cbutton">
            Upload more files
          </Typography>
        </Link>
      </Box>
    </Box>
  );
};

export default SuccessUploadScreen;
