import React, { useState } from "react";
import { Box, Grid, Typography } from "@mui/material";
import IconComponent from "../../components/Icons";
import SubjectList from "../ListItem/SubjectList";
import SubjectListt from "../ListItem/SubjectListt";
import SubjectDetailsModal from "./subjectDetailsModal";
import TopicAndTypeList from "../ListItem/TopicTypeList";
import {
  viewEditAos,
  viewEditSubject,
  viewEditTopic,
  viewEditSubjectGroup
} from "../../services/api/subjects";
import Cookies from "js-cookie";
import SubjectFirstGrid from "../ListItem/SubjectFirstGrid";
import FileDetail from "../FileLibrary/fileDetails";

interface EditSub {
  id?: number;
  mst_subject_group_id?: number;
  subject?: string;
  subject_description?: string | null;
  subject_icon?: string | null;
  subject_colour?: string;
  updated_by?: string | null;
  record_status?: string;
  createdAt?: string;
  updatedAt?: string;
  sub_code?: string;
}

interface EditAos {
  id?: number;
  subject_group_id?: number;
  subject_id?: number;
  area_of_study?: string;
  aos_code?: string;
  aos_id?: string;
  area_of_study_icon?: string | null;
  area_of_study_description?: string | null;
}

type GetSub = {
  subject_group_id?: number;
  subject_group?: number;
  subject_id?: number;
  area_of_id?: number;
  topic_id?: number;
  type_id?: number;
  file_type_id?: number;
  search_key?: string;
};

type Sub = {
  subject_id?: number;
  record_status?: string;
  subject_colour?: string;
  subject_icon?: string;
  subject_description?: string;
  subject_group_id?: number;
  subject_group?: string;
  subject?: string;
  area_of_study_id?: number;
  area_of_id?: number;
  area_of_study?: string;
  aos_code?: string;
  topic_id?: number;
  topic?: string;
  topic_description?: string;
  type_id?: number;
  type?: string;
  file_id?: number;
  file_names?: string;
  file_type?: string;
  file_type_id?: number;
  area_of_study_icon?: string;
  area_of_study_description?: string;
};
interface CustomFileFinderProps {
  subTableData: Sub[];
  subjectTableData: Sub[];
  aosTableData: Sub[];
  topicTableData: Sub[];
  typeTableData: Sub[];
  fileTableData: Sub[];
  setSeleTabData: React.Dispatch<React.SetStateAction<GetSub>>;
  setSelected: React.Dispatch<React.SetStateAction<number | null>>;
  selected: number | null;
  setAosTableData: React.Dispatch<React.SetStateAction<Sub[]>>;
  setTopicTableData: React.Dispatch<React.SetStateAction<Sub[]>>;
  setTypeTableData: React.Dispatch<React.SetStateAction<Sub[]>>;
  setFileTableData: React.Dispatch<React.SetStateAction<Sub[]>>;
  seleTabData: GetSub;
}

const CustomFileFinder: React.FC<CustomFileFinderProps> = ({
  subTableData,
  seleTabData,
  subjectTableData,
  aosTableData,
  topicTableData,
  typeTableData,
  fileTableData,
  setSeleTabData,
  setSelected,
  selected,
  setAosTableData,
  setTopicTableData,
  setTypeTableData,
  setFileTableData,
}) => {
  const verifyAccessString = Cookies.get("authAccess");
  const verifyAccess = verifyAccessString
    ? JSON.parse(verifyAccessString)
    : null;
  const [selectedCourse, setSelectedCourse] = useState<Sub | null>(null);
  const [selectedAos, setSelectedAos] = useState<Sub | null>(null);
  const [selectedTopic, setSelectedTopic] = useState<Sub | null>(null);
  const [selectedTypeRec, setSelectedTypeRec] = useState<Sub | null>(null);
  const [selectedLast, setSelectedLast] = useState<Sub | null>(null);
  const [editSubData, setEditSubData] = useState<EditSub>({});
  const [editAosData, setEditAosData] = useState<EditAos>({});
  const [editTopicData, setTopicSubData] = useState<EditSub>({});
  const [editSubjectGroupData, setEditSubjectGroupData] = useState<EditSub>({});

  const [isSubjectDetailsModalOpen, setIsSubjectDetailsModalOpen] =
    useState(false);
  const [isCourseDetailsModalOpen, setIsCoursetDetailsModalOpen] =
    useState(false);
  const [isAosDetailsModalOpen, setIsAosDetailsModalOpen] = useState(false);
  const [isTopicDetailsModalOpen, setIsTopicDetailsModalOpen] = useState(false);

  const handleSubjectGroupClick = () => {
    setIsSubjectDetailsModalOpen(true);
  };
  const handleCoursetGroupClick = () => {
    setIsCoursetDetailsModalOpen(true);
  };
  const handleAosGroupClick = () => {
    setIsAosDetailsModalOpen(true);
  };
  const handleTopicGroupClick = () => {
    setIsTopicDetailsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsSubjectDetailsModalOpen(false);
    setIsCoursetDetailsModalOpen(false);
    setIsAosDetailsModalOpen(false);
    setIsTopicDetailsModalOpen(false);
  };

  const handleItemClick = (index: number) => {
    setSelected(index === selected ? index : index);
    setSelectedCourse(null);
    setSelectedAos(null);
    setSelectedTopic(null);
    setSelectedTypeRec(null);
    setSelectedLast(null);
    setAosTableData([]);
    setTopicTableData([]);
    setTypeTableData([]);
    setFileTableData([]);
  };

  const handleCourseClick = (course: Sub) => {
    setSelectedCourse(
      course.subject_id === selectedCourse?.subject_id ? course : course,
    );
    setSelectedAos(null);
    setSelectedTopic(null);
    setSelectedTypeRec(null);
    setSelectedLast(null);
    setAosTableData([]);
    setTopicTableData([]);
    setTypeTableData([]);
    setFileTableData([]);
  };

  const handleAosClick = (aos: Sub) => {
    setSelectedAos(aos.subject_id === selectedAos?.subject_id ? aos : aos);
    setSelectedTopic(null);
    setSelectedTypeRec(null);
    setSelectedLast(null);
    setTopicTableData([]);
    setTypeTableData([]);
    setFileTableData([]);
  };

  const handleTopicClick = (topic: Sub) => {
    setSelectedTopic(
      topic.subject_id === selectedTopic?.subject_id ? topic : topic,
    );
    setSelectedTypeRec(null);
    setSelectedLast(null);
    setTypeTableData([]);
    setFileTableData([]);
  };

  const handleTypeRecClick = (typeRec: Sub) => {
    setSelectedTypeRec(
      typeRec.subject_id === selectedTypeRec?.subject_id ? typeRec : typeRec,
    );
    setSelectedLast(null);
  };
  const handleTypeLast = (fileShow: Sub) => {
    setSelectedLast(
      fileShow.subject_id === selectedLast?.subject_id ? fileShow : fileShow,
    );
  };

  const handleBreadcrumbClick = (breadcrumbType: string) => {
    switch (breadcrumbType) {
      case "subject":
        setSelectedCourse(null);
        setSelectedAos(null);
        setSelectedTopic(null);
        setSelectedTypeRec(null);
        setSelectedLast(null);
        break;
      case "course":
        setSelectedAos(null);
        setSelectedTopic(null);
        setSelectedTypeRec(null);
        setSelectedLast(null);
        break;
      case "aos":
        setSelectedTopic(null);
        setSelectedTypeRec(null);
        setSelectedLast(null);
        break;
      case "topic":
        setSelectedTypeRec(null);
        setSelectedLast(null);
        break;
      case "typeRec":
        setSelectedLast(null);
        break;
      default:
        break;
    }
  };

  const handeleSetSubject = () => {
    setSelectedCourse(null);
    setAosTableData([]);
    setTopicTableData([]);
    setTypeTableData([]);
    setFileTableData([]);
  };

  const fetchEditSubjectData = async (id: number) => {
    try {
      const response = await viewEditSubject(verifyAccess.token, id);
      setEditSubData(response.data.data.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchEditAosData = async (id: number) => {
    try {
      const response = await viewEditAos(verifyAccess.token, id);
      setEditAosData(response.data.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchEditTopicData = async (id: number) => {
    try {
      const response = await viewEditTopic(verifyAccess.token, id);
      console.log("11", response.data.data);
      setTopicSubData(response.data.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchEditSubjectGroupData = async (id: number) => {
    try {
      const response = await viewEditSubjectGroup(verifyAccess.token, id);
      console.log("22", response.data.data);
      setEditSubjectGroupData(response.data.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const [drawer, setDrawer] = React.useState(false);
  const [selectedRowData, 
    setSelectedRowData
  ] = React.useState<number>();
  const [isEditing, setIsEditing] = React.useState(false);

  const handleFileNameClick = (id:any) => {
    setSelectedRowData(id);
    setDrawer(true);
  };

  const renderBreadcrumbs = (): JSX.Element => {
    const breadcrumbs: JSX.Element[] = [];

    if (selected !== null) {
      const selectedSubject = subTableData.find(
        (subject) => subject.subject_group_id === selected,
      );
      if (selectedSubject) {
        breadcrumbs.push(
          <div
            key={selectedSubject.subject_group_id}
            style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
            onClick={() => handleBreadcrumbClick("subject")}
          >
            <div style={{}}>
              <IconComponent
                name="folderOpen"
                fill="#8E8D8A"
                width={14}
                height={14}
              />
            </div>
            <Typography
              sx={{
                fontSize: "12px",
                fontWeight: "500",
                lineHeight: "15px",
                color: "#333333",
                textTransform: "capitalize",
                marginLeft: "8px",
                marginRight: "14px",
              }}
            >
              {selectedSubject.subject_group}
            </Typography>
            <IconComponent
              name="chevronRight"
              fill="#8E8D8A"
              width={6}
              height={12}
            />
          </div>,
        );
      }
      if (selectedCourse !== null) {
        breadcrumbs.push(
          <div
            key={selectedCourse.subject_id}
            style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
            onClick={() => handleBreadcrumbClick("course")}
          >
            <div style={{}}>
              <IconComponent
                name="folderOpen"
                fill="#8E8D8A"
                width={14}
                height={14}
              />
            </div>
            <Typography
              sx={{
                fontSize: "12px",
                fontWeight: "500",
                lineHeight: "15px",
                color: "#333333",
                textTransform: "capitalize",
                marginLeft: "8px",
                marginRight: "14px",
              }}
            >
              {selectedCourse.subject}
            </Typography>
            <IconComponent
              name="chevronRight"
              fill="#8E8D8A"
              width={6}
              height={12}
            />
          </div>,
        );
      }
      if (selectedAos !== null) {
        breadcrumbs.push(
          <div
            key={selectedAos.subject_id}
            style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
            onClick={() => handleBreadcrumbClick("aos")}
          >
            <div style={{}}>
              <IconComponent
                name="folderOpen"
                fill="#8E8D8A"
                width={14}
                height={14}
              />
            </div>
            <Typography
              sx={{
                fontSize: "12px",
                fontWeight: "500",
                lineHeight: "15px",
                color: "#333333",
                textTransform: "capitalize",
                marginLeft: "8px",
                marginRight: "14px",
              }}
            >
              {selectedAos.aos_code} - {selectedAos.area_of_study}
            </Typography>
            <IconComponent
              name="chevronRight"
              fill="#8E8D8A"
              width={6}
              height={12}
            />
          </div>,
        );
      }
      if (selectedTopic !== null) {
        breadcrumbs.push(
          <div
            key={selectedTopic.subject_id}
            style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
            onClick={() => handleBreadcrumbClick("topic")}
          >
            <div style={{}}>
              <IconComponent
                name="folderOpen"
                fill="#8E8D8A"
                width={14}
                height={14}
              />
            </div>
            <Typography
              sx={{
                fontSize: "12px",
                fontWeight: "500",
                lineHeight: "15px",
                color: "#333333",
                textTransform: "capitalize",
                marginLeft: "8px",
                marginRight: "14px",
              }}
            >
              {selectedTopic.topic}
            </Typography>
            <IconComponent
              name="chevronRight"
              fill="#8E8D8A"
              width={6}
              height={12}
            />
          </div>,
        );
      }
      if (selectedTypeRec !== null) {
        breadcrumbs.push(
          <div
            key={selectedTypeRec.subject_id}
            style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
            onClick={() => handleBreadcrumbClick("typeRec")}
          >
            <div style={{}}>
              <IconComponent
                name="folderOpen"
                fill="#8E8D8A"
                width={14}
                height={14}
              />
            </div>
            <Typography
              sx={{
                fontSize: "12px",
                fontWeight: "500",
                lineHeight: "15px",
                color: "#333333",
                textTransform: "capitalize",
                marginLeft: "8px",
                marginRight: "14px",
              }}
            >
              {selectedTypeRec.file_type}
            </Typography>
            <IconComponent
              name="chevronRight"
              fill="#8E8D8A"
              width={6}
              height={12}
            />
          </div>,
        );
      }
      if (selectedLast !== null) {
        breadcrumbs.push(
          <div
            key={selectedLast.subject_id}
            style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
          >
            <div style={{}}>
              <IconComponent
                name="folderOpen"
                fill="#8E8D8A"
                width={14}
                height={14}
              />
            </div>
            <Typography
              sx={{
                fontSize: "12px",
                fontWeight: "500",
                lineHeight: "15px",
                color: "#333333",
                textTransform: "capitalize",
                marginLeft: "8px",
                marginRight: "14px",
              }}
            >
              {selectedLast.file_names}
            </Typography>
          </div>,
        );
      }
    }

    return (
      <>
        <div style={{ display: "flex", alignItems: "center", gap: "14px" }}>
          {breadcrumbs.map((breadcrumb, index) => (
            <div
              key={index}
              onClick={() =>
                handleBreadcrumbClick(breadcrumb.props["data-type"])
              }
            >
              {breadcrumb}
            </div>
          ))}
        </div>
      </>
    );
  };

  return (
    <div>
      <Grid
        container
        sx={{
          display: "flex",
          height: "calc(100vh - 222px)",
          overflowY: "scroll",
          borderRadius: "8px",
          width: "100%",
          border: "1px solid #D2D2D0",
          marginBottom: "24px",
        }}
      >
        <Grid item md={2} sx={{ borderRight: "1px solid #D2D2D0" }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              padding: "16px",
              background: "#F4F6FF",
              borderTopLeftRadius: "8px",
              borderBottom: "1px solid #D2D2D0",
            }}
          >
            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: "600",
                lineHeight: "18.2px",
                color: "#333333",
                textTransform: "math-auto",
              }}
              variant="title2"
            >
              Subject group
            </Typography>
            <button
              style={{
                alignItems: "center",
                display: "flex",
                padding: 0,
                border: 0,
                cursor: "pointer",
                marginLeft: "auto",
              }}
              onClick={handleSubjectGroupClick}
            >
              <Box
                sx={{
                  border: "1px solid #F4F6FF",
                  marginLeft: "auto",
                  background: "#F4F6FF",
                  height: "26px",
                  "&:hover": {
                    border: "1px solid #D2D2D0",
                    background: "#FFF8EC",
                    borderRadius: "4px",
                    zIndex: 100,
                  },
                }}
              >
                <IconComponent name="add" fill={"#8E8D8A"} />
              </Box>
            </button>
          </div>
          {subTableData.map((subject) => (
            <Box
              key={subject.subject_group_id}
              sx={{
                background:
                  selected === subject.subject_group_id ? "#FFF8EC" : "inherit",
                "&:hover": {
                  background: "#FFF8EC",
                },
              }}
            >  
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
                onClick={() => {
                  setSeleTabData(() => {
                    return {
                      subject_group: subject.subject_group_id,
                    };
                  });
                  handleItemClick(subject.subject_group_id || 0);
                }}
              >
          
                <SubjectFirstGrid
                  key={subject.subject_group_id}
                  boxSx={{
                    background:
                      subject.record_status === "Closed"
                        ? "#EFEFEF"
                        : selectedTopic !== null &&
                          selectedTopic.subject_group_id === subject.subject_group_id
                          ? "#FFF8EC"
                          : "inherit",
                    "&:hover": {
                      background:
                        subject.record_status === "Closed"
                          ? "#EFEFEF"
                          : "#FFF8EC",
                    },
                    border: 0,
                    borderBottom: "1px solid #D2D2D0",
                  }}
                  clicked={
                    selectedTopic !== null &&
                    selectedTopic.subject_group_id === subject.subject_group_id
                  }
                  fetchEditSubjectData={fetchEditSubjectGroupData}//here
                  subId={subject.subject_group_id}
                  editSubData={editSubjectGroupData} //here
                  closed={subject.record_status === "Closed" ? true : false}
                  handeleSetSubject={handeleSetSubject}
                  setSeleTabData={setSeleTabData}
                >
                  <Box
                    key={subject.subject_group_id}
                    sx={{
                      "&:hover": {
                        background:
                          subject.record_status === "Closed"
                            ? "#EFEFEF"
                            : "#FFF8EC",
                      },
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "12px",
                          fontWeight: "500",
                          lineHeight: "18.2px",
                          color: "#333333",
                          textTransform: "capitalize",
                          textAlign: "start",
                          marginX: "8px",
                        }}
                        variant="title2"
                      >
                        {subject.subject_group}
                      </Typography>
                      <div
                        style={{
                          display: "flex",
                          marginLeft: "auto",
                          alignItems: "center",
                        }}
                      ></div>
                    </div>
                  </Box>
                </SubjectFirstGrid>
              </div>
            </Box>
          ))}
        </Grid>

        {/* Grid 1 - Courses */}
        {selected !== null && (
          <Grid
            item
            md={2}
            sx={{ borderRight: selected ? "1px solid #D2D2D0" : null }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                padding: "16px",
                background: "#F4F6FF",
                borderBottom: "1px solid #D2D2D0",
              }}
            >
              <Typography
                sx={{
                  fontSize: "14px",
                  fontWeight: "600",
                  lineHeight: "18.2px",
                  color: "#333333",
                  textTransform: "math-auto",
                }}
                variant="title2"
              >
                Subject
              </Typography>
              <button
                style={{
                  alignItems: "center",
                  display: "flex",
                  padding: 0,
                  border: 0,
                  cursor: "pointer",
                  marginLeft: "auto",
                }}
                onClick={handleCoursetGroupClick}
              >
                <Box
                  sx={{
                    border: "1px solid #F4F6FF",
                    marginLeft: "auto",
                    background: "#F4F6FF",
                    height: "26px",
                    "&:hover": {
                      border: "1px solid #D2D2D0",
                      background: "#FFF8EC",
                      borderRadius: "4px",
                      zIndex: 100,
                    },
                  }}
                >
                  <IconComponent name="add" fill={"#8E8D8A"} />
                </Box>
              </button>
            </div>
            <div>
              {subjectTableData.map((subject) => {
                if (selected === subject.subject_group_id) {
                  return (
                    <div
                      key={subject.subject_id}
                      onClick={() => {
                        setSeleTabData((prevFilters) => {
                          return {
                            subject_group: prevFilters.subject_group,
                            subject_id: subject.subject_id,
                          };
                        });
                        handleCourseClick(subject);
                        console.log("clicked")
                      }}
                    >
                      <SubjectList
                        key={subject.subject_id}
                        boxSx={{
                          background:
                            subject.record_status === "Closed"
                              ? "#EFEFEF"
                              : selectedCourse !== null &&
                                selectedCourse.subject_id ===
                                subject.subject_id
                                ? "#FFF8EC"
                                : "inherit",
                          "&:hover": {
                            background:
                              subject.record_status === "Closed"
                                ? "#EFEFEF"
                                : "#FFF8EC",
                          },
                          border: 0,
                          borderBottom: "1px solid #D2D2D0",
                        }}
                        clicked={
                          selectedCourse !== null &&
                          selectedCourse.subject_id === subject.subject_id
                        }
                        setSeleTabData={setSeleTabData}
                        DescriptionIcon={
                          subject.subject_description === null ||
                          subject.subject_description === ""
                        }
                        iconOpacity={
                          subject.subject_icon === null ||
                          subject.subject_icon === ""
                        }
                        closed={
                          subject.record_status === "Closed" ? true : false
                        }
                        fetchEditSubjectData={fetchEditSubjectData}
                        subId={subject.subject_id}
                        subName={subject.subject}
                        editSubData={editSubData}
                        handeleSetSubject={handeleSetSubject}
                      >
                        <Box key={subject.subject_id}>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "8px",
                            }}
                          >
                            <span
                              style={{
                                backgroundColor: subject.subject_colour,
                                width: "6px",
                                height: "6px",
                                borderRadius: "50%",
                              }}
                            ></span>
                            <Box
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                gap: "8px",
                                alignItems: "flex-start",
                                justifyContent: "flex-start",
                              }}
                            >
                              <Typography
                                sx={{
                                  fontSize: "12px",
                                  fontWeight: "500",
                                  lineHeight: "18.2px",
                                  color: "#333333",
                                  textTransform: "capitalize",
                                  maxWidth: "102.5px",
                                  textAlign: "left",
                                }}
                                variant="title2"
                              >
                                {subject.subject}
                              </Typography>
                              {subject.record_status === "Closed" && (
                                <Box
                                  sx={{
                                    paddingX: "8px",
                                    paddingY: "4px",
                                    borderRadius: "4px",
                                    bgcolor: "#3B4478",
                                  }}
                                >
                                  <Typography
                                    color={"white"}
                                    variant="detailBold"
                                  >
                                    Closed
                                  </Typography>
                                </Box>
                              )}
                            </Box>
                          </div>
                        </Box>
                      </SubjectList>
                    </div>
                  );
                }
                return null;
              })}
            </div>
          </Grid>
        )}

        {/* Grid 2 - Aos */}
        {selected !== null &&
          selectedCourse !== null && (
            <Grid
              item
              md={2}
              sx={{ borderRight: selected ? "1px solid #D2D2D0" : null }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  padding: "16px",
                  background: "#F4F6FF",
                  borderBottom: "1px solid #D2D2D0",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: "600",
                    lineHeight: "18.2px",
                    color: "#333333",
                    textTransform: "math-auto",
                  }}
                  variant="title2"
                >
                  Area of study
                </Typography>
                <button
                  style={{
                    alignItems: "center",
                    display: "flex",
                    padding: 0,
                    border: 0,
                    cursor: "pointer",
                    marginLeft: "auto",
                  }}
                  onClick={handleAosGroupClick}
                >
                  <Box
                    sx={{
                      border: "1px solid #F4F6FF",
                      marginLeft: "auto",
                      background: "#F4F6FF",
                      height: "26px",
                      "&:hover": {
                        border: "1px solid #D2D2D0",
                        background: "#FFF8EC",
                        borderRadius: "4px",
                        zIndex: 100,
                      },
                    }}
                  >
                    <IconComponent name="add" fill={"#8E8D8A"} />
                  </Box>
                </button>
              </div>
              <div>
                {aosTableData.map((aos) => (
                  <div
                    key={aos.subject_id}
                    onClick={() => {
                      setSeleTabData((prevFilters) => {
                        return {
                          subject_group: prevFilters.subject_group,
                          subject_id: prevFilters.subject_id,
                          area_of_id: aos.area_of_study_id,
                        };
                      });
                      handleAosClick(aos);
                    }}
                  >
                    <SubjectListt
                      key={aos.subject_id}
                      boxSx={{
                        background:
                          aos.record_status === "Closed"
                            ? "#EFEFEF"
                            : selectedAos !== null &&
                              selectedAos.area_of_study_id ===
                              aos.area_of_study_id
                              ? "#FFF8EC"
                              : "inherit",
                        "&:hover": {
                          background:
                            aos.record_status === "Closed"
                              ? "#EFEFEF"
                              : "#FFF8EC",
                        },
                        border: 0,
                        borderBottom: "1px solid #D2D2D0",
                      }}
                      clicked={
                        selectedAos !== null &&
                        selectedAos.area_of_study_id === aos.area_of_study_id
                      }
                      setSeleTabData={setSeleTabData}
                      fetchEditSubjectData={fetchEditAosData}
                      subId={aos.area_of_study_id}
                      editSubData={editAosData}
                      handeleSetSubject={handeleSetSubject}
                      iconOpacity={
                        aos.area_of_study_icon === null ||
                        aos.area_of_study_icon === ""
                      }
                      DescriptionIcon={
                        aos.area_of_study_description === null ||
                        aos.area_of_study_description === ""
                      }
                      closed={aos.record_status === "Closed" ? true : false}
                    >
                      <Box
                        key={aos.subject_id}
                        sx={{
                          "&:hover": {
                            background:
                              aos.record_status === "Closed"
                                ? "#EFEFEF"
                                : "#FFF8EC",
                          },
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "8px",
                            alignItems: "flex-start",
                            justifyContent: "flex-start",
                          }}
                        >
                          <Typography
                            sx={{
                              fontSize: "12px",
                              fontWeight: "500",
                              lineHeight: "15px",
                              color: "#333333",
                              textTransform: "capitalize",
                              textAlign: "start",
                            }}
                          >
                            {aos.aos_code} {aos.area_of_study ? `- ${aos.area_of_study}` : ""}
                          </Typography>
                         
                        </div>
                      </Box>
                    </SubjectListt>
                  </div>
                ))}
              </div>
            </Grid>
          )}

        {/* Grid 3 - Topic */}
        {selected !== null &&
          selectedCourse !== null &&
          selectedAos !== null && (
            <Grid
              item
              md={2}
              sx={{ borderRight: selected ? "1px solid #D2D2D0" : null }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  padding: "16px",
                  background: "#F4F6FF",
                  borderBottom: "1px solid #D2D2D0",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: "600",
                    lineHeight: "18.2px",
                    color: "#333333",
                    textTransform: "math-auto",
                  }}
                  variant="title2"
                >
                  Topic
                </Typography>
                <button
                  style={{
                    alignItems: "center",
                    display: "flex",
                    padding: 0,
                    border: 0,
                    cursor: "pointer",
                    marginLeft: "auto",
                  }}
                  onClick={handleTopicGroupClick}
                >
                  <Box
                    sx={{
                      border: "1px solid #F4F6FF",
                      marginLeft: "auto",
                      background: "#F4F6FF",
                      height: "26px",
                      "&:hover": {
                        border: "1px solid #D2D2D0",
                        background: "#FFF8EC",
                        borderRadius: "4px",
                        zIndex: 100,
                      },
                    }}
                  >
                    <IconComponent name="add" fill={"#8E8D8A"} />
                  </Box>
                </button>
              </div>
              <div>
                {topicTableData.map((topic) => (
                  <div
                    key={topic.subject_id}
                    onClick={() => {
                      setSeleTabData((prevFilters) => {
                        return {
                          subject_group: prevFilters.subject_group,
                          subject_id: prevFilters.subject_id,
                          area_of_id: prevFilters.area_of_id,
                          topic_id: topic.topic_id,
                        };
                      });
                      handleTopicClick(topic);
                    }}
                  >
                    <TopicAndTypeList
                      key={topic.subject_id}
                      boxSx={{
                        background:
                          topic.record_status === "Closed"
                            ? "#EFEFEF"
                            : selectedTopic !== null &&
                              selectedTopic.topic_id === topic.topic_id
                              ? "#FFF8EC"
                              : "inherit",
                        "&:hover": {
                          background:
                            topic.record_status === "Closed"
                              ? "#EFEFEF"
                              : "#FFF8EC",
                        },
                        border: 0,
                        borderBottom: "1px solid #D2D2D0",
                      }}
                      clicked={
                        selectedTopic !== null &&
                        selectedTopic.topic_id === topic.topic_id
                      }
                      fetchEditSubjectData={fetchEditTopicData}
                      subId={topic.topic_id}
                      editSubData={editTopicData}
                      handeleSetSubject={handeleSetSubject}
                      DescriptionIcon={
                        topic.topic_description === null ||
                        topic.topic_description === ""
                      }
                          setSeleTabData={setSeleTabData}
                      closed={topic.record_status === "Closed" ? true : false}
                    >
                      <Box
                        key={topic.subject_id}
                        sx={{
                          "&:hover": {
                            background:
                              topic.record_status === "Closed"
                                ? "#EFEFEF"
                                : "#FFF8EC",
                          },
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <Typography
                            sx={{
                              fontSize: "12px",
                              fontWeight: "500",
                              lineHeight: "18.2px",
                              color: "#333333",
                              textTransform: "capitalize",
                              textAlign: "start",
                              marginX: "8px",
                            }}
                            variant="title2"
                          >
                            {topic.topic}
                          </Typography>
                          <div
                            style={{
                              display: "flex",
                              marginLeft: "auto",
                              alignItems: "center",
                            }}
                          ></div>
                        </div>
                      </Box>
                    </TopicAndTypeList>
                  </div>
                ))}
              </div>
            </Grid>
          )}

        {/* Grid 4 - Type */}
        {selected !== null &&
          selectedCourse !== null &&
          selectedAos !== null &&
          selectedTopic !== null &&
          typeTableData.length !== 0 && (
            <Grid
              item
              md={2}
              sx={{ borderRight: selected ? "1px solid #D2D2D0" : null }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  padding: "20px 16px",
                  background: "#F4F6FF",
                  borderBottom: "1px solid #D2D2D0",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: "600",
                    lineHeight: "18.2px",
                    color: "#333333",
                    textTransform: "math-auto",
                  }}
                  variant="title2"
                >
                  Type
                </Typography>
               
              </div>
              <div>
                {typeTableData.map((typeRec) => (
                  <div
                    key={typeRec.subject_id}
                    onClick={() => {
                      setSeleTabData((prevFilters) => {
                        return {
                          subject_group: prevFilters.subject_group,
                          subject_id: prevFilters.subject_id,
                          area_of_id: prevFilters.area_of_id,
                          topic_id: prevFilters.topic_id,
                          type_id: typeRec.file_type_id,
                        };
                      });
                      handleTypeRecClick(typeRec);
                    }}
                  >
                    <TopicAndTypeList
                      key={typeRec.subject_id}
                      boxSx={{
                        background:
                          typeRec.record_status === "Closed"
                            ? "#EFEFEF"
                            : selectedTypeRec !== null &&
                              selectedTypeRec.file_type_id ===
                              typeRec.file_type_id
                              ? "#FFF8EC"
                              : "inherit",
                        "&:hover": {
                          background:
                            typeRec.record_status === "Closed"
                              ? "#EFEFEF"
                              : "#FFF8EC",
                        },
                        border: 0,
                        borderBottom: "1px solid #D2D2D0",
                      }}
                      clicked={
                        selectedTypeRec !== null &&
                        selectedTypeRec.file_type_id === typeRec.file_type_id
                      }
                      noIcon={true}
                      closed={typeRec.record_status === "Closed" ? true : false}
                      DescriptionIcon={
                        typeRec.topic_description === null ||
                        typeRec.topic_description === ""
                      }
                      type={true}
                    >
                      <Box
                        key={typeRec.subject_id}
                        sx={{
                          "&:hover": {
                            background:
                              typeRec.record_status === "Closed"
                                ? "#EFEFEF"
                                : "#FFF8EC",
                          },
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <Typography
                            sx={{
                              fontSize: "12px",
                              fontWeight: "500",
                              lineHeight: "18.2px",
                              color: "#333333",
                              textTransform: "capitalize",
                              textAlign: "start",
                              marginX: "8px",
                            }}
                            variant="title2"
                          >
                            {typeRec.file_type}
                          </Typography>
                        </div>
                      </Box>
                    </TopicAndTypeList>
                  </div>
                ))}
              </div>
            </Grid>
          )}

        {/* Grid 5 - File */}
        {selected !== null &&
          selectedCourse !== null &&
          selectedAos !== null &&
          selectedTopic !== null &&
          selectedTypeRec !== null &&
          fileTableData.length !== 0 && (
            <Grid item md={2}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  padding: "16px",
                  paddingTop: "20px",
                  paddingBottom: "20px",
                  background: "#F4F6FF",
                  borderBottom: "1px solid #D2D2D0",
                  borderTopRightRadius: "16px",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: "600",
                    lineHeight: "18.2px",
                    color: "#333333",
                    textTransform: "math-auto",
                  }}
                  variant="title2"
                >
                  File
                </Typography>
                <button
                  style={{
                    alignItems: "center",
                    display: "flex",
                    padding: 0,
                    border: 0,
                    cursor: "pointer",
                    marginLeft: "auto",
                  }}
                >
                </button>
              </div>
              <div>
                {fileTableData.map((file) => (
                  <Box
                    key={file.subject_id}
                    onClick={() => handleTypeLast(file)}
                    sx={{
                      background:
                        file.record_status === "Closed"
                          ? "#EFEFEF"
                          : selectedLast !== null &&
                            selectedLast.file_id === file.file_id
                            ? "#FFF8EC"
                            : "inherit",
                      "&:hover": {
                        background:
                          file.record_status === "Closed"
                            ? "#EFEFEF"
                            : "#FFF8EC",
                      },
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        padding: "12px",
                        paddingRight: "4px",
                        borderBottom: "1px solid #D2D2D0",
                        cursor: "pointer",
                      }}
                    >
                      <div style={{}}>
                        {file.file_type === "Recording" && (
                          <IconComponent
                            name="recording"
                            fill="#3B4478"
                            width={16}
                            height={16}
                          />
                        )}
                      </div>
                      <Typography
                        sx={{
                          fontSize: "12px",
                          fontWeight: "500",
                          lineHeight: "18.2px",
                          color: "#333333",
                          maxWidth: "162.5px",
                          marginX: "4px",
                        }}
                        variant="title2"
                        onClick={() => handleFileNameClick(file.file_id)}
                      >
                        {file.file_names}
                      </Typography>
                      <div
                        style={{
                          textAlign: "center",
                          paddingLeft: "12px",
                          paddingRight: "12px",
                          marginLeft: "auto",
                        }}
                      >
                        <IconComponent name="chevronRight" fill="#8E8D8A" />
                      </div>
                    </div>
                  </Box>
                ))}
              </div>
            </Grid>
          )}
      </Grid>
      <FileDetail
        drawer={drawer}
        setDrawer={setDrawer}
        selectedRowData={selectedRowData}
        isEditing={isEditing}
        setIsEditing={setIsEditing}
      />
      {/* subject Modal */}
      <SubjectDetailsModal
        open={isSubjectDetailsModalOpen}
        handleCloseModal={handleCloseModal}
        titleName={"Create subject group"}
        name={"Subject group name"}
        description={"Subject group description"}
        iconName={"Subject group icon"}
        type={"group"}
        setSeleTabData={setSeleTabData}
        subjectCode={"Create Subject Code"} />
      {/* Course Modal */}
      <SubjectDetailsModal
        open={isCourseDetailsModalOpen}
        handleCloseModal={handleCloseModal}
        titleName={"Create subject"}
        name={"Subject name"}
        description={"Subject description"}
        iconName={"Subject icon"}
        setSeleTabData={setSeleTabData}
        seleTabData={seleTabData}
        subjectCode={"Create Subject Code"}
        isCourseDetailsModalOpen={true}
      />
      {/* AOS Modal */}
      <SubjectDetailsModal
        open={isAosDetailsModalOpen}
        handleCloseModal={handleCloseModal}
        titleName={"Create area of study"}
        name={"Area of study name"}
        description={"Area of study description"}
        iconName={"Area of study icon"}
        type={"aos"}
        setSeleTabData={setSeleTabData}
        seleTabData={seleTabData}
        subjectCode={"Create Subject Code"}
        AosCodee={'Area of study code'}
        isAosModalOpen={true}
      />
      {/* Topic Modal */}
      <SubjectDetailsModal
        open={isTopicDetailsModalOpen}
        handleCloseModal={handleCloseModal}
        titleName={"Create topic"}
        name={"Topic name"}
        description={"Topic description"}
        iconName={"Topic icon"}
        type={"topic"}
        setSeleTabData={setSeleTabData}
        seleTabData={seleTabData}
        subjectCode={"Create Subject Code"}
      />
      {renderBreadcrumbs()}
    </div>
  );
};

export default CustomFileFinder;