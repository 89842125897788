import { Box, CircularProgress, Typography } from "@mui/material";
import React from "react";

interface buttonProps {
  text: string;
  textStyle: object;
  icon?: React.ReactNode | null;
  handleClick?: () => void;
  onClick?: () => void;
  loading?: boolean;
}
const btnStyle = {
  borderRadius: "8px",
  paddingX: "16px",
  bgcolor: "#FFA737",
  height: "38px",
  display: "flex",
  flexDirection: "row",
  gap: "8px",
  alignItems: "center",
  justifyContent: "center",
  cursor: "pointer",
};

const txtStyle = {
  fontSize: "14px",
  fontWeight: 600,
  fontFamily: "Inter",
};

const Button = ({
  text = "Button",
  icon = null,
  textStyle = {},
  handleClick,
  onClick,
  loading = false,
}: buttonProps) => {
  
  return (
    <Box
      sx={{
        ...btnStyle,
        opacity: loading ? 0.5 : 1,
        pointerEvents: loading ? "none" : "auto",
      }}
      onClick={handleClick || onClick}>
      <>
        {icon}
        <Typography sx={{ ...txtStyle, ...textStyle, display: "flex", alignItems: "center" }}>
          {text}
          {
            loading ? (
              <CircularProgress sx={{ marginLeft: "8px" }} size={14} color="inherit" />
            ) : ''
          }
        </Typography>
      </>
    </Box >
  );
};

export default Button;
