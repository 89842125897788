import { Box, Typography } from "@mui/material";
import React from "react";

interface TransparentBdrBtnProps {
  text: string;
  textStyle: object;
  icon?: React.ReactNode | null;
  handleClick?: () => void;
}
const btnStyle = {
  borderRadius: "8px",
  border: "1px solid #FFA737",
  paddingX: "12px",
  bgcolor: "#FFFFFF",
  height: "38px",
  display: "flex",
  flexDirection: "row",
  cursor: "pointer",
  gap: "8px",
  alignItems: "center",
  justifyContent: "center",
};

const txtStyle = {
  fontSize: "14px",
  fontWeight: 600,
  fontFamily: "Inter",
};

const TransparentBdrBtn = ({
  text = "Button",
  icon = null,
  textStyle = {},
  handleClick,
}: TransparentBdrBtnProps) => {
  
  return (
    <Box sx={btnStyle} onClick={handleClick}>
      {icon}
      <Typography sx={{ ...txtStyle, ...textStyle }}>{text}</Typography>
    </Box>
  );
};

export default TransparentBdrBtn;
