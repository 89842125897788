import { Box, Typography } from "@mui/material";
import React from "react";
import { styled } from "@mui/material/styles";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import Cookies from "js-cookie";
import CustomizedSwitchComponent from "../Switch";
import { updateSubAccess } from "../../services/api/student";
import ToastMessage from "../ToastMessage";
import moment from "moment";

interface Subject {
  id: number;
  subject_id: any;
  sub_code: string;
  time_spent: string;
  progress: string;
  access_start_date: string;
  access_end_date: string;
  access_status: boolean;
  updated_by: string;
  subject_name: string;
  record_status: string;
  Last_access?:string;
}

interface StudentCardsProps {
  subjects: Subject[];
  stuId: number;
}

const BorderLinearProgress = styled(LinearProgress)(() => ({
  height: 6,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: "#EFEFEF",
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: "#FFA737",
  },
}));

const StudentCards: React.FC<StudentCardsProps> = ({ subjects, stuId }) => {
  const verifyAccessString = Cookies.get("authAccess");
  const verifyAccess = verifyAccessString
    ? JSON.parse(verifyAccessString)
    : null;
  const [toast, setToast] = React.useState<{
    type: string;
    message: string;
  } | null>(null);
  const [switchStates, setSwitchStates] = React.useState<boolean[]>([]);

  React.useEffect(() => {
    if (Array.isArray(subjects)) {
      const initialSwitchStates = subjects.map(
        (subject) => subject.access_status,
      );
      setSwitchStates(initialSwitchStates);
    }
  }, [subjects]);

  const handleOnOff =
    (subjectId: number, studentId: number, index: number) => async () => {
      setSwitchStates((prevSwitchStates) => {
        const newSwitchStates = [...prevSwitchStates];
        newSwitchStates[index] = !newSwitchStates[index];
        return newSwitchStates;
      });
      try {
        const token = verifyAccess.token;
        const updatedSwitchState = !switchStates[index];
        const payload = {
          subjectId,
          studentId,
          newAccessStatus: updatedSwitchState,
        };
        console.log("payload", payload);
        const response = await updateSubAccess(payload, token);
        if (response && response.data) {
          setSwitchStates((prevSwitchStates) => {
            const newSwitchStates = [...prevSwitchStates];
            newSwitchStates[index] = updatedSwitchState;
            return newSwitchStates;
          });
          const successMessage = response.data.message;
          setToast({
            type: "success",
            message: successMessage,
          });
        } else {
          const errorMessage =
            response && response.data.message
              ? response.data.message
              : "An error occured";
          setToast &&
            setToast({
              type: "error",
              message: errorMessage,
            });
        }
        console.log("updateStuSubAccess", response);
      } catch (error) {
        console.error("Error fetching student details:", error);
      }
    };

  return (
    <Box>
      {toast && (
        <ToastMessage
          type={toast.type as "success" | "error"}
          message={toast.message}
          onClose={() => setToast(null)}
          style={{
            top: "85px",
          }}
        />
      )}
      <Box
        sx={{
          paddingX: "28px",
          display: "flex",
          flexDirection: "column",
          gap: "16px",
        }}
      >
        <Typography
          sx={{
            fontSize: "16px",
            fontWeight: "600",
            color: "#333333",
            lineHeight: "24px",
            paddingTop: "16px",
          }}
        >
          Subjects
        </Typography>

        {subjects &&
          subjects.map((subject, index) => {
            return (
              <Box
                key={index}
                sx={{
                  padding: "12px",
                  border: "1px solid #D2D2D0",
                  borderRadius: "10px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    paddingBottom: "8px",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "14px",
                      fontWeight: "600",
                      color: "#333333",
                      textTransform: "capitalize",
                    }}
                  >
                    {subject.sub_code} {subject.subject_name}
                  </Typography>
                </Box>
                <BorderLinearProgress
                  variant="determinate"
                  value={parseInt(subject.progress) || 0}
                />
                <Typography
                  color="#696969"
                  variant="detail"
                  sx={{ display: "block", paddingTop: "4px" }}
                >
                  {subject.progress}%
                </Typography>
                <Typography color="#696969" variant="detail">
                  Last access:{moment(subject.access_end_date).format("dddd (hh:MM A)")}
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    paddingY: "20px",
                    alignItems: "center",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      width: "140px",
                    }}
                  >
                    <Typography color="#696969" variant="detail">
                      Time spent
                    </Typography>
                    <Typography
                      color="#333333"
                      variant="detail"
                      sx={{ fontSize: "14px" }}
                    >
                      {subject.time_spent}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      background: "#D2D2D0",
                      height: "31px",
                      width: "1px",
                      marginRight: "20px",
                    }}
                  />
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <Typography color="#696969" variant="detail">
                      Last accessed
                    </Typography>
                    <Typography
                      color="#333333"
                      variant="detail"
                      sx={{ fontSize: "14px" }}
                    >
                     {subject.Last_access ? moment(subject.Last_access).format("MMM DD, YYYY hh:mm A") : " - "}
                    </Typography>
                  </Box>
                </Box>
                <Box>
                  <CustomizedSwitchComponent
                    onToggle={handleOnOff(
                      subject.subject_id,
                      stuId,
                      index,
                    )}
                    defaultChecked={subject.access_status}
                    key={subject.id.toString()}
                  />
                  <Typography
                    color={"#333333"}
                    fontWeight={"500"}
                    variant="detail"
                  >
                    {switchStates[index] ? "Access enabled" : "Access disabled"}
                  </Typography>
                </Box>
              </Box>
            );
          })}
      </Box>
    </Box>
  );
};

export default StudentCards;
