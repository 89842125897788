import { createSlice, PayloadAction } from "@reduxjs/toolkit";


interface BugLibrary {
  shouldRefetch:number|null,
}

const initialState: BugLibrary = {
    shouldRefetch:null,
};
export const bugLibrarySlice = createSlice({
  name: "error",
  initialState,
  reducers: {
    setRefetchContent: (state, action: PayloadAction<BugLibrary>) => {
      const { shouldRefetch } = action.payload;
      state.shouldRefetch = shouldRefetch;
    }
  },
});


export const { setRefetchContent } = bugLibrarySlice.actions;
export const bugLibraryReducer = bugLibrarySlice.reducer;
