import React, { useState, useRef, ChangeEvent } from "react";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import IconComponent from "../Icons";

interface SearchSubjectProps {
  onSearchChange: (value: string) => void; 
}

export const SearchSubjectComponent: React.FC<SearchSubjectProps> = ({
  onSearchChange,
}) => {
  const [searchQuery, setSearchQuery] = useState("");

  const inputRef = useRef<HTMLInputElement | null>(null);

  const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSearchQuery(value);
    onSearchChange(value); 
  };
  const handleClearSearch = () => {
    setSearchQuery("");
    inputRef.current && inputRef.current.focus();
  };

  return (
    <TextField
      inputRef={inputRef}
      type="text"
      placeholder="Search for a subject"
      variant="outlined"
      sx={{
        width: "100%",
        ".Mui-focused": {
          background: "#FFF8EC",
          ".css-9sors8-MuiButtonBase-root-MuiIconButton-root:hover": {
            background: "transparent",
          },
          ".css-1yq5fb3-MuiButtonBase-root-MuiIconButton-root:hover": {
            background: "transparent",
          },
          ".css-14sxf79-MuiInputBase-input-MuiOutlinedInput-input": {
            color: "#333",
            fontSize: "14px",
            fontWeight: "400",
          },
        },
        ".MuiInputBase-root": {
          maxHeight: "44px",
          borderRadius: "8px",
          "&:focus-visible": {
            outline: "none !important",
          },
        },
        ".css-9sors8-MuiButtonBase-root-MuiIconButton-root": {
          padding: "0px",
          marginLeft: "-7px",
          pointerEvents: "none",
        },
      }}
      value={searchQuery}
      onChange={handleSearchChange}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <IconButton aria-label="search icon" edge="start">
              <IconComponent name="search" fill={"#8E8D8A"} />
            </IconButton>
          </InputAdornment>
        ),
        endAdornment: searchQuery && (
          <InputAdornment position="end">
            <IconButton
              aria-label="clear search"
              onClick={handleClearSearch}
              edge="end"
            >
              <IconComponent name="close" fill={"#8E8D8A"} />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};
