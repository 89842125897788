import React, { useState, useEffect, ChangeEvent } from "react";
import {
  Box,
  TextField,
  Typography,
  InputAdornment,
  IconButton,
} from "@mui/material";
import Loginlogo from "../../assets/images/ContourLogo.png";
import Button from "../../components/Button";
import Loginlayout from "../../layouts/LoginLayout";
import IconComponent from "../../components/Icons";
import BorderText from "../../components/Label/BorderText";
import { useNavigate } from "react-router-dom";
import { resetPassword } from "../../services/api/login";
import ToastMessage from "../../components/ToastMessage";
import { setError } from "../../store/error.slice";
import { useDispatch } from "react-redux";

interface FormData {
  newpwd: string;
  confirmpwd: string;
}

const validatePassword = (password: string): boolean => {
  const specialChars =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*(),.?":{}|<>]).{8,}$/;
  return specialChars.test(password);
};

const ResetPassword = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const [formData, setFormData] = useState<FormData>({
    newpwd: "",
    confirmpwd: "",
  });
  const [showPassword, setShowPassword] = useState(false);
  const [showConPassword, setShowConPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isPasswordValid, setIsPasswordValid] = useState(true);
  const [passwordsMatch, setPasswordsMatch] = useState(true);
  const [token, setToken] = useState<string>("");
  const [toast, setToast] = React.useState<{
    type: string;
    message: string;
  } | null>(null);

  const handleTogglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  useEffect(() => {
    const getTokenFromURL = () => {
      const searchParams = new URLSearchParams(window.location.search);
      const tokenVal = searchParams.get("token");
      if (tokenVal !== null) {
        setToken(tokenVal);
      }
    };

    getTokenFromURL();
  }, []);

  const handleConTogglePasswordVisibility = () => {
    setShowConPassword((prevShowPassword) => !prevShowPassword);
  };

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { id, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [id]: value,
    }));
    if (id === "newpwd") {
      setIsPasswordValid(validatePassword(value));

      if (formData.confirmpwd.trim() !== "") {
        setPasswordsMatch(value === formData.confirmpwd);
      }
    }

    if (id === "confirmpwd") {
      setPasswordsMatch(value === formData.newpwd);
    }
  };

  const handleResetPassword = async () => {
    if (
      formData.newpwd !== "" &&
      formData.confirmpwd !== "" &&
      isPasswordValid &&
      passwordsMatch
    ) {
      setLoading(true);
      try {
        const response = await resetPassword(
          { newpwd: formData.newpwd, confirmpwd: formData.newpwd },
          token,
        );
        if (response && response.data) {
          setLoading(false);
          setFormData({ newpwd: "", confirmpwd: "" });
          const successMessage = response.data.message;
          setToast({
            type: "success",
            message: successMessage,
          });
          handleBack();
        } else {

          dispatch(
            setError({
              message: response?.data?.message || "Something went wrong",
              type: "error",
              time: new Date().toLocaleString(),
            })
          );
          setLoading(false);
        }
      } catch (error: any) {
        const errorMessage =
          error.response?.data?.message || "Something went wrong";
        console.error("Forgot api", error);
        setToast({
          type: "error",
          message: errorMessage,
        });
        setLoading(false);
      }
    }
  };

  const handleBack = () => {
    navigate("/");
  };

  const isResetButtonDisabled =
    !formData.newpwd ||
    !formData.confirmpwd ||
    !passwordsMatch ||
    !isPasswordValid;

  const handleKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      if (
        formData.newpwd.trim() !== "" &&
        formData.confirmpwd.trim() !== "" &&
        passwordsMatch
      ) {
        handleResetPassword();
      }
    }
  };

  return (
    <Loginlayout>
      {toast && (
        <ToastMessage
          type={toast.type as "success" | "error"}
          message={toast.message}
          onClose={() => setToast(null)}
          style={{
            position: "absolute",
            top: "5%",
          }}
        />
      )}
      <Box
        sx={{ marginBottom: "60px", width: "90%" }}
        component="img"
        src={Loginlogo}
        alt="brand-logo"
      />
      <div
        style={{
          maxWidth: "54px",
          cursor: "pointer",
          marginLeft: "-6px",
          marginBottom: "20px",
        }}
        onClick={handleBack}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <IconComponent name="chevronLeft" fill={"#8E8D8A"} />
          <BorderText text={"Login"} />
        </Box>
      </div>
      <div>
        <Typography color={"contour.title3"} variant="title2">
          Reset Password
        </Typography>
        <Box
          width={"100%"}
          sx={{ paddingY: "40px" }}
          display={"flex"}
          flexDirection={"column"}
        >
          <Typography
            color={"#333333"}
            variant="detailBold"
            sx={{ marginBottom: "4px" }}
          >
            New Password
          </Typography>
          <TextField
            id="newpwd"
            type={showPassword ? "text" : "password"}
            variant="outlined"
            value={formData.newpwd}
            onChange={handleInputChange}
            onKeyDown={handleKeyPress}
            helperText={
              !isPasswordValid ? (
                <Typography
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "6px",
                    fontSize: "10px",
                    '&.MuiTypography-root': {
                      color: "#B21200",
                    }
                  }}
                >
                  <IconComponent name="warningRed" fill={"#8E8D8A"} />
                  Password: 8+ chars, 1 uppercase, 1 lowercase, 1 digit, 1 special.
                </Typography>
              ) : (
                ""
              )
            }
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={handleTogglePasswordVisibility}
                    edge="end"
                  >
                    {showPassword ? (
                      <IconComponent name="show" fill={"#8E8D8A"} />
                    ) : (
                      <IconComponent name="hide" fill={"#8E8D8A"} />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            sx={{
              marginBottom: "8px",
              ".MuiOutlinedInput-notchedOutline": {
                borderRadius: "8px",
                border: "1px solid #D2D2D0",
              },
              "&.MuiFormControl-root .MuiFormHelperText-root": {
                marginLeft: "0px",
              },
            }}
          />
          <Typography
            color={"#333333"}
            variant="detailBold"
            sx={{ marginBottom: "4px" }}
          >
            Confirm Password
          </Typography>
          <TextField
            id="confirmpwd"
            type={showConPassword ? "text" : "password"}
            variant="outlined"
            value={formData.confirmpwd}
            onChange={handleInputChange}
            error={!isPasswordValid}
            onKeyDown={handleKeyPress}
            helperText={
              !passwordsMatch ? (
                <span
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "6px",
                    fontSize: "10px",
                    color: "#B21200",
                  }}
                >
                  <IconComponent name="warningRed" fill={"#8E8D8A"} />
                  {`Password doesn't match`}
                </span>
              ) : (
                ""
              )
            }
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={handleConTogglePasswordVisibility}
                    edge="end"
                  >
                    {showConPassword ? (
                      <IconComponent name="show" fill={"#8E8D8A"} />
                    ) : (
                      <IconComponent name="hide" fill={"#8E8D8A"} />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            sx={{
              marginBottom: "8px",
              ".MuiOutlinedInput-notchedOutline": {
                borderRadius: "8px",
                border: "1px solid #D2D2D0",
              },
              "&.MuiFormControl-root .MuiFormHelperText-root": {
                marginLeft: "0px",
              },
            }}
          />
        </Box>

        <Box
          sx={{
            maxWidth: "168px",
          }}
        >
            <Button
            loading={loading}
              text="Reset"
              textStyle={{
                color: "#333",
                opacity: isResetButtonDisabled ? "0.5" : "1",
                pointerEvents: isResetButtonDisabled ? "none" : "auto",
              }}
              handleClick={handleResetPassword}
            />
        </Box>
      </div>
    </Loginlayout>
  );
};

export default ResetPassword;
