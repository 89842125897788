import React from "react";

import Tooltip from "@mui/material/Tooltip";
import Box from "@mui/material/Box";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import { getEventDetails } from "../../services/api/fileLibrary";
import Cookies from "js-cookie";

interface tipProps {
  eventId: React.ReactNode | string;
  children: React.ReactElement;
}

const TextTooltipDynamic = ({ eventId, children }: tipProps) => {
  const [open, setOpen] = React.useState(false);
  const [title, setTitle] = React.useState("")
  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = (eventId: any) => {
    const verifyAccessString = Cookies.get("authAccess");
    const verifyAccess = verifyAccessString
      ? JSON.parse(verifyAccessString)
      : null;
    const token = verifyAccess.token;
    getEventDetails(token, eventId).then((res) => {
      console.log(res, "events details", res.data)
      setOpen((prevOpen) => !prevOpen);
      const data: any = {}
      res.data.data.map((file: any) => {
        data[file.event_name] = file.event_status
      })
      setTitle(JSON.stringify(data));
    })
  };


  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <Tooltip
        arrow
        title={<Box>
          {title}
        </Box>}
        placement="left"
        PopperProps={{
          disablePortal: true,
        }}
        onClose={handleTooltipClose}
        open={open}
        disableFocusListener
        disableHoverListener
        disableTouchListener
        componentsProps={{
          tooltip: {
            sx: {
              backgroundColor: "#3B4478",
              color: "#FFF",
              fontFamily: "Inter",
              fontSize: " 12px",
              fontStyle: "normal",
              fontWeight: 400,
              lineHeight: "15px",
              borderRadius: "4px",
              maxWidth: "unset !important",
            },
          },
          arrow: {
            sx: {
              "&:before": {
                bgcolor: "#3B4478",
              },
            },
          },
        }}
      >
        <Box
          onClick={() => handleTooltipOpen(eventId)}
          component={"div"}
          sx={{ cursor: "pointer" }}
        >
          {children}
        </Box>
      </Tooltip>
    </ClickAwayListener>
  );
};

export default TextTooltipDynamic;
