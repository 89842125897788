import axios from "axios";
import client from "./client";

type SubPayload = {
  subject_id: number;
  access_status: boolean;
};

interface addStuPayload {
  email: string;
  first_name: string;
  last_name: string;
  phone: string;
  campus_id: number;
  enrolment_status: string;
  subjects: SubPayload[];
}

type GetStudent = {
  campus_name?: string;
  subject_name?: string;
  enrolment_status?: string;
  visit_by?: string;
  global_search_keyword?: string;
};

type StudentDetail = {
  studentId: number;
  subjects?: {
    subject_id: number;
    access_status: boolean;
  }[];
};

export const addStudentApi = async (payload: addStuPayload, token: string) =>
  await client.post("/students/add-student", payload, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const refreshStudentData = async (token: string) =>
  await axios.put(
    `https://contour-crons.contoureducation.com.au/api/create-student-from-monday`,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

export const getStudentSubject = async (
  params: GetStudent,
  token: string,
  page: number,
  limit: number,
  student: any,
) =>
  await client.get(
    `/students/get-students?visit_by=${student}&page=${page}&limit=${limit}`,
    {
      params: params,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

export const getCampusList = async (token: string) =>
  await client.get("/employees/view-campus", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const getSubjectList = async (token: string) =>
  await client.get("/subjects/view-subjects", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const getStudentDetail = async (payload: StudentDetail, token: string) =>
  await client.post("/students/student-details", payload, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const updateStuSubAccess = async (
  payload: object,
  token: string | null
) =>
  await client.post("/students/access-statusBystudentId", payload, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
export const disableStuSubAccess = async (
  payload: object,
  token: string | null
) =>
  await client.post("/students/subjects-access-disable", payload, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const updateSubAccess = async (payload: object, token: string | null) =>
  await client.post("/students/access-statusBysubjectId", payload, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const enrollmentStautusUpdate = async (
  payload: object,
  token: string | null
) =>
  await client.post("/students/update-enrolment-status", payload, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
