import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Button } from "@mui/material";

interface CalendarComponentProps {
  selectedStartDate: Date | null;
  selectedEndDate: Date | null;
  handleDateChange: (dates: [Date | null, Date | null]) => void;
  handleReset: () => void;
  key: number;
  handleClose4: () => void;
  fromDateFocused: boolean;
  setFromDateFocused: React.Dispatch<React.SetStateAction<boolean>>;
  toDateFocused: boolean;
  setToDateFocused: React.Dispatch<React.SetStateAction<boolean>>;
}

const CalendarComponent: React.FC<CalendarComponentProps> = ({
  selectedStartDate,
  selectedEndDate,
  handleDateChange,
  handleReset,
  key,
  handleClose4,
  fromDateFocused,
  setFromDateFocused,
  toDateFocused,
  setToDateFocused,
}) => {

  return (
    <div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginBottom: "12px",
        }}
      >
        <div>
          <label
            style={{
              paddingBottom: "4px",
              fontSize: "12px",
              fontWeight: "500",
              color: "#333",
            }}
          >
            From date
          </label>
          <input
            style={{
              width: "192px",
              height: "44px",
              padding: "12px 16px",
              fontSize: "14px",
              fontWeight: "400",
              color: "#333",
              borderRadius: "8px",
              border: `1px solid ${selectedStartDate ? "#FFA737" : (fromDateFocused ? "#FFA737" : "#D2D2D0")}`,
              backgroundColor: selectedStartDate ? "#FFF8EC" : (fromDateFocused ? "#FFF8EC": "transparent"),
            }}
            type="text"
            readOnly
            onFocus={() => setFromDateFocused(true)}
            onBlur={() => setFromDateFocused(false)}
            value={
              selectedStartDate
                ? selectedStartDate.toLocaleDateString("en-GB", {
                    year: "2-digit",
                    month: "2-digit",
                    day: "2-digit",
                  })
                : ""
            }
            placeholder="--/--/--"
          />
        </div>
        <div>
          <label
            style={{
              paddingBottom: "4px",
              fontSize: "12px",
              fontWeight: "500",
              color: "#333",
            }}
          >
            To date
          </label>
          <input
            style={{
              width: "192px",
              height: "44px",
              padding: "12px 16px",
              fontSize: "14px",
              fontWeight: "400",
              color: "#333",
              borderRadius: "8px",
              border: `1px solid ${toDateFocused ? "#FFA737" : "#D2D2D0"}`,
              backgroundColor:selectedStartDate ? "#FFF8EC" : (toDateFocused ? "#FFF8EC" : "transparent"),
            }}
            type="text"
            readOnly
            onFocus={() => setToDateFocused(true)}
            onBlur={() => setToDateFocused(false)}
            value={
              selectedEndDate
                ? selectedEndDate.toLocaleDateString("en-GB", {
                    year: "2-digit",
                    month: "2-digit",
                    day: "2-digit",
                  })
                : ""
            }
            placeholder="--/--/--"
          />
        </div>
      </div>
      <div className="filterDate">
        <DatePicker
          key={key}
          open
          selectsRange
          inline
          showMonthDropdown
          showYearDropdown
          scrollableYearDropdown
          startDate={selectedStartDate || null}
          endDate={selectedEndDate || null}
          onChange={(dates) =>
            handleDateChange(dates as [Date | null, Date | null])
          }
          dateFormat="dd/MM/yy"
        />
      </div>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Button
          variant="outlined"
          onClick={handleClose4}
          style={{
            border: "none",
            padding: "0px",
            minWidth: "auto",
            height: "auto",
            textTransform: "capitalize",
            borderBottom: "2px solid #FFA737",
            borderRadius: "0px",
          }}
        >
          Ok
        </Button>
        <Button
          variant="outlined"
          onClick={handleReset}
          disabled={!selectedStartDate}
          style={{
            border: "none",
            padding: "0px",
            minWidth: "auto",
            height: "auto",
            textTransform: "capitalize",
            borderBottom: "2px solid #FFA737",
            borderRadius: "0px",
          }}
        >
          Reset
        </Button>
      </div>
    </div>
  );
};

export default CalendarComponent;
