import React, { useState, useEffect } from "react";
import { Box, TextField, Typography} from "@mui/material";
import Loginlogo from "../../assets/images/ContourLogo.png";
import BorderText from "../../components/Label/BorderText";
import Button from "../../components/Button";
import IconComponent from "../../components/Icons";
import Loginlayout from "../../layouts/LoginLayout";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { verifyOtpThunk } from "../../store/AuthSlice";
import { AppDispatch } from "../../store/index";
import Cookies from "js-cookie";
import ToastMessage from "../../components/ToastMessage";
import { resendOtp } from "../../services/api/login";

export const Verification = () => {
  const loginToken = Cookies.get("loginToken");
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();
  const verifyAccessString = Cookies.get("authAccess");
  const verifyAccess = verifyAccessString
    ? JSON.parse(verifyAccessString)
    : null;
  const [loading, setLoading] = useState(false);
  const [otpValue, setOtpValue] = useState("");
  const [emailData, setEmailData] = useState({ email: "" });
  const [toast, setToast] = React.useState<{
    type: string;
    message: string;
  } | null>(null);

  useEffect(() => {
    if (!loginToken) {
      navigate("/");
    } else if (verifyAccess) {
      navigate("/dashboard");
    }
  }, [loginToken, verifyAccess]);

  const handleVerify = async () => {
    if (otpValue.length === 6) {
      setLoading(true);
      try {
        const action = await dispatch(
          verifyOtpThunk({ otp: otpValue, token: loginToken ?? "" }),
        );
        if (verifyOtpThunk.fulfilled.match(action)) {
          navigate("/dashboard");
          sessionStorage.removeItem("userData");
          setLoading(false);
        } else {
          setOtpValue("");
          setLoading(false);
          setToast({
            type: "error",
            message: "Invalid Otp",
          });
          console.error("Authentication failed");
        }
      } catch (error) {
        console.error("Authentication failed", error);
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    const savedUserData = sessionStorage.getItem("userData");
    if (savedUserData) {
      setEmailData(JSON.parse(savedUserData));
    }
  }, []);

  const handleResendOtp = async () => {
    try {
      const payload = {
        email: emailData.email,
      };
      const response = await resendOtp(payload, loginToken ?? "");
      if (response && response.data) {
        const successMessage = response.data.message;
        setToast({
          type: "success",
          message: successMessage,
        });
      } else {
        const errorMessage =
          response && response.data.message
            ? response.data.message
            : "An error occured";
        setToast({
          type: "error",
          message: errorMessage,
        });
      }
    } catch (error) {
      console.error("Forgot api", error);
    }
  };

  const handleBack = () => {
    Cookies.remove("loginToken");
    navigate("/");
  };

  const handleOtpChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputOtp = event.target.value;
    const mainOtp = inputOtp.replace(/\D/g, "").slice(0, 6);
    setOtpValue(mainOtp);
  };

  const handleKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      if (otpValue.length === 6) {
        handleVerify();
      }
    }
  };

  return (
    <Loginlayout>
      {toast && (
        <ToastMessage
          type={toast.type as "success" | "error"}
          message={toast.message}
          onClose={() => setToast(null)}
          style={{
            position: "absolute",
            top: "5%",
          }}
        />
      )}
      <Box
        sx={{ marginBottom: "60px", width: "90%" }}
        component="img"
        src={Loginlogo}
        alt="brand-logo"
      />
      <div>
        <div
          style={{ maxWidth: "54px", cursor: "pointer" }}
          onClick={handleBack}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <IconComponent name="chevronLeft" fill={"#8E8D8A"} />
            <BorderText text={"Back"} />
          </Box>
        </div>
        <Box
          width={"100%"}
          sx={{ paddingY: "40px", maxWidth: "350px" }}
          display={"flex"}
          flexDirection={"column"}
        >
          <Typography
            sx={{ maxWidth: "300px", marginBottom: "8px" }}
            color={"contour.title3"}
            variant="title2"
          >
            Enter a verification code
          </Typography>
          <Typography
            sx={{ marginBottom: "40px" }}
            color={"#333333"}
            variant="h6"
            fontSize={16}
          >
            The code was just sent to your email
          </Typography>
          <Typography
            sx={{ marginBottom: "4px" }}
            color={"#333333"}
            variant="detailBold"
          >
            Verification code
          </Typography>
          <TextField
            id="outlined-basic"
            variant="outlined"
            type="text"
            value={otpValue}
            onChange={handleOtpChange}
            onKeyDown={handleKeyPress}
            inputProps={{
              pattern: "[0-9]*",
              inputMode: "numeric",
              maxLength: 6,
              autoComplete: "off",
            }}
            sx={{
              marginBottom: "8px",
              width: "350px",
              ".MuiOutlinedInput-notchedOutline": {
                borderRadius: "8px",
                border: "1px solid #D2D2D0",
              },
              ".css-19z3763-MuiInputBase-input-MuiOutlinedInput-input": {
                height: "11px",
              },
            }}
          />
        </Box>
        <Box sx={{ maxWidth: "168px" }}>
            <Button
            loading={loading}
              text="Verify"
              textStyle={{
                color: "#333",
                cursor: otpValue.length !== 6 ? "not-allowed" : "pointer",
                opacity: otpValue.length !== 6 ? "0.5" : "1",
                pointerEvents: otpValue.length !== 6 ? "none" : "auto",
              }}
              handleClick={otpValue.length === 6 ? handleVerify : undefined}
            />
        </Box>
        <div
          style={{ marginTop: "20px", maxWidth: "108px", cursor: "pointer" }}
        >
          <Box sx={{ display: "flex", alignItems: "center", gap: "4px" }}>
            <IconComponent name="undoDark" fill={"#8E8D8A"} />
            <BorderText text={"Resend code"} onClick={handleResendOtp} />
          </Box>
        </div>
      </div>
    </Loginlayout>
  );
};
