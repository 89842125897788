import React from "react";
import { Box, Typography } from "@mui/material";
import Icons from "../Icons";
import UploadImg from "../../assets/images/upload.png";

interface UploadFileProps {
  invalidType: string|null;
  isDragging: boolean;
  showLoader: boolean;
  addFileTag?: boolean;
  uploadBatchNew?: boolean;
  setCreateNewBatch?: React.Dispatch<React.SetStateAction<boolean>>;
  handleDragEnter: (e: React.DragEvent<HTMLDivElement>) => void;
  handleDragLeave: () => void;
  handleDrop: (e: React.DragEvent<HTMLDivElement>) => Promise<void>;
  handleFileClick: () => void;
  handleFileChange: (e: React.ChangeEvent<HTMLInputElement>) => Promise<void>;
}

const UploadFileBatch2: React.FC<UploadFileProps> = ({
  invalidType,
  isDragging,
  showLoader,
  handleDragEnter,
  handleDragLeave,
  handleDrop,
  handleFileClick,
  handleFileChange,
  addFileTag,
  uploadBatchNew,
  setCreateNewBatch,
}) => {
  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          marginTop: addFileTag ? "16px" : "",
          alignItems: "center",
          justifyContent: "center",
          border: "2px dashed #D2D2D0",
          borderRadius: "8px",
          padding: "22px 0px",
          cursor: "pointer",
          position: "relative",
          backgroundColor: isDragging || showLoader ? "#007AFF" : "#fff",
          "&:hover": {
            backgroundColor: isDragging || showLoader ? "#007AFF" : "#ffa7371f",
          },
        }}
        onDragEnter={handleDragEnter}
        onDragLeave={handleDragLeave}
        onDragOver={(e)=>e.preventDefault()}
        onDrop={handleDrop}
        onClick={handleFileClick}
      >
        {uploadBatchNew && (
          <Box
            sx={{
              lineHeight: 0,
              padding: "5px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "22px",
              height: "22px",
              borderRadius: "50%",
              border: "0.7px solid black",
              background: "#fff",
              cursor: "pointer",
              position: "absolute",
              right: "-2px",
              top: "-11px",
            }}
            onClick={(event) => {
              event.stopPropagation();
              setCreateNewBatch && setCreateNewBatch(false);
            }}
          >
            <Icons width={16} height={16} name="close" fill="#8E8D8A" />
          </Box>
        )}
        <input
          type="file"
          id="fileInput2"
          style={{ display: "none" }}
          onChange={handleFileChange}
        />
        <>
          <img style={{ marginBottom: "5px" }} src={UploadImg} alt="" />
          <Typography
            sx={{
              fontSize: "16px",
              fontWeight: "600",
              color: "#333",
            }}
          >
            {uploadBatchNew
              ? "Upload a new batch"
              : !addFileTag
                ? "Upload a file"
                : "Add more files"}
          </Typography>

          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: "400",
              color: "#696969",
            }}
          >
            Click to browse, or drag & drop a file here
          </Typography>
        </>
      </Box>
      {invalidType!==null && (
        <Typography
          sx={{
            fontSize: "16px",
            fontWeight: "500",
            textAlign: "center",
            color: "red",
            marginTop: "2px",
          }}
        >
          {invalidType}
        </Typography>
      )}
    </>
  );
};

export default UploadFileBatch2;
