import { Box, Link, Typography } from "@mui/material";
import React from "react";

interface dat {
  label: string;
  count: number;
}

interface tabProps {
  tabData?: dat[];
  selected?: number;
  setSelectedTab: (index: number) => void;
}

const Tab = ({ tabData, selected, setSelectedTab }: tabProps) => {

  return (
    <Box display={"flex"} flexDirection={"row"} gap={"4px"}>
      {tabData &&
        tabData.map((item, id) => {
          return (
            <Link
              key={id}
              underline="none"
              component="button"
              data-id={id}
              onClick={() => setSelectedTab(id)}
              sx={{
                padding: "12px",
                borderTopRightRadius: "16px",
                borderTopLeftRadius: "16px",
                border: "1px solid #D2D2D0",
                borderColor: selected == id ? "#FFA737" : "#D2D2D0",
                bgcolor: selected == id ? "#FFF8EC" : "#FFFFFF",
                borderBottomWidth: selected == id ? "4px" : "1px",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                minWidth: "150px",
              }}
            >
              <Typography
                color={selected == id ? "contour.title3" : "contour.inactive"}
                variant="title3"
                component={"p"}
              >
                {item?.count}
              </Typography>
              <Typography
                color={selected == id ? "contour.title3" : "contour.inactive"}
                variant="bodySmBold"
                component={"p"}
              >
                {item?.label}
              </Typography>
            </Link>
          );
        })}
    </Box>
  );
};

export default Tab;
