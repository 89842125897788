import React, { ReactNode } from "react";
import { Box, Grid } from "@mui/material";
import LoginBanner from "../assets/images/LoginBanner.png";
import BottomDots from "../assets/images/BottomDots.png";
import TopDots from "../assets/images/TopDots.png";
import LoginSmily from "../assets/images/LoginSmily.png";

interface ForgottenPasswordProps {
  children: ReactNode;
}

const Loginlayout: React.FC<ForgottenPasswordProps> = ({ children }) => {
  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
      overflow={"hidden"}
    >
      <Grid sx={{ paddingX: "100px" }} item xs={12} md={5}>
        {children}
      </Grid>
      <Grid
        item
        xs={12}
        md={7}
        sx={{
          bgcolor: "#007AFF",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100Vh",
          position: "relative",
        }}
      >
        <Box
          sx={{
            maxWidth: "560px",
            position: "relative",
            "&::before": {
              content: "''",
              position: "absolute",
              bottom: "17px",
              left: "-15px",
              width: "100%",
              maxWidth: "424px",
              height: "216px",
              background: `url(${LoginSmily}) no-repeat`,
              zIndex: "100",
            },
          }}
        >
          <Box
            sx={{
              position: "relative",
              zIndex: "10",
              width: "100%",
              height: "cal(100vh - 285px)",
            }}
            component="img"
            src={LoginBanner}
            alt="LoginBanner"
          />
        </Box>
        <Box
          sx={{ position: "absolute", top: "-6px", left: "-10px" }}
          component="img"
          src={TopDots}
          alt="LoginSmily"
        />
        <Box
          sx={{ position: "absolute", bottom: "26px", right: "67px" }}
          component="img"
          src={BottomDots}
          alt="LoginSmily"
        />
      </Grid>
    </Grid>
  );
};

export default Loginlayout;
