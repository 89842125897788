import { AxiosProgressEvent } from "axios";

export const calculateUploadStats = (progressEvent: AxiosProgressEvent,totalChunks:number) => {
  const { loaded, total, estimated,rate } = progressEvent;
  console.log("ProgressEvent",ProgressEvent)
  if (loaded && total && estimated) {
    const percentCompleted = Math.round((loaded / total)*80);
    const uploadSpeed = rate||0; // Bytes per second
    const timeRemaining = estimated*totalChunks; // Seconds
    const bytes = (loaded/(1024*1024)); // bytes
    const totalBytes = total/(1024*1024); // bytes
    return { percentCompleted, uploadSpeed:Math.round(uploadSpeed).toFixed(2), timeRemaining, bytes,totalBytes };
  }else{
    return { percentCompleted:80 , uploadSpeed:0, timeRemaining:0,bytes:0,totalBytes:0 };

  }
};
export const calculateUploadStats1 = (progressEvent: AxiosProgressEvent) => {
  const { loaded, total, estimated,rate } = progressEvent;
  console.log("ProgressEvent",ProgressEvent)
  if (loaded && total && estimated) {
    const percentCompleted = Math.round((loaded / total)*80);
    const uploadSpeed = rate||0; // Bytes per second
    const timeRemaining = estimated; // Seconds
    const bytes = (loaded/(1024*1024)); // bytes
    const totalBytes = total/(1024*1024); // bytes
    return { percentCompleted, uploadSpeed:Math.round(uploadSpeed).toFixed(2), timeRemaining, bytes,totalBytes };
  }else{
    return { percentCompleted:80 , uploadSpeed:0, timeRemaining:0,bytes:0,totalBytes:0 };

  }
};
export const getTimeFormat=(timeRemaining:number)=>{
    const minutes = Math.ceil(timeRemaining / 60);
    return `${minutes} ${minutes > 1 ? 'minutes' : 'minute'} left`;
}
export const convertToTranscript=(data:Array<any>)=> {
  return data.map(item => `${item.seek_time} ${item.chapter}`).join('\n');
}
export const checkIfFileNameValid=(fileName:string)=> {
  const validFilenamePattern = /^[\w\s]+ \[\d+\.\d+\] - [\w\s&]+ - .+\.(pdf|mp4|avi|quicktime|mpeg|mkv|mov)$/i;
  return validFilenamePattern.test(fileName);
}