import { styled } from "@mui/material/styles";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch, { SwitchProps } from "@mui/material/Switch";

interface CustomizedSwitchProps {
  label?: string;
  onToggle: () => void;
  defaultChecked: boolean;
  key: string;
}

const YellowSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 34,
  height: 19,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 0,
    color: "#D2D2D0",
    zIndex: "-1",
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#FFA737",
      "& + .MuiSwitch-track": {
        backgroundColor:
          theme.palette.mode === "dark" ? "#2ECA45" : "transparent",
        opacity: 1,
        border: "1.5px solid #FFA737",
      },
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 18,
    height: 18,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: "transparent",
    opacity: 1,
    border: "1.5px solid #D2D2D0",
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

const CustomizedSwitchComponent: React.FC<CustomizedSwitchProps> = ({
  label,
  onToggle,
  defaultChecked,
  key,
}: CustomizedSwitchProps) => {
  return (
    <>
      <FormControlLabel
        control={
          <YellowSwitch
            sx={{ m: 1, marginLeft: "12px" }}
            defaultChecked={defaultChecked}
            onClick={onToggle}
          />
        }
        label={label}
        key={key}
        sx={{ marginRight: 0 }}
      />
    </>
  );
};

export default CustomizedSwitchComponent;
