import { Box, Typography } from "@mui/material";
import React, { Dispatch, SetStateAction, useState } from "react";
import RightDrawer from "../Drawer";
import HoverText from "../Label/HoverText";
import Icons from "../Icons";
import BugDetail from "./BugDetail";
import StatusDropdown from "../Dropdown/StatusDropdown";
import ToastMessage from "../ToastMessage";
import { getBugListData } from "../../services/api/dashboard";
import Cookies from "js-cookie";
import FileDetail from "../FileLibrary/fileDetails";
import { BugDetailState } from ".";
import { useSelector } from "react-redux";
import { RootState } from "../../store";

type DataBug = {
  id: number;
  bug_title: string;
  bug_description: string;
  attachment: string;
  file_id: number;
  file_name: string;
  record_status: string;
  area_of_study_id: number;
  aos_name: string;
  reported_in: string;
  createdAt: string;
  updatedAt: string;
};

interface buglistProps {
  drawer: boolean;
  setDrawer: Dispatch<SetStateAction<boolean>>;
  bug?: string;
  setBug?: React.Dispatch<React.SetStateAction<string>>;
  setBugDetail: React.Dispatch<React.SetStateAction<BugDetailState>>;
  bugDetail: {
    id: number;
    bug_title: string;
    bug_description: string;
    attachment: string;
    file_id: number;
    file_name: string;
    file_type_id: number;
    file_type_name: string;
    area_of_study_id: number;
    area_of_study_name: string;
    subject_id: number;
    subject_name: string;
    topic_id: number;
    topic_name: string;
    sub_grp_id: number;
    sub_grp_name: string;
    sub_grp_code: string;
    student_id: number;
    reported_by: string;
    record_status: string;
    createdAt: string;
    updatedAt: string;
  };
  handleBugClick?: (id: number) => void;
}

const BugList = ({
  drawer,
  setDrawer,
  bug,
  setBug,
  bugDetail,
  setBugDetail,
  handleBugClick,
}: buglistProps) => {
  const {shouldRefetch} =useSelector((state:RootState)=>state.bugLibrary)
  const verifyAccessString = Cookies.get("authAccess");
  const verifyAccess = verifyAccessString
    ? JSON.parse(verifyAccessString)
    : null;
  const [toast, setToast] = React.useState<{
    type: string;
    message: string;
  } | null>(null);
  const [bugData, setBugData] = useState<DataBug[]>([]);
  const [bugStatus, setBugStatus] = useState("");
  const [openViewFile, setOpenViewFile] = useState(false);
  const [selectedRowData, setSelectedRowData] = React.useState<number>();
  const handleBug = (val: string) => {
    console.log(val);
  };
  React.useEffect(()=>{
    setBugStatus(bugDetail?.record_status||"")
  },[bugDetail])

  React.useEffect(() => {
    fetchBugData();
  }, []);
  React.useEffect(() => {
    shouldRefetch!==null &&
    fetchBugData();
  }, [shouldRefetch]);

  const fetchBugData = async () => {
    try {
      const response = await getBugListData(verifyAccess.token);
      if (!response.data.success || response.data.data.length === 0) {
        setBugData([]);
      } else {
        setBugData(response.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const sortedBugData = bugData?.sort((a, b) => {
    return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
  });

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    const now = new Date();

    if (
      date.getDate() === now.getDate() &&
      date.getMonth() === now.getMonth() &&
      date.getFullYear() === now.getFullYear()
    ) {
      const options: Intl.DateTimeFormatOptions = {
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      };
      return `Today, ${date.toLocaleString("en-US", options)}`;
    }

    const yesterday = new Date(now);
    yesterday.setDate(now.getDate() - 1);
    if (
      date.getDate() === yesterday.getDate() &&
      date.getMonth() === yesterday.getMonth() &&
      date.getFullYear() === yesterday.getFullYear()
    ) {
      const options: Intl.DateTimeFormatOptions = {
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      };
      return `Yesterday, ${date.toLocaleString("en-US", options)}`;
    }

    const options: Intl.DateTimeFormatOptions = {
      day: "numeric",
      month: "long",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };
    return date.toLocaleString("en-US", options);
  };

  const handleViewFile = () => {
    setSelectedRowData(bugDetail.file_id);
    setDrawer(false);
    setBug && setBug("");
    setOpenViewFile(true);
  };

  return (
    <>
      <RightDrawer
        open={drawer}
        handleClose={() => {
          setDrawer(false);
          setBugStatus("")
          setBugDetail({
            id: 0,
            bug_title: "",
            bug_description: "",
            attachment: "",
            file_id: 0,
            file_name: "",
            file_type_id: 0,
            file_type_name: "",
            area_of_study_id: 0,
            area_of_study_name: "",
            subject_id: 0,
            subject_name: "",
            topic_id: 0,
            topic_name: "",
            sub_grp_id: 0,
            sub_grp_name: "",
            sub_grp_code: "",
            student_id: 0,
            reported_by: "",
            record_status: "",
            createdAt: "",
            updatedAt: "",
          })
          setBug && setBug("");
        }}
        title="Bug Reports"
        btnText="View File"
        handleButton={() => handleViewFile()}
        extra={bug}
      >
        {bug ? (
          <BugDetail
            bugDetData={bugDetail}
            bugStatus={bugStatus}
            setBugStatus={setBugStatus}
            onBack={() => {
              setBug && setBug("");
              fetchBugData();
            }}
          />
        ) : (
          sortedBugData?.map((item, index) => {
            return (
              <Box
                key={index}
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "stretch",
                  borderBottom: "1px solid #EFEFEF",
                }}
              >
                <HoverText
                  onClick={() => {
                    handleBugClick && handleBugClick(item.id);
                    setBug && setBug(item.id.toString());
                    setBugStatus(item.record_status);
                  }}
                  sx={{ width: "65%" }}
                  icon={<Icons name="chevronRight" fill="#8E8D8A" />}
                >
                  <Box
                    sx={{
                      padding: "15px",
                    }}
                    display={"flex"}
                    flexDirection={"column"}
                  >
                    <Typography color={"#000000"} variant="bodySm">
                      {item.reported_in}
                    </Typography>
                    <Typography
                      color={"#000000"}
                      variant="bodySmBold"
                      sx={{ textTransform: "capitalize" }}
                    >
                      {`'${item.bug_title}'`}
                    </Typography>
                    <Typography color={"#696969"} variant="bodySm">
                      {formatDate(item.createdAt)}
                    </Typography>
                  </Box>
                </HoverText>
                <HoverText
                  sx={{ flexGrow: 1 }}
                  icon={<Icons name="userArrowDown" fill="#8E8D8A" />}
                >
                  <StatusDropdown
                    sx={{ width: "100%" }}
                    status={item.record_status.toLowerCase()}
                    fileId={item.id}
                    type={"bugs"}
                    setToast={setToast}
                    onClickCallback={handleBug}
                  />
                </HoverText>
              </Box>
            );
          })
        )}
        {toast && (
          <ToastMessage
            type={toast.type as "success" | "error"}
            message={toast.message}
            onClose={() => setToast(null)}
            style={{
              position: "abosolute",
              top: "90px",
              textTransform: "capitalize",
            }}
          />
        )}
      </RightDrawer>
      <FileDetail
        drawer={openViewFile}
        setDrawer={setOpenViewFile}
        selectedRowData={selectedRowData}
        isEditing={false}
      />
    </>
  );
};

export default BugList;
