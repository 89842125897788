import React, { ReactNode } from "react";
import Dialog from "@mui/material/Dialog";
import Typography from "@mui/material/Typography";
import IconComponent from "../../components/Icons";
import { Box } from "@mui/material";
import Button from "../Button";

interface GlobalPopupModal {
  titleName: string;
  open: boolean;
  btnText: string;
  handleCloseModal: () => void;
  handleFooterBtn: () => void;
  handleFooterCancle?: () => void;
  children?: ReactNode;
  modalWidth: string;
  isCreateButtonDisabled?: boolean;
  loading?:boolean;
}

const GlobalPopupModal: React.FC<GlobalPopupModal> = ({
  titleName,
  open,
  btnText,
  handleCloseModal,
  handleFooterBtn,
  handleFooterCancle,
  modalWidth,
  children,
  isCreateButtonDisabled,
  loading
}) => {
  return (
    <Dialog
      open={open}
      PaperProps={{
        style: {
          width: modalWidth,
          borderRadius: "16px",
          overflowY: "hidden",
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          paddingX: "28px",
          paddingY: "15.5px",
          borderBottom: "1px solid #EFEFEF",
          marginX: "7px",
        }}
      >
        <Typography color="contour.title3" variant="title4">
          {titleName}
        </Typography>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginLeft: "auto",
            cursor: "pointer",
          }}
          onClick={handleCloseModal}
        >
          <IconComponent name="close" fill="#8E8D8A" width={20} height={20} />
        </div>
      </Box>
      <Box sx={{ paddingX: "28px", paddingY: "24px", overflowY: "scroll" }}>
        {children}
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          paddingX: "28px",
          paddingY: "15.5px",
          borderTop: "1px solid #EFEFEF",
        }}
      >
        <div style={{ display: "flex", marginLeft: "auto" }}>
          {handleFooterCancle && (
            <div style={{ cursor: "pointer" }} onClick={handleFooterCancle}>
              <Box
                sx={{
                  border: "1px solid #FFA737",
                  borderRadius: "8px",
                  paddingX: "12px",
                  bgcolor: "#FFF",
                  height: "38px",
                  display: "flex",
                  flexDirection: "row",
                  gap: "8px",
                  alignItems: "center",
                  justifyContent: "center",
                  marginX: "8px",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: 600,
                    fontFamily: "Inter",
                  }}
                >
                  Cancel
                </Typography>
              </Box>
            </div>
          )}

          <div
            style={{
              cursor: "pointer",
              pointerEvents: isCreateButtonDisabled ? "none" : "auto",
              opacity: isCreateButtonDisabled ? "0.5" : "1",
            
            }}
            onClick={handleFooterBtn}
          >
            <Button loading={loading} text={btnText} textStyle={{ color: "#333" }} />
          </div>
        </div>
      </Box>
    </Dialog>
  );
};

export default GlobalPopupModal;
