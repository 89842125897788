import React, { useState } from "react";
import Box from "@mui/material/Box";

interface hoverProps {
  children: React.ReactNode;
  icon: React.ReactNode;
  sx?: object;
  onClick?: () => void;
}

const HoverText = ({ children, sx, icon, onClick }: hoverProps) => {
  const [isHovering, setIsHovering] = useState(false);

  const handleMouseOver = () => {
    setIsHovering(true);
  };

  const handleMouseOut = () => {
    setIsHovering(false);
  };
  return (
    <Box
      onClick={onClick}
      sx={{
        cursor: "pointer",
        display: "flex",
        flexDirection: "row",
        position: "relative",
        "&:hover": { bgcolor: "#ffa7371f", borderRadius: "8px" },
        ...sx,
      }}
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
    >
      {children}
      {isHovering && (
        <Box
          sx={{
            position: "absolute",
            right: 10,
            top: 0,
            bottom: 0,
            display: "flex",
            alignItems: "center",
          }}
        >
          {icon}
        </Box>
      )}
    </Box>
  );
};

export default HoverText;
