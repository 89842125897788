import * as React from "react";
import { styled } from "@mui/material/styles";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Icons from "../Icons";
import Typography from "@mui/material/Typography";
import { Box, Checkbox, FormControlLabel, Grid } from "@mui/material";
import CustomizedSwitchComponent from "../Switch";
import { updateSubAccess } from "../../services/api/student";
import Cookies from "js-cookie";
import ToastMessage from "../ToastMessage";
import moment from "moment";

type Student = {
  lastAccessed: any;
  last_access_date: any;
  time_spent: any;
  id: string;
  mst_student_id: number;
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  access_start_date: string | null;
  access_end_date: string | null;
  access_status: boolean;
  campus_id: string;
  enrolmentStatus?: string;
  SubjectCode?: string[];
};

type TableDataa = {
  id: number;
  sub_code: string;
  label: string;
  students: Student[];
};
interface CustomizedAccordionsProps {
  onSelectionChange: (selectedCount: number, checked: number[]) => void;
  stuData: TableDataa[];
  loading: boolean;
  fetchData?: (select: string) => Promise<void>;
}

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(() => ({
  marginBottom: "0px !important",
  borderRadius: "0px !important",
  height: "unset !important",
  minHeight: "unset !important",
  width: "100%",
  border: "0",
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled(MuiAccordionSummary)(({ theme }) => ({
  height: "unset !important",
  minHeight: "unset !important",
  padding: theme.spacing(0),
  paddingRight: theme.spacing(2),
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(0),
  borderTop: "1px solid #EFEFEF !important",
}));

const CustomizedAccordions: React.FC<CustomizedAccordionsProps> = ({
  onSelectionChange,
  stuData,
  loading,
  fetchData,
}) => {
  const verifyAccessString = Cookies.get("authAccess");
  const verifyAccess = verifyAccessString
    ? JSON.parse(verifyAccessString)
    : null;
  const [currentPage, setCurrentPage] = React.useState(1);
  const itemsPerPage = 10;
  const [toast, setToast] = React.useState<{
    type: string;
    message: string;
  } | null>(null);
  const indexOfLastItem = Math.min(currentPage * itemsPerPage, stuData.length);
  const indexOfFirstItem = Math.min(
    (currentPage - 1) * itemsPerPage,
    stuData.length
  );
  const currentItems = stuData?.slice(indexOfFirstItem, indexOfLastItem) || [];
  const [checked, setChecked] = React.useState<boolean[]>(
    stuData && stuData.map(() => false)
  );

  const [switchStates, setSwitchStates] = React.useState<boolean[]>([]);

  React.useEffect(() => {
    if (Array.isArray(stuData)) {
      const initialSwitchStates = stuData.flatMap((subject) =>
        Array.isArray(subject.students)
          ? subject.students.map((item) => item.access_status)
          : []
      );
      setSwitchStates(initialSwitchStates);
    }
  }, [stuData]);

  const handleOnOff =
    (subjectId: number, studentId: number, index: number) => async () => {
      setSwitchStates((prevSwitchStates) => {
        const newSwitchStates = [...prevSwitchStates];
        newSwitchStates[index] = !newSwitchStates[index];
        return newSwitchStates;
      });

      try {
        const token = verifyAccess.token;
        const updatedSwitchState = !switchStates[index];
        const payload = {
          subjectId,
          studentId,
          newAccessStatus: updatedSwitchState,
        };
        console.log("payload", payload);
        const response = await updateSubAccess(payload, token);
        if (response && response.data) {
          fetchData && fetchData("Subject");
          const successMessage = response.data.message;
          setToast({
            type: "success",
            message: successMessage,
          });
        } else {
          const errorMessage =
            response && response.data.message
              ? response.data.message
              : "An error occured";
          setToast &&
            setToast({
              type: "error",
              message: errorMessage,
            });
        }
        console.log("updateStuSubAccess", response);
      } catch (error) {
        console.error("Error fetching student details:", error);
      }
    };

  const [checkedIds, setCheckedIds] = React.useState<number[]>([]);

  const handleChange =
    (index: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setChecked((prevChecked) => {
        const newChecked = [...prevChecked];
        newChecked[index] = event.target.checked;
        const selectedCount = newChecked.filter(
          (isChecked) => isChecked
        ).length;
        const newCheckedIds = [...checkedIds];
        if (event.target.checked) {
          newCheckedIds.push(index);
        } else {
          const indexToRemove = newCheckedIds.indexOf(index);
          if (indexToRemove !== -1) {
            newCheckedIds.splice(indexToRemove, 1);
          }
        }
        setCheckedIds(newCheckedIds);
        onSelectionChange(selectedCount, newCheckedIds);

        return newChecked;
      });
    };

  return (
    <>
      {loading ? (
        <Typography sx={{ textAlign: "center", marginTop: "40px" }}>
          Loading....
        </Typography>
      ) : (
        <div>
          <Box
            sx={{
              background: "#F4F6FF",
              borderTopLeftRadius: "12px",
              borderTopRightRadius: "12px",
            }}
          >
            <Box sx={{ paddingX: "12px", paddingY: "10px" }}>
              <FormControlLabel
                label=""
                control={
                  <Checkbox
                    checked={checked.every(Boolean)}
                    indeterminate={
                      !checked.every(Boolean) && checked.some(Boolean)
                    }
                    onChange={(event) => {
                      const allChecked = event.target.checked;
                      setChecked((prevChecked) =>
                        prevChecked.map(() => allChecked)
                      );

                      const selectedCount = allChecked ? stuData.length : 0;
                      const checkedIds = allChecked
                        ? stuData.map((student) => student.id)
                        : [];
                      onSelectionChange(selectedCount, checkedIds);
                    }}
                    sx={{
                      color: "#D2D2D0",
                      padding: "0",
                      width: "20px",
                      height: "20px",
                      marginLeft: "12px",
                      "&.Mui-checked": {
                        color: "#FFA737",
                      },
                      "&.MuiCheckbox-indeterminate": {
                        color: "#FFA737",
                      },
                    }}
                  />
                }
              />
              <Typography
                variant="detail"
                color="#696969"
                sx={{ paddingRight: "13px" }}
              >
                Subjects
              </Typography>
              <Icons name="userArrowDown" fill={"#8E8D8A"} />
            </Box>
          </Box>

          {stuData.length > 0 ? (
            <>
              {currentItems.map((subject, index) => (
                <Box
                  key={subject.id}
                  sx={{
                    display: "grid",
                    alignItems: "flex-start",
                    borderBottom: "1px solid #EFEFEF",
                    position: "relative",
                  }}
                >
                  <FormControlLabel
                    label=""
                    control={
                      <Checkbox
                        checked={checked[subject.id]}
                        onChange={handleChange(subject.id)}
                        sx={{
                          color: "#D2D2D0",
                          padding: "0",
                          width: "20px",
                          height: "20px",
                          position: "absolute",
                          top: "0",
                          zIndex: "99",
                          marginLeft: "24px",
                          marginY: "12px",
                          "&.Mui-checked": {
                            color: "#FFA737",
                          },
                        }}
                      />
                    }
                  />
                  <Accordion>
                    <AccordionSummary
                      aria-controls={`panel${index}-content`}
                      id={`panel${index}-header`}
                      expandIcon={<Icons name="chevronDown" fill={"#8E8D8A"} />}
                      sx={{
                        background: checked[index]
                          ? "#FFF8EC !important"
                          : "#FFFFFF !important",
                        ":Hover": { background: "#FFF8EC !important" },
                      }}
                    >
                      <Typography
                        sx={{ marginLeft: "44px", textTransform: "capitalize" }}
                        variant="detail"
                        color="#696969"
                      >
                        {subject.sub_code} - {subject.label}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails
                      sx={{
                        background: checked[index] ? "#FFEDD1" : "#FAF9F6",
                      }}
                    >
                      <Grid
                        container
                        sx={{
                          padding: "10px",
                          borderBottom: "1px solid #D2D2D0",
                        }}
                      >
                        <Grid item md={2}>
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            <Typography
                              variant="detail"
                              color="#696969"
                              sx={{ paddingRight: "13px" }}
                            >
                              Subjects
                            </Typography>
                            <Icons name="userArrowDown" fill={"#8E8D8A"} />
                          </Box>
                        </Grid>
                        <Grid item md={2}>
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            <Typography
                              variant="detail"
                              color="#696969"
                              sx={{ paddingRight: "13px" }}
                            >
                              Email
                            </Typography>
                            <Icons name="userArrowDown" fill={"#8E8D8A"} />
                          </Box>
                        </Grid>
                        <Grid item md={2}>
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            <Typography
                              variant="detail"
                              color="#696969"
                              sx={{ paddingRight: "13px" }}
                            >
                              Phone
                            </Typography>
                            <Icons name="userArrowDown" fill={"#8E8D8A"} />
                          </Box>
                        </Grid>
                        <Grid item md={2}>
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            <Typography
                              variant="detail"
                              color="#696969"
                              sx={{ paddingRight: "13px" }}
                            >
                              Time spent
                            </Typography>
                            <Icons name="userArrowDown" fill={"#8E8D8A"} />
                          </Box>
                        </Grid>
                        <Grid item md={2}>
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            <Typography
                              variant="detail"
                              color="#696969"
                              sx={{ paddingRight: "13px" }}
                            >
                              Last accessed
                            </Typography>
                            <Icons name="userArrowDown" fill={"#8E8D8A"} />
                          </Box>
                        </Grid>
                        <Grid item md={2}>
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            <Typography
                              variant="detail"
                              color="#696969"
                              sx={{ paddingRight: "13px" }}
                            >
                              Program access
                            </Typography>
                            <Icons name="userArrowDown" fill={"#8E8D8A"} />
                          </Box>
                        </Grid>
                      </Grid>
                      {subject?.students?.map((sub, subIndex) => (
                        <Grid
                          key={subIndex}
                          container
                          sx={{
                            borderBottom:
                              subIndex === subject.students.length - 1
                                ? "none"
                                : "1px solid #D2D2D0",
                            paddingX: "8px",
                            alignItems: "center",
                          }}
                        >
                          <Grid md={2}>
                            <Box
                              display={"flex"}
                              gap={"8px"}
                              flexDirection={"row"}
                              sx={{ paddingY: "3.5px" }}
                              alignItems={"center"}
                            >
                              <Box
                                sx={{
                                  height: "24px",
                                  width: "24px",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                <Typography variant="detail">
                                  {sub.first_name.charAt(0).toUpperCase()}
                                </Typography>
                              </Box>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                <Typography
                                  variant="detail"
                                  sx={{ textTransform: "capitalize" }}
                                >
                                  {sub.first_name} {sub.last_name}
                                </Typography>
                                {sub.campus_id && (
                                  <Typography
                                    color="#696969"
                                    variant="detail"
                                    sx={{ textTransform: "capitalize" }}
                                  >
                                    {sub.campus_id}
                                  </Typography>
                                )}
                              </div>
                            </Box>
                          </Grid>
                          <Grid md={2}>
                            <Box sx={{ paddingY: "3.5px" }}>
                              <Typography variant="detail" color="#696969">
                                {sub.email}
                              </Typography>
                            </Box>
                          </Grid>
                          <Grid md={2}>
                            <Box sx={{ paddingY: "3.5px" }}>
                              <Typography variant="detail" color="#696969">
                                {sub.phone}
                              </Typography>
                            </Box>
                          </Grid>
                          <Grid md={2}>
                            <Box sx={{ paddingY: "3.5px" }}>
                              <Typography variant="detail" color="#696969">{sub.time_spent}</Typography>
                            </Box>
                          </Grid>
                          <Grid md={2}>
                            <Box sx={{ paddingY: "3.5px" }}>
                              <Typography variant="detail" color="#696969">
                                {
                                  sub.last_access_date ?
                                  moment(moment.utc(sub.last_access_date).toDate()).local().fromNow()
                                  :''
                                }
                              </Typography>
                            </Box>
                          </Grid>
                          <Grid md={2}>
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                              <CustomizedSwitchComponent
                                onToggle={handleOnOff(
                                  subject.id,
                                  sub.mst_student_id,
                                  index * subject.students.length + subIndex
                                )}
                                defaultChecked={sub.access_status}
                                key={sub.access_status.toString()}
                              />
                              <Typography variant="detail" color="#696969">
                                {switchStates[subIndex]
                                  ? "Access enabled"
                                  : "Access disabled"}
                              </Typography>
                            </Box>
                          </Grid>
                        </Grid>
                      ))}
                    </AccordionDetails>
                  </Accordion>
                </Box>
              ))}
            </>
          ) : (
            <Typography sx={{ textAlign: "center", marginTop: "30px" }}>
              No data found
            </Typography>
          )}

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "end",
              marginTop: "20px",
            }}
          >
            <Typography
              sx={{
                marginRight: "28px",
                fontSize: "14px",
                fontWeight: "500",
                color: "#333333",
              }}
            >
              {stuData.length === 0
                ? `0-0 of 0`
                : `${indexOfFirstItem + 1}-${indexOfLastItem} of ${
                    stuData.length
                  }`}
            </Typography>
            <button
              style={{
                transform: "rotate(180deg)",
                background: "transparent",
                padding: "0px",
                border: "none",
                display: "flex",
                marginRight: "30px",
                cursor: currentPage === 1 ? "default" : "pointer",
              }}
              onClick={() =>
                setCurrentPage((prevPage) => Math.max(prevPage - 1, 1))
              }
              disabled={currentPage === 1 || stuData.length === 0}
            >
              <Icons name="chevronRight" fill="#8E8D8A" />
            </button>
            <button
              style={{
                background: "transparent",
                padding: "0px",
                border: "none",
                display: "flex",
                cursor:
                  currentPage === Math.ceil(stuData.length / itemsPerPage) ||
                  stuData.length === 0
                    ? "default"
                    : "pointer",
              }}
              onClick={() =>
                setCurrentPage((prevPage) =>
                  Math.min(
                    prevPage + 1,
                    Math.ceil(stuData.length / itemsPerPage)
                  )
                )
              }

              disabled={
                currentPage === Math.ceil(stuData.length / itemsPerPage) ||
                stuData.length === 0
              }
            >
              <Icons name="chevronRight" fill="#8E8D8A" />
            </button>
          </Box>
        </div>
      )}
      {toast && (
        <ToastMessage
          type={toast.type as "success" | "error"}
          message={toast.message}
          onClose={() => setToast(null)}
          style={{
            top: "85px",
          }}
        />
      )}
    </>
  );
};

export default CustomizedAccordions;
