import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { loginCredentials, verifyOtp } from "../services/api/login";
import Cookies from "js-cookie";
interface UserResponse {
  first_name: string;
  last_name: string;
  email: string;
  token: string;
  is_verified: boolean;
  two_fa: boolean;
}

interface AuthState {
  isLoggedin: boolean;
  token: string | null;
  loading: boolean;
  errorMessage: string | null;
}

interface AuthStateOtp {
  token: string | null;
  user: UserResponse | null;
  loading: boolean;
  errorMessage: string | null;
}

const initialState: AuthState = {
  isLoggedin: false,
  token: Cookies.get("loginToken") || null,
  loading: false,
  errorMessage: null,
};

const getInitialAuthStateOtp = (): AuthStateOtp => {
  const authAccessString = Cookies.get("authAccess");
  const authAccess = authAccessString ? JSON.parse(authAccessString) : null;

  return {
    token: authAccess ? authAccess.token : null,
    user: authAccess ? authAccess.responseData : null,
    loading: false,
    errorMessage: null,
  };
};

const initialStateOtp: AuthStateOtp = getInitialAuthStateOtp();

export const authenticateWithCredentials = createAsyncThunk(
  "auth/loginWithCredentials",
  async ({ email, password }: { email: string; password: string }) => {
    try {
      const response = await loginCredentials({ email, password });
      Cookies.set("loginToken", response.data.token);
      console.log("response", response);
      return response.data;
    } catch (error: any) {
      console.log("error", error);
      throw new Error(error.response.data.error || "something went wrong");
    }
  },
);

export const verifyOtpThunk = createAsyncThunk(
  "auth/verifyOtp",
  async ({ otp, token }: { otp: string; token: string }) => {
    const response = await verifyOtp({ otp: otp }, token);
    Cookies.set("authAccess", JSON.stringify(response.data));
    return response.data.responseData;
  },
);

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAuthState: (state, action: PayloadAction<AuthState>) => {
      const { isLoggedin, token, errorMessage } = action.payload;
      state.isLoggedin = isLoggedin;
      state.token = token;
      state.errorMessage = errorMessage;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(authenticateWithCredentials.pending, (state: AuthState) => {
      state.loading = true;
    });
    builder.addCase(
      authenticateWithCredentials.fulfilled,
      (state: AuthState, action: PayloadAction<AuthState>) => {
        state.loading = false;
        const { payload } = action;
        state.isLoggedin = true;
        state.token = payload.token;
        state.errorMessage = null;
      },
    );
    builder.addCase(
      authenticateWithCredentials.rejected,
      (state: AuthState, action: PayloadAction<unknown>) => {
        state.loading = false;
        state.isLoggedin = false;
        state.token = null;
        state.errorMessage = action.payload as string;
      },
    );
  },
});

export const authSliceOtp = createSlice({
  name: "authOtp",
  initialState: initialStateOtp,
  reducers: {
    setAuthStateOtp: (state, action: PayloadAction<AuthStateOtp>) => {
      const { token, user, errorMessage } = action.payload;
      state.token = token;
      state.user = user;
      state.errorMessage = errorMessage;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(verifyOtpThunk.pending, (state: AuthStateOtp) => {
      state.loading = true;
    });
    builder.addCase(
      verifyOtpThunk.fulfilled,
      (state: AuthStateOtp, action: PayloadAction<UserResponse>) => {
        state.loading = false;
        const { payload } = action;
        state.token = payload.token;
        state.user = payload;
        state.errorMessage = null;
      },
    );
    builder.addCase(
      verifyOtpThunk.rejected,
      (state: AuthStateOtp, action: PayloadAction<unknown>) => {
        state.loading = false;
        state.token = null;
        state.user = null;
        state.errorMessage = action.payload as string;
      },
    );
  },
});

export const { setAuthState } = authSlice.actions;
export const { setAuthStateOtp } = authSliceOtp.actions;

export const selectAuthStateCredentials = (state: { auth: AuthState }) =>
  state.auth;
export const selectAuthStateOtp = (state: { authOtp: AuthStateOtp }) =>
  state.authOtp;

export const authReducerCredentials = authSlice.reducer;
export const authReducerOtp = authSliceOtp.reducer;
