import React from "react";
import { Box, Typography, Popover } from "@mui/material";
import Icons from "../Icons";
import { bugStautusUpdate } from "../../services/api/dashboard";
import { setRefetchContent as setRefetchContentFileData  } from "../../store/filelibrary.slice";
import { setRefetchContent as setRefetchContentBugData } from "../../store/filelibrary.slice";
import { useDispatch } from "react-redux";

type ToastState = {
  type: string;
  message: string;
};
interface dropProps {
  status: string;
  type: string;
  dropdown?: boolean;
  fileId?: number;
  token: string | undefined;
  setToast?: React.Dispatch<React.SetStateAction<ToastState | null>>;
  onClickCallback: (status: string) => void;
  dateUpdate: string;
}

const data = [
  {
    label: "Needs attention",
    status: "needs attention",
    color: "#C61341",
    bgColor: "#FFEEF2",
    type: "bugs",
  },
  {
    label: "In review",
    status: "in review",
    color: "#0069DB",
    bgColor: "#E6F2FF",
    type: "bugs",
  },
  {
    label: "Resolved",
    status: "resolved",
    color: "#007634",
    bgColor: "#E6F6ED",
    type: "bugs",
  },
];

const BugStatus = ({
  status,
  type,
  onClickCallback,
  dateUpdate,
  fileId,
  token,
  setToast,
}: dropProps) => {
  const dispatch=useDispatch()
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null,
  );
  const [selectedLabel, setSelectedLabel] = React.useState<string>("");
  const [selectedColor, setSelectedColor] = React.useState<string>("");
  const [selectedBgColor, setSelectedBgColor] = React.useState<string>("");

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChange = async (
    dynamicValue: string,
    label: string,
    color: string,
    bgColor: string,
  ) => {
    try {
      const payload: {
        bugid: number;
        status?: string;
      } = {
        bugid: fileId!,
      };
      payload.status = dynamicValue;

      const response = await bugStautusUpdate(payload, token ?? null);
      if (response && response.data) {
        const successMessage = response.data.message;
        dispatch(setRefetchContentBugData({shouldRefetch:new Date().getMilliseconds()}))
        dispatch(setRefetchContentFileData({shouldRefetch:new Date().getMilliseconds()}))
        setToast &&
          setToast({
            type: "success",
            message: successMessage,
          });
      } else {
        const errorMessage =
          response && response.data.message
            ? response.data.message
            : "An error occured";
        setToast &&
          setToast({
            type: "error",
            message: errorMessage,
          });
      }
    } catch (error) {
      console.error("Error updating status:", error);
    }
    setSelectedLabel(label);
    setSelectedColor(color);
    setSelectedBgColor(bgColor);
    onClickCallback(dynamicValue);
    setAnchorEl(null);
  };

  const dateObj = new Date(dateUpdate);
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const month = monthNames[dateObj.getMonth()];
  const day = dateObj.getDate();
  const year = dateObj.getFullYear();
  let hours = dateObj.getHours();
  const minutes = (dateObj.getMinutes() < 10 ? "0" : "") + dateObj.getMinutes();
  const ampm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12;
  hours = hours ? hours : 12;
  const formattedDate = `${month} ${day}, ${year} ${hours}:${minutes}${ampm}`;

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  console.log("status", status && status);
  const getStatus = data.find((i) => i.status == status && i.type == type);

  return (
    <>
      <Box
        aria-describedby={id}
        component={"button"}
        bgcolor={anchorEl !== null ? "#FFF8EC" : "white"}
        display={"flex"}
        flexDirection={"row"}
        gap={"8px"}
        alignItems={"center"}
        padding={"8px"}
        borderRadius={"8px"}
        border={"1px solid #EFEFEF"}
        onClick={handleClick}
        sx={{cursor:"pointer"}}
      >
        <Box
          display={"flex"}
          flexDirection={"row"}
          gap={"8px"}
          alignItems={"center"}
          bgcolor={selectedBgColor || getStatus?.bgColor}
          paddingX={"8px"}
          paddingY={"4px"}
          borderRadius={"16px"}
          width={"fit-content"}
        >
          <Box
            width={"7px"}
            height={"7px"}
            bgcolor={selectedColor || getStatus?.color}
            borderRadius={"50%"}
            display={"inline-block"}
          />
          <Typography
            color={selectedColor || getStatus?.color}
            variant="detailBold"
          >
            {selectedLabel || getStatus?.label}
          </Typography>
        </Box>
        <Typography color={"#696969"} variant="bodySm">
          {formattedDate}
        </Typography>
        <Icons name="userArrowDown" fill={"#8E8D8A"} />
      </Box>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <Box
          sx={{ borderRadius: "16px", width: "310px" }}
          display={"flex"}
          flexDirection={"column"}
        >
          {data.map((item, id) => {
            return (
              <Box
                key={id}
                component={"div"}
                paddingX={"12px"}
                paddingY={"8px"}
                display={"flex"}
                flexDirection={"row"}
                gap={"8px"}
                sx={{cursor:"pointer"}}
                alignItems={"center"}
                borderBottom={
                  id != data.length - 1 ? "1px solid #D2D2D0" : "0px"
                }
                onClick={() => {
                  handleChange(
                    item.status,
                    item.label,
                    item.color,
                    item.bgColor,
                  );
                }}
              >
                <Box
                  display={"flex"}
                  flexDirection={"row"}
                  gap={"8px"}
                  alignItems={"center"}
                  bgcolor={item?.bgColor}
                  paddingX={"8px"}
                  paddingY={"4px"}
                  borderRadius={"16px"}
                  width={"fit-content"}
                >
                  <Box
                    width={"7px"}
                    height={"7px"}
                    bgcolor={item?.color}
                    borderRadius={"50%"}
                    display={"inline-block"}
                  />
                  <Typography color={item?.color} variant="detailBold">
                    {item?.label}
                  </Typography>
                </Box>
              </Box>
            );
          })}
        </Box>
      </Popover>
    </>
  );
};

export default BugStatus;
