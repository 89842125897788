import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

interface CalendarComponentProps {
  selectedStartDate: Date | null;
  handleDateChange: (dates: Date | null) => void;
  fromDateFocused: boolean;
  setFromDateFocused: React.Dispatch<React.SetStateAction<boolean>>;
}

const UploadCalendarComponent: React.FC<CalendarComponentProps> = ({
  selectedStartDate,
  handleDateChange,
  fromDateFocused,
  setFromDateFocused,
}) => {
  const today = new Date();

  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          marginBottom: "5px",
        }}
      >
        <label
          style={{
            paddingBottom: "4px",
            display: "block",
            fontSize: "12px",
            fontWeight: "500",
            color: "#333",
          }}
        >
          Date
        </label>
        <input
          style={{
            height: "44px",
            display: "block",
            padding: "12px 16px",
            fontSize: "14px",
            fontWeight: "400",
            color: "#333",
            borderRadius: "8px",
            border: `1px solid ${selectedStartDate ? "#FFA737" : (fromDateFocused ? "#FFA737" : "#D2D2D0")}`,
            backgroundColor: selectedStartDate ? "#FFF8EC" : (fromDateFocused ? "#FFF8EC": "transparent"),
          }}
          type="text"
          readOnly
          onFocus={() => setFromDateFocused(true)}
          value={
            selectedStartDate
              ? selectedStartDate.toLocaleDateString("en-GB", {
                  year: "2-digit",
                  month: "2-digit",
                  day: "2-digit",
                })
              : ""
          }
          placeholder="--/--/--"
        />
      </div>
      <div className="upload">
        <DatePicker
          open
          inline
          minDate={today}
          startDate={selectedStartDate || null}
          onChange={(date) => handleDateChange(date as Date | null)}
          dateFormat="dd/MM/yy"
          calendarStartDay={1}
        />
      </div>
    </div>
  );
};

export default UploadCalendarComponent;
