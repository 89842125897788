import React, { useState, useEffect, ChangeEvent } from "react";
import { Box, TextField, Typography } from "@mui/material";
import Loginlogo from "../../assets/images/ContourLogo.png";
import Button from "../../components/Button";
import Loginlayout from "../../layouts/LoginLayout";
import IconComponent from "../../components/Icons";
import BorderText from "../../components/Label/BorderText";
import { useNavigate } from "react-router-dom";
import { forgotPassword } from "../../services/api/login";
import ToastMessage from "../../components/ToastMessage";

interface FormData {
  email: string;
}

const validateEmail = (email: string): boolean => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

const ForgottenPassword = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState<FormData>({
    email: "",
  });
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [loading, setLoading] = useState(false);

  const [toast, setToast] = React.useState<{
    type: string;
    message: string;
  } | null>(null);

  useEffect(() => {
    const savedUserData = sessionStorage.getItem("userData");
    if (savedUserData) {
      setFormData(JSON.parse(savedUserData));
    }
  }, []);

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { id, value } = event.target;
    const isEmailValid = validateEmail(value);
    setIsEmailValid(isEmailValid);
    setFormData((prevData) => ({
      ...prevData,
      [id]: value,
    }));
  };

  const handleForgotPassword = async () => {
    const isEmailValid = validateEmail(formData.email);
    setIsEmailValid(isEmailValid);

    if (isEmailValid) {
      setLoading(true);
      try {
        const response = await forgotPassword({ email: formData.email });
        if (response && response.data) {
          setLoading(false);
          setFormData({ email: "" });
          const successMessage = response.data.message;
          setToast({
            type: "success",
            message: successMessage,
          });
        } else {
          const errorMessage =
            response && response.data.message
              ? response.data.message
              : "An error occured";
          setLoading(false);
          setToast({
            type: "error",
            message: errorMessage,
          });
        }
      } catch (error) {
        console.error("Forgot api", error);
        setLoading(false);
      }
    }
  };

  const handleBack = () => {
    navigate("/");
  };

  const isResetButtonDisabled = !formData.email || !isEmailValid;

  const handleKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      if (formData.email.trim() !== "") {
        handleForgotPassword();
      }
    }
  };

  return (
    <Loginlayout>
      {toast && (
        <ToastMessage
          type={toast.type as "success" | "error"}
          message={toast.message}
          onClose={() => setToast(null)}
          style={{
            position: "absolute",
            top: "5%",
          }}
        />
      )}
      <Box
        sx={{ marginBottom: "60px", width: "90%" }}
        component="img"
        src={Loginlogo}
        alt="brand-logo"
      />
      <div
        style={{
          maxWidth: "54px",
          cursor: "pointer",
          marginLeft: "-6px",
          marginBottom: "20px",
        }}
        onClick={handleBack}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <IconComponent name="chevronLeft" fill={"#8E8D8A"} />
          <BorderText text={"Back"} />
        </Box>
      </div>
      <div>
        <Typography color={"contour.title3"} variant="title2">
          Forgot Password
        </Typography>
        <Box
          width={"100%"}
          sx={{ paddingY: "40px" }}
          display={"flex"}
          flexDirection={"column"}
        >
          <Typography
            color={"#333333"}
            variant="detailBold"
            sx={{ marginBottom: "4px" }}
          >
            Email
          </Typography>
          <TextField
            id="email"
            variant="outlined"
            value={formData.email}
            onChange={handleInputChange}
            error={!isEmailValid}
            onKeyDown={handleKeyPress}
            helperText={
              !isEmailValid ? (
                <span
                  style={{ display: "flex", alignItems: "center", gap: "6px" }}
                >
                  <IconComponent name="warningRed" fill={"#8E8D8A"} />
                  Enter a valid email address
                </span>
              ) : (
                ""
              )
            }
            sx={{
              marginBottom: "8px",
              ".MuiOutlinedInput-notchedOutline": {
                borderRadius: "8px",
                border: "1px solid #D2D2D0",
              },
              "& .css-5ifmys-MuiFormHelperText-root": {
                marginLeft: 0,
              },
            }}
          />
        </Box>

        <Box sx={{ maxWidth: "168px" }}>
          {/* {!loading ? ( */}
            <Button
            loading={loading}
              text="Reset"
              textStyle={{
                color: "#333",
                opacity: isResetButtonDisabled ? "0.5" : "1",
                cursor: isResetButtonDisabled ? "none" : "pointer",
                pointerEvents: isResetButtonDisabled ? "none" : "auto",
              }}
              handleClick={handleForgotPassword}
            />
        </Box>
      </div>
    </Loginlayout>
  );
};

export default ForgottenPassword;
