import React from "react";
import { Box, Typography, CircularProgress } from "@mui/material";

interface CircularProgressProps {
  showLoader: boolean;
  uploadProgress: number;
  addFileTag?: boolean;
}

const CircularProgressScreen: React.FC<CircularProgressProps> = ({
  showLoader,
  uploadProgress,
  addFileTag,
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        marginTop: addFileTag ? "16px" : "",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        border: "2px dashed #D2D2D0",
        borderRadius: "8px",
        padding: "22px 0px",
        cursor: "pointer",
        backgroundColor: showLoader ? "#007AFF" : "#007AFF",
        "&:hover": {
          backgroundColor: showLoader ? "#007AFF" : "#ffa7371f",
        },
      }}
    >
      <Box
        sx={{
          height: "89px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <CircularProgress
          value={uploadProgress}
          size={30}
          thickness={5}
          sx={{
            color: "#fff",
          }}
        />

        <Typography
          sx={{
            fontSize: "16px",
            fontWeight: "600",
            color: "#fff",
          }}
        >
          Reading files...
        </Typography>
      </Box>
    </Box>
  );
};

export default CircularProgressScreen;
