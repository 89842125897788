import React, { Dispatch, SetStateAction, useState } from "react";
import { Box, Checkbox, Grid, Typography } from "@mui/material";
import IconComponent from "../Icons";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import StudentCards from "../Cards/SubjectCards";
import Button from "../Button";
import Icons from "../Icons";
import BorderText from "../Label/BorderText";
import { SearchSubjectComponent } from "./SearchSubjectComponent";
import SubjectRightDrawer from "../Drawer/SubjectRightDrawer";
import { getStudentDetail, getSubjectList } from "../../services/api/student";
import Cookies from "js-cookie";
import { setError } from "../../store/error.slice";
import { useDispatch } from "react-redux";

interface Subject {
  id: number;
  subject_id: string;
  sub_code: string;
  time_spent: string;
  progress: string;
  access_start_date: string;
  subject_name: string;
  access_end_date: string;
  access_status: boolean;
  updated_by: string;
  record_status: string;
}

interface SubjectList {
  id: number;
  sub_code: string;
  subject: string;
  subjects: Subject[];
}

type StudentAddPayload = {
  studentId: number;
  subjects?: {
    subject_id: number;
    access_status: boolean;
  }[];
};

interface StudentDetails {
  student: {
    id: number;
    student_id: number;
    first_name: string;
    last_name: string;
    phone: string;
    email: string;
    campus_id: number;
  };
  Parentdetails: {
    Name: string;
    Relationship: string;
    phone: string;
  };
  subjects: Subject[];
}

interface StudentDetailsDrawerProps {
  drawer: boolean;
  setDrawer: Dispatch<SetStateAction<boolean>>;
  setParamID: Dispatch<SetStateAction<null | number>>;
  paramsRowId: number;
}

const StudentDetailsDrawer: React.FC<StudentDetailsDrawerProps> = ({
  drawer,
  setDrawer,
  setParamID,
  paramsRowId,
}) => {
  const dispatch = useDispatch()
  const verifyAccessString = Cookies.get("authAccess");
  const verifyAccess = verifyAccessString
    ? JSON.parse(verifyAccessString)
    : null;
  const [studentDetails, setStudentDetails] = useState<StudentDetails[]>([]);
  const [newSubject, setNewSubject] = useState(true);
  const [checkboxStates, setCheckboxStates] = useState<{
    [key: string]: boolean;
  }>({});
  const [subjectData, setSubjectData] = useState<SubjectList[]>([]);
  const [filteredSubjects, setFilteredSubjects] = useState<SubjectList[]>(
    subjectData && subjectData,
  );
  const [selectedCodes, setSelectedCodes] = useState<
    { id: number; subject: string; sub_code: string }[]
  >([]);
  const [loading, setLoading] = useState(false)
  React.useEffect(() => {
    console.log("Rendered fewer", paramsRowId, drawer)
    const fetchData = async () => {
      try {
        const token = verifyAccess.token;
        const response = await getStudentDetail(
          { studentId: paramsRowId },
          token,
        );
        setStudentDetails(response.data.data);
      } catch (error) {
        console.error("Error fetching student details:", error);
      }
    };

    if (drawer && paramsRowId) {
      fetchData();
    }
  }, [drawer, paramsRowId]);

  React.useEffect(() => {
    console.log("subjectDatause", subjectData, studentDetails)
    const filteredData = subjectData.filter((subject) => {
      return !studentDetails[0]?.subjects.some(
        (studentSubject) =>
          studentSubject.subject_id === subject.subject &&
          studentSubject.sub_code === subject.sub_code,
      );
    });
    setFilteredSubjects(filteredData);
  }, [subjectData]);

  const handleSearchChange = (value: string) => {
    const filteredData = subjectData.filter(
      (subject) =>
        subject?.subject?.toLowerCase().includes(value.toLowerCase()) ||
        subject?.sub_code?.toLowerCase().includes(value.toLowerCase()),
    );
    setFilteredSubjects(filteredData);
  };

  const fetchSubjectData = async () => {
    try {
      const response = await getSubjectList(verifyAccess.token);
      setSubjectData(response.data.data);
      setFilteredSubjects(response.data.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const HandleAddNewSubject = () => {
    setNewSubject(false);
    fetchSubjectData();
  };

  const handleAddSubject = async () => {
    setLoading(true);
    try {
      const payload: StudentAddPayload = {
        studentId: paramsRowId,
        subjects: selectedCodes.map((selectedSubject) => ({
          subject_id: selectedSubject.id,
          access_status: true,
        })),
      };
      const response = await getStudentDetail(payload, verifyAccess.token);
      setStudentDetails(response.data.data);
      setNewSubject(true);
      setSelectedCodes([]);
      setCheckboxStates({});
    } catch (error: any) {
      dispatch(
        setError({
          message: error?.response?.data?.message || "Something went wrong",
          type: "error",
          time: new Date().toLocaleString(),
        })
      );
      console.error("Error fetching data:", error.response.data.message);
      setNewSubject(false);
    }
    finally {
      setLoading(false);
    }
  };

  const onBack = () => {
    setNewSubject(true);
    setSelectedCodes([]);
    setCheckboxStates({});
  };
  const handleCheckState = (id: number) => {
    setCheckboxStates((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));

    setSelectedCodes((prev) => {
      if (!checkboxStates[id]) {
        const selectedSubject = subjectData.find(
          (subject) => subject.id === id,
        );
        return selectedSubject ? [...prev, selectedSubject] : prev;
      }

      return prev.filter((subject) => subject.id !== id);
    });
  };

  const label = { inputProps: { "aria-label": "Checkbox demo" } };

  const handleRemoveSelectedSubject = (id: number) => {
    setCheckboxStates((prev) => ({
      ...prev,
      [id]: false,
    }));

    setSelectedCodes((prev) => prev.filter((subject) => subject.id !== id));
  };

  const handleCloseDrawer = () => {
    setDrawer(false);
    setParamID(null);
    setNewSubject(true);
    setSelectedCodes([]);
    setCheckboxStates({});
  };

  return (
    <>
      <SubjectRightDrawer
        open={drawer}
        handleClose={handleCloseDrawer}
        title="Student Details"
      >
        {newSubject ? (
          <Box
            sx={{
              overflowY: "auto",
              height: "calc(100vh - 200px)",
              overflowX: "hidden",
            }}
          >
            <Grid
              container
              sx={{ paddingX: "28px", background: "#F4F6FF", paddingY: "24px" }}
            >
              <Grid item md={6}>
                <Typography
                  color="#333333"
                  variant="title4"
                  component={"div"}
                  sx={{ textTransform: "capitalize" }}
                >
                  {/* Alex Lee */}
                  {studentDetails.length > 0 &&
                    studentDetails[0].student.first_name}{" "}
                  {studentDetails.length > 0 &&
                    studentDetails[0].student.last_name}
                </Typography>
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: "400",
                    color: "#333333",
                    textTransform: "capitalize",
                  }}
                >
                  {studentDetails.length > 0 &&
                    studentDetails[0].student.campus_id}
                </Typography>
              </Grid>
              <Grid item md={6}>
                <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
                  <IconComponent
                    name="mail"
                    fill={"#8E8D8A"}
                    width={16}
                    height={16}
                  />
                  <Typography
                    sx={{
                      fontSize: "14px",
                      fontWeight: "400",
                      color: "#333333",
                    }}
                    component={"div"}
                  >
                    {studentDetails.length > 0 &&
                      studentDetails[0].student.email}
                  </Typography>
                </Box>
                <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
                  <IconComponent
                    name="phone"
                    fill={"#8E8D8A"}
                    width={16}
                    height={16}
                  />
                  <Typography
                    sx={{
                      fontSize: "14px",
                      fontWeight: "400",
                      color: "#333333",
                    }}
                  >
                    {studentDetails.length > 0 &&
                      studentDetails[0].student.phone}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
            <Box>
              <Accordion
                sx={{
                  border: "0",
                  marginBottom: "0px !important",
                }}
              >
                <AccordionSummary
                  expandIcon={
                    <Box sx={{ marginX: "12px", display: "flex" }}>
                      <IconComponent name="userArrowDown" fill={"#8E8D8A"} />
                    </Box>
                  }
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  sx={{
                    background: "#FFF",
                    paddingY: "24px",
                    paddingX: "28px",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "16px",
                      fontWeight: "600",
                      color: "#333333",
                      lineHeight: "24px",
                    }}
                  >
                    Parents details
                  </Typography>
                </AccordionSummary>
                <AccordionDetails
                  sx={{
                    paddingX: "28px",
                    paddingTop: "0",
                    paddingBottom: "12px",
                  }}
                >
                  <Box
                    sx={{ display: "flex", flexDirection: "row", gap: "4px" }}
                  >
                    <Box sx={{ width: "120px" }}>
                      <Typography
                        sx={{
                          fontSize: "14px",
                          fontWeight: "400",
                          color: "#696969",
                        }}
                      >
                        Name
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "14px",
                          fontWeight: "400",
                          color: "#696969",
                        }}
                      >
                        Relationship
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "14px",
                          fontWeight: "400",
                          color: "#696969",
                        }}
                      >
                        Phone
                      </Typography>
                    </Box>
                    <Box sx={{}}>
                      <Typography
                        sx={{
                          fontSize: "14px",
                          fontWeight: "400",
                          color: "#333333",
                        }}
                      >
                        {studentDetails.length > 0 &&
                          studentDetails[0].Parentdetails.Name}
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "14px",
                          fontWeight: "400",
                          color: "#333333",
                        }}
                      >
                        {studentDetails.length > 0 &&
                          studentDetails[0].Parentdetails.Relationship}
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "14px",
                          fontWeight: "400",
                          color: "#333333",
                        }}
                      >
                        {studentDetails.length > 0 &&
                          studentDetails[0].Parentdetails.phone}
                      </Typography>
                    </Box>
                  </Box>
                </AccordionDetails>
              </Accordion>
            </Box>
            <Box
              sx={{
                height: "1px",
                marginLeft: "16px",
                width: "93%",
                background: "#EFEFEF",
              }}
            />
            <StudentCards
              subjects={studentDetails[0]?.subjects}
              stuId={
                studentDetails.length > 0
                  ? studentDetails[0].student.student_id
                  : 0
              }
            />
            <Box
              display={"flex"}
              alignItems={"center"}
              justifyContent={"end"}
              gap={"24px"}
              paddingX={"24px"}
              paddingTop={"16px"}
              sx={{ position: "absolute", bottom: "0", right: "0" }}
            >
              <Button
                text="Add new subjects"
                icon={<Icons name="add" fill="#333" width={16} height={16} />}
                textStyle={{ color: "#333" }}
                handleClick={HandleAddNewSubject}
              />
            </Box>
          </Box>
        ) : (
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Box
              onClick={onBack}
              display={"flex"}
              flexDirection={"row"}
              alignItems={"center"}
              gap={"4px"}
              paddingTop={"20px"}
              paddingX={"28px"}
              sx={{ cursor: "pointer" }}
            >
              <Icons name="chevronLeft" fill="#8E8D8A" width={16} height={16} />
              <BorderText text={"Back"} />
            </Box>
            <Box>
              <Typography
                sx={{ paddingY: "16px", paddingX: "28px" }}
                color="#333333"
                variant="title4"
                component={"div"}
              >
                Add new subject
              </Typography>
              <Box sx={{ paddingX: "24px" }}>
                <SearchSubjectComponent onSearchChange={handleSearchChange} />
              </Box>
            </Box>

            <Box
              marginTop={"16px"}
              paddingX={"28px"}
              height={"calc(100vh - 390px)"}
              sx={{
                overflowY: "auto", overflowX: "hidden",

              }}
            >
              {filteredSubjects.map((subject, index) => (
                <Box
                  key={index}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "8px",
                    padding: "12px",
                    borderBottom: "1px solid #D2D2D0", 
                    background: checkboxStates[subject.id] ? "#FFF8EC" : "",
                    ":hover": {
                      background: "#FFF8EC",
                    },
                  }}
                >
                  <Checkbox
                    {...label}
                    checked={checkboxStates[subject.id] || false}
                    onChange={() => handleCheckState(subject.id)}
                    sx={{
                      color: "#D2D2D0",
                      padding: "0",
                      width: "20px",
                      height: "20px",
                      "&.Mui-checked": {
                        color: "#FFA737",
                      },
                    }}
                  />
                  <Typography
                    onClick={() => handleCheckState(subject.id)}
                    sx={{
                      fontSize: "14px",
                      cursor: "pointer",
                      fontWeight: checkboxStates[subject.id] ? "600" : "400",
                      color: "#333333",
                    }}
                  >
                    {subject.sub_code} - {subject.subject}
                  </Typography>
                </Box>
              ))}
            </Box>

            <Box
              sx={{
                paddingX: "28px",
                width: "100%",
                borderTop: "1px solid #EFEFEF",
                position: "absolute",
                bottom: "0",
                paddingTop: "24px",
                background: "#FFF",
              }}
            >
              <Typography
                sx={{ fontSize: "12px", fontWeight: "500", color: "#333333" }}
                component={"div"}
              >
                {selectedCodes.length} Selected
              </Typography>

              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Box
                  sx={{
                    display: "flex",
                    gap: "4px",
                    maxWidth: "372px",
                    overflowX: "auto",
                  }}
                >
                  {selectedCodes.map((subject, index) => (
                    <Box
                      key={index}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        border: "1px solid #D2D2D0",
                        borderRadius: "4px",
                        paddingX: "8px",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "12px",
                          fontWeight: "500",
                          color: "#333333",
                          marginRight: "4px",
                        }}
                      >
                        {subject.sub_code}
                      </Typography>
                      <Box
                        sx={{ lineHeight: 0, cursor: "pointer" }}
                        onClick={() => handleRemoveSelectedSubject(subject.id)}
                      >
                        <Icons
                          width={14}
                          height={14}
                          name="close"
                          fill="#8E8D8A"
                        />
                      </Box>
                    </Box>
                  ))}
                </Box>

                <Box
                  sx={{
                    marginLeft: "auto",
                    cursor: selectedCodes.length === 0 ? "default" : "pointer",
                    opacity: selectedCodes.length === 0 ? "0.5" : "1",
                    pointerEvents: selectedCodes.length === 0 ? "none" : "auto",
                  }}
                >
                  <Button
                    loading={loading}
                    text="Add"
                    textStyle={{ color: "#333" }}
                    handleClick={handleAddSubject}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        )}
      </SubjectRightDrawer>
    </>
  );
};

export default StudentDetailsDrawer;
