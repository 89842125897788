import client from "./client";

export const getFileLibraryPaginationData = async (
  token: string,
  page: number,
  limit: number,
  status: any,
  params: object
) =>
  await client.get(
    `/filetype/viewAll?status=${status}&page=${page}&limit=${limit}`,
    {
      params: params,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
export const getEventDetails = async (
  token: string,
  eventID:string
) =>
  await client.get(
    `/files/events/${eventID}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  
export const getFileTypeCount = async (token: string) =>
  await client.get("/filetype/count", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const viewFileDetails = async (token: string, id: number) =>
  await client.get(`/filetype/file-details/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const fileStautusUpdate = async (
  payload: object,
  token: string | null
) =>
  await client.post("/filetype/status-update", payload, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const recordingTimestamp = async (
  payload: string,
  fileId: number,
  token: string | null
) =>
  await client.post(`/files/generateChapters/${fileId}`, payload, {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "text/plain",
    },
  });
