import React, { useCallback, useEffect, useRef, useState } from "react";
import { VideoSeekSlider } from "react-video-seek-slider";
import "react-video-seek-slider/styles.css";
import { Box, IconButton, Typography } from "@mui/material";
import screenfull from "screenfull";
import Select from "react-dropdown-select";
import { ExtendedHTMLVideoElement } from "react-use-pip";
import "../FileLibrary/video.css";
import OnHoverBtnsFullscreen from "./OnhoverBtnsfullscreen";
import IconBtnComponent from "./IconBtnComponent";

const options = [
  { value: "0.5", label: "0.5x" },
  { value: "0.75", label: "0.75x" },
  { value: "1", label: "1x" },
  { value: "1.25", label: "1.25x" },
  { value: "1.5", label: "1.5x" },
  { value: "1.75", label: "1.75x" },
  { value: "2", label: "2x" },
];
interface TimeCode {
  fromMs: number;
  description: string;
}

interface VideoPreviewProps {
  file?: string;
  fileName?: string;
  REACT_APP_API_URL?: string;
}

const generateTimeCodes = (): TimeCode[] => {
  const timeCodes: TimeCode[] = [
    {
      fromMs: convertToMilliseconds("00:00:00"),
      description: "Introduction",
    },
    {
      fromMs: convertToMilliseconds("00:02:10"),
      description: "Theory",
    },
    {
      fromMs: convertToMilliseconds("00:04:30"),
      description:
        "Lorem ipsum dolor sit amet consectetur vel odio at id pretium.",
    },
    {
      fromMs: convertToMilliseconds("00:07:20"),
      description: "Lorem ipsum dolor sit amet consectetur.",
    },
    {
      fromMs: convertToMilliseconds("00:10:00"),
      description: "Lorem ipsum dolor sit amet consectetur.",
    },
  ];

  return timeCodes;
};
const convertToMilliseconds = (timeString: string): number => {
  const [hours, minutes, seconds] = timeString.split(":").map(Number);
  return hours * 3600000 + minutes * 60000 + seconds * 1000;
};

const VideoPreview: React.FC<VideoPreviewProps> = ({ file, fileName }) => {
  const fileUrl = `${process.env.REACT_APP_API_URL}/${file}`;
  const player = useRef<ExtendedHTMLVideoElement>(null);
  const previewImage = useRef<string>("");
  const interval = useRef<NodeJS.Timeout | null>(null);
  const [currentTime, setCurrentTime] = useState<number>(0);
  const [progress, setProgress] = useState<number>(0);
  const [maxTime, setMaxTime] = useState<number>(0);
  const [playing, setPlaying] = useState<boolean>(false);
  const [showPlayButton, setShowPlayButton] = useState<boolean>(true);
  const playerDivRef = useRef<HTMLDivElement>(null);
  const [fullMovieTime, setFullMovieTime] = useState<string>("");
  const [selectedSpeed, setSelectedSpeed] = useState<number>(1);

  const handleTimeChange = useCallback((time: number) => {
    if (!player.current?.currentTime) {
      return;
    }

    player.current.currentTime = time / 1000;
    setCurrentTime(time);
  }, []);

  const handlePlay = () => {
    if (player.current) {
      player.current.play();
      setPlaying(true);
      setShowPlayButton(false);
      interval.current = setInterval(() => {
        setCurrentTime((player.current?.currentTime || 0) * 1000);
      }, 1000);
    }
  };

  const rewind = () => {
    if (player.current) {
      player.current.currentTime -= 15;
      setCurrentTime((player.current?.currentTime || 0) * 1000);
    }
  };

  const fastForward = () => {
    if (player.current) {
      player.current.currentTime += 15;
      setCurrentTime((player.current?.currentTime || 0) * 1000);
    }
  };

  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      switch (event.key) {
        default:
          break;
      }
    },
    [playing, handlePlay, rewind, fastForward],
  );

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleKeyDown]);

  const handlePause = () => {
    if (player.current) {
      player.current.pause();
      setPlaying(false);
      setShowPlayButton(true);
      if (interval.current) {
        clearInterval(interval.current);
      }
    }
  };
  const handleSelectChange = (
    selectedOptions: { value: string; label: string }[],
  ) => {
    if (selectedOptions.length > 0) {
      const speed = parseFloat(selectedOptions[0].value);
      handleSpeedChange(speed);
      setSelectedSpeed(speed);
    }
  };

  const handleDataLoaded = () => {
    setMaxTime((player.current?.duration || 0) * 1000);
  };

  const handleProgress = () => {
    if (player.current) {
      const buffer = player.current.buffered;

      if (buffer.length && player.current.duration) {
        let currentBuffer = 0;
        const inSeconds = player.current.currentTime;

        for (let i = 0; i < buffer.length; i++) {
          if (buffer.start(i) <= inSeconds && inSeconds <= buffer.end(i)) {
            currentBuffer = i;
            break;
          }
        }

        setProgress(buffer.end(currentBuffer) * 1000 || 0);
      }
    }
  };

  const updatePreviewImage = (hoverTime: number) => {
    if (
      !player.current ||
      !player.current.videoWidth ||
      !player.current.videoHeight
    ) {
      return;
    }

    if (!player.current.readyState || player.current.readyState < 3) {
      player.current.addEventListener("canplaythrough", () => {
        updatePreviewImage(hoverTime);
      });
      return;
    }

    const canvas = document.createElement("canvas");
    const video = player.current;

    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;

    const context = canvas.getContext("2d");

    if (context) {
      context.drawImage(
        video as HTMLVideoElement,
        0,
        0,
        canvas.width,
        canvas.height,
      );

      // Create an image element
      const image = new Image();

      // Set the image source to the canvas data URL
      image.src = canvas.toDataURL();

      // Set the preview image
      previewImage.current = image.src;
    }
  };

  const handleSpeedChange = (speed: number) => {
    setSelectedSpeed(speed);

    if (player.current) {
      player.current.playbackRate = speed;
    }
  };

  const handleGettingPreview = useCallback((hoverTime: number): string => {
    updatePreviewImage(hoverTime);
    return previewImage.current;
  }, []);

  useEffect(() => {
    if (!player.current) {
      return;
    }

    player.current.addEventListener("loadeddata", handleDataLoaded);
    player.current.addEventListener("progress", handleProgress);

    return () => {
      if (player.current) {
        player.current.removeEventListener("loadeddata", handleDataLoaded);
        player.current.removeEventListener("progress", handleProgress);
      }
    };
  }, [handleDataLoaded, handleProgress]);

  useEffect(() => {
    setFullMovieTime(formatTime(maxTime / 1000));
  }, [maxTime]);

  const handleFullScreenMode = () => {
    if (playerDivRef.current) {
      screenfull.toggle(playerDivRef.current);
    }
  };

  const formatTime = (timeInSeconds: number): string => {
    const hours = Math.floor(timeInSeconds / 3600);
    const minutes = Math.floor((timeInSeconds % 3600) / 60);
    const seconds = Math.floor(timeInSeconds % 60);

    const formattedHours = hours.toString().padStart(2, "0");
    const formattedMinutes = minutes.toString().padStart(2, "0");
    const formattedSeconds = seconds.toString().padStart(2, "0");

    return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
  };

  return (
    <Box>
      <Box
        id="videoSec"
        sx={{ display: { md: "block", sm: "none", xs: "none" } }}
      >
        <Box
          sx={{
            position: "relative !important",
            height: screenfull && !screenfull.isFullscreen ? "350px" : "900px",
            borderRadius: !screenfull.isFullscreen ? "0px" : "0px",
            background:
              screenfull && !screenfull.isFullscreen ? "#000" : "#2B3258",
          }}
          ref={playerDivRef}
        >
          {screenfull && !screenfull.isFullscreen ? null : (
            <Box sx={{ padding: "32px 45px" }}>
              <Typography
                style={{
                  color: "#FFFFFF",
                  fontWeight: "600",
                  fontSize: "16px",
                  lineHeight: "24px",
                }}
              >
                {fileName && fileName}
              </Typography>
            </Box>
          )}
          <video
            ref={player as React.MutableRefObject<HTMLVideoElement>}
            style={{
              width: "100%",
              height: !screenfull.isFullscreen ? "100%" : "calc(100vh - 200px)",
            }}
            crossOrigin="anonymous"
          >
            <source src={fileUrl} type="video/mp4" />
          </video>
          {screenfull && !screenfull.isFullscreen ? (
            showPlayButton ? (
              <div
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  zIndex: 2,
                }}
              >
                <IconButton onClick={handlePlay}>
                  <IconBtnComponent
                    name="playIconCircle"
                    width={130}
                    height={130}
                  />
                </IconButton>
              </div>
            ) : (
              <Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    alignContent: "center",
                    flexDirection: "row",
                    width: "100%",
                    position: "absolute",
                    bottom: "4px",
                    justifyContent: "space-between",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "row",
                      marginLeft: "16px",
                    }}
                  >
                    {playing ? (
                      <IconBtnComponent
                        name="pauseSmallIcon"
                        clickButton={handlePause}
                        width={24}
                        height={24}
                      />
                    ) : (
                      <IconBtnComponent
                        name="playSmallIcon"
                        clickButton={handlePlay}
                        width={24}
                        height={24}
                      />
                    )}
                   
                    <IconBtnComponent
                      name="rePlay15"
                      clickButton={rewind}
                      width={24}
                      height={24}
                    />
                    <IconBtnComponent
                      name="forward15"
                      clickButton={fastForward}
                      width={24}
                      height={24}
                    />
                    
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "row",
                      width: "40%",
                    }}
                  >
                    <VideoSeekSlider
                      max={maxTime}
                      currentTime={currentTime}
                      bufferTime={progress}
                      onChange={handleTimeChange}
                      limitTimeTooltipBySides={true}
                      secondsPrefix="00:"
                      minutesPrefix="0:"
                      getPreviewScreenUrl={handleGettingPreview}
                      timeCodes={generateTimeCodes()}
                    />
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "row",
                      marginRight: "16px",
                      justifyContent: "end",
                    }}
                  >
                    <Typography style={{ color: "white" }}>
                      {fullMovieTime}
                    </Typography>

                    <IconBtnComponent
                      name="expandVideoFull"
                      clickButton={handleFullScreenMode}
                      width={24}
                      height={24}
                    />
                  </Box>
                </Box>
              </Box>
            )
          ) : (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                alignContent: "center",
                flexDirection: "row",
                width: "100%",
                position: "absolute",
                bottom: "16px",
                justifyContent: "space-between",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "row",
                  marginLeft: "45px",
                }}
              >
                {playing ? (
                  <IconBtnComponent
                    name="pauseSmallIcon"
                    clickButton={handlePause}
                    width={24}
                    height={24}
                  />
                ) : (
                  <IconBtnComponent
                    name="playSmallIcon"
                    clickButton={handlePlay}
                    width={24}
                    height={24}
                  />
                )}
                <div className="MiniPrevBtn" style={{ position: "relative" }}>
                  <IconBtnComponent
                    name="skipPrevious"
                    clickButton={() => console.log("Prev Video")}
                    width={24}
                    height={24}
                  />
                  <div
                    className="ShowPreviewContent"
                    style={{ display: "none" }}
                  >
                    <OnHoverBtnsFullscreen
                      type={"Prev:"}
                      title={"Video name here - lorem ipsum oralam dulos"}
                    />
                  </div>
                </div>
                <div className="MiniNextBtn" style={{ position: "relative" }}>
                  <IconBtnComponent
                    name="skipNext"
                    clickButton={() => console.log("Next Video")}
                    width={24}
                    height={24}
                  />
                  <div className="ShowNextContent" style={{ display: "none" }}>
                    <OnHoverBtnsFullscreen
                      type={"Next:"}
                      title={"Video name here - lorem ipsum oralam dulos"}
                    />
                  </div>
                </div>

                <IconBtnComponent
                  name="rePlay15"
                  clickButton={rewind}
                  width={24}
                  height={24}
                />
                <IconBtnComponent
                  name="forward15"
                  clickButton={fastForward}
                  width={24}
                  height={24}
                />
                <Select
                  options={options}
                  values={[
                    {
                      value: selectedSpeed.toString(),
                      label: `${selectedSpeed}x`,
                    },
                  ]}
                  onChange={handleSelectChange}
                  style={{ marginBottom: "1px" }}
                  dropdownPosition={"top"}
                  searchable={false}
                  className="VideoSpeedSelect"
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "row",
                  width: "55%",
                }}
              >
                <VideoSeekSlider
                  max={maxTime}
                  currentTime={currentTime}
                  bufferTime={progress}
                  onChange={handleTimeChange}
                  limitTimeTooltipBySides={true}
                  secondsPrefix="00:"
                  minutesPrefix="0:"
                  getPreviewScreenUrl={handleGettingPreview}
                  timeCodes={generateTimeCodes()}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "row",
                  marginRight: "45px",
                  justifyContent: "end",
                }}
              >
                <Typography style={{ color: "white" }}>
                  {fullMovieTime}
                </Typography>
                <IconBtnComponent
                  name="expandVideoFull"
                  clickButton={handleFullScreenMode}
                  width={24}
                  height={24}
                />
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default VideoPreview;
