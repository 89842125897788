import React from "react";
import {
  Box,
  Typography,
  Link,
  FormControl,
  MenuItem,
  TextField,
} from "@mui/material";
import Icons from "../Icons";
import { timeValue } from "../../utils/uploadTime";
import UploadCalendarComponent from "./Calender";
import UploadFileScreen from "./UploadFile";
import CircularProgressScreen from "./CircularProgress";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import ToastMessage from "../ToastMessage";

type BatchFile = {
  name: string;
  deleted?: boolean;
  type: string;
  size: number;
  lastModified: number;
  edited?: boolean;
  status?: { type: "success" | "failure"; message: string };
  isValid?: boolean;
};

type File1 = {
  name: string;
  deleted?: boolean;
  type: string;
  size: number;
  lastModified: number;
  edited?: boolean;
  status?: { type: "success" | "failure"; message: string };
  isValid?: boolean;
};

type Batch1 = {
  files: File1[];
  filesAdd?: File1[];
};

type File = {
  name: string;
  deleted?: boolean;
  type: string;
  size: number;
  lastModified: number;
  edited?: boolean;
  status?: { type: "success" | "failure"; message: string };
  isValid?: boolean;
};

type Batch = {
  files: File[];
  isPublished: boolean;
  filesAdd?: File[];
  addedToBatch?: boolean;
  uploadStatus: string[];
};
interface Toast {
  type: string;
  message: string;
}
interface FilesForUploadProps {
  showLoader: boolean;
  index: number;
  uploadSpeed:any;
  isCalendarOpen: boolean[];
  batchData: BatchFile[];
  uploadProgress: number;
  editingIndex: Record<number, number | null>;
  editedFileNames: Record<
    number,
    Record<number, { edited: string; original: string }>
  >;
  setEditedFileNames: React.Dispatch<
    React.SetStateAction<
      Record<number, Record<number, { edited: string; original: string }>>
    >
  >;
  fileUpload?: boolean;
  batchesName?: Batch1[];
  handleSaveFileName: (batchIndex: number, fileIndex: number) => void;
  replacedFiles: Record<number, boolean>;
  handleEditFileName: (batchIndex: number, fileIndex: number) => void;
  handleDeleteFile: (batchIndex: number, fileIndex: number) => void;
  handleUndoDelete: (batchIndex: number, fileIndex: number) => void;
  setAddMoreFile: React.Dispatch<React.SetStateAction<boolean>>;
  handleCancel: () => void;
  setTimestamp?: React.Dispatch<React.SetStateAction<number | null>>;
  currentBatchIndex: number;
  addMoreFile: boolean;
  currentBatch: Batch;
  setIsCalendarOpen: React.Dispatch<React.SetStateAction<boolean[]>>;
  setUploadingFile: (newState: boolean) => void;
  setBatches: React.Dispatch<React.SetStateAction<Batch[]>>;
  batchStates: Array<{
    selectedStartDate: Date | null;
    fromDateFocused: boolean;
    time: string;
  }>;
  handleFileUpload: (batchIndex: number) => void;
  setPublishedDateTime: React.Dispatch<React.SetStateAction<string[]>>;
  invalidType: string|null;
  isDragging: boolean;
  handleDragEnter: (event: React.DragEvent<HTMLDivElement>) => void;
  handleDragLeave: () => void;
  handleDrop: (e: React.DragEvent<HTMLDivElement>) => Promise<void>;
  handleFileClick: (id:string) => void;
  toast: Toast | null;
  setToast: React.Dispatch<React.SetStateAction<Toast | null>>;
  handleFileChange: (e: React.ChangeEvent<HTMLInputElement>) => Promise<void>;
  setBatchStates: React.Dispatch<
    React.SetStateAction<
      Array<{
        selectedStartDate: Date | null;
        fromDateFocused: boolean;
        time: string;
      }>
    >
  >;
}

const FilesForUpload: React.FC<FilesForUploadProps> = ({
  showLoader,
  index,
  isCalendarOpen,
  batchData,
  editingIndex,
  editedFileNames,
  setTimestamp,
  setEditedFileNames,
  handleSaveFileName,
  replacedFiles,
  handleEditFileName,
  handleDeleteFile,
  handleUndoDelete,
  currentBatchIndex,
  currentBatch,
  setAddMoreFile,
  fileUpload,
  batchesName,
  addMoreFile,
  uploadProgress,
  setIsCalendarOpen,
  setUploadingFile,
  setBatches,
  setBatchStates,
  batchStates,
  handleFileUpload,
  setPublishedDateTime,
  invalidType,
  isDragging,
  handleDragEnter,
  handleDragLeave,
  handleDrop,
  handleFileClick,
  handleFileChange,
  setToast,
  toast,
}) => {
  const handleTimeChange = (event: SelectChangeEvent, index: number) => {
    const newTime = event.target.value;
    setBatchStates((prevStates) => {
      const newStates = [...prevStates];
      newStates[index] = { ...prevStates[index], time: newTime };
      return newStates;
    });
  };

  const handleFromDateFocusedChange = (
    value: React.SetStateAction<boolean>,
    index: number,
  ) => {
    setBatchStates((prevStates) => {
      const newStates = [...prevStates];
      newStates[index] = {
        ...prevStates[index],
        fromDateFocused:
          typeof value === "function"
            ? value(prevStates[index].fromDateFocused)
            : value,
      };
      return newStates;
    });
  };

  const handleDateChange = (dates: Date | null, index: number) => {
    setBatchStates((prevStates) => {
      const newStates = [...prevStates];
      newStates[index] = {
        ...prevStates[index],
        selectedStartDate: dates,
        fromDateFocused: false,
      };
      return newStates;
    });
  };

  const formatDateTime = (date: Date | null, time: string) => {
    if (!date) {
      return "Invalid Date";
    }
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const formattedDate = `${
      monthNames[date.getMonth()]
    } ${date.getDate()} ${date.getFullYear()}`;
    const formattedTime = time;
    return `Publish ${formattedDate} - ${formattedTime}`;
  };

  return (
    <Box
      sx={{
        background: "#fff",
        padding: "12px",
        borderRadius: "16px",
      }}
    >
      {toast && (
        <ToastMessage
          type={toast.type as "success" | "error"}
          message={toast.message}
          onClose={() => setToast(null)}
          style={{
            position: "absolute",
            top: "-3%",
          }}
        />
      )}
      <Typography
        sx={{
          fontSize: "12px",
          fontWeight: "500",
          color: "#333",
          marginBottom: "3px",
        }}
      >
        Batch {index + 1}
      </Typography>
     
      {!isCalendarOpen[index] ? (
        <>
          <Typography
            sx={{
              fontSize: "16px",
              fontWeight: "600",
              color: "#333",
              marginBottom: "10px",
            }}
          >
            {batchData.length} Files for upload
          </Typography>
          <Box>
            {batchData.map((file, fileIndex) => (
              <Box
                key={fileIndex}
                sx={{
                  borderBottom: "1px solid #EFEFEF",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "14px",
                    paddingY: "8px",
                    fontWeight: "400",
                    color: "#333",
                    display: "flex",
                    alignItems: "center",
                    maxWidth: '496px',
                    width: '100%',
                  }}
                >
                  {" "}
                  <Icons
                    name={batchData && batchData[fileIndex]?.type?.includes("pdf")?"textFile":"recordingShow"}
                    fill={file.isValid?"#8E8D8A":"#FF0000"}
                    width={18}
                    height={18}
                    style={{
                      marginRight: "8px",
                      opacity: file.deleted ? "0.5" : "1",
                    }}
                  />{" "}
                  {editingIndex[index] === fileIndex ? (
                    <TextField
                      id="editFile"
                      variant="outlined"
                      value={
                        editedFileNames[index]?.[fileIndex]?.edited !==
                        undefined
                          ? editedFileNames[index]?.[fileIndex]?.edited
                          : batchData && batchData[fileIndex]?.name
                      }
                      onChange={(e) => {
                        const editedValue = e.target.value;
                        setEditedFileNames((prev) => ({
                          ...prev,
                          [index]: {
                            ...(prev[index] || {}),
                            [fileIndex]: {
                              edited: editedValue,
                              original:
                                editedValue !== "" &&
                                batchData &&
                                batchData[fileIndex]
                                  ? batchData[fileIndex].name
                                  : "",
                            },
                          },
                        }));
                      }}
                      sx={{
                        width: '100%',
                        ".Mui-focused": {
                          background: "#FFF8EC",
                          ".MuiInputBase-input": {
                            color: "#333",
                            fontSize: "14px",
                            fontWeight: "400",
                          },
                          ".css-9sors8-MuiButtonBase-root-MuiIconButton-root:hover":
                            {
                              background: "transparent",
                            },
                          ".css-1yq5fb3-MuiButtonBase-root-MuiIconButton-root:hover":
                            {
                              background: "transparent",
                            },
                          ".css-14sxf79-MuiInputBase-input-MuiOutlinedInput-input":
                            {
                              color: "#333",
                              fontSize: "14px",
                              fontWeight: "400",
                            },
                        },
                        ".MuiInputBase-root": {
                          width: "100%",
                          maxHeight: "44px",
                          borderRadius: "8px",
                          marginRight: "8px",
                          maxWidth: '460px',
                          "&:focus-visible": {
                            outline: "none !important",
                          },
                        },
                        "&.MuiFormControl-root .MuiInputBase-root .css-19z3763-MuiInputBase-input-MuiOutlinedInput-input":
                          {
                            fontSize: "14px",
                            fontWeight: "400",
                            color: "#333",
                            padding: "12px 16px",
                          },
                        "&.MuiFormControl-root .MuiInputBase-root .css-1d3z3hw-MuiOutlinedInput-notchedOutline":
                          {
                            border: "1px solid #FFA737",
                          },
                        ".css-9sors8-MuiButtonBase-root-MuiIconButton-root": {
                          padding: "0px",
                          marginLeft: "-7px",
                          pointerEvents: "none",
                        },
                      }}
                    />
                  ) : (
                    <Box
                      sx={{
                        fontSize: "14px",
                        fontWeight: "400",
                        color: file.isValid?"#333":"red",
                        opacity: file.deleted ? "0.5" : "1",
                        maxWidth:'266px !important',
                      }}
                    >
                      <>
                        {editedFileNames[index] &&
                          editedFileNames[index][fileIndex] &&
                          editedFileNames[index][fileIndex]?.edited !==
                            undefined && (
                            editedFileNames[index]?.[fileIndex]?.edited !== editedFileNames[index]?.[fileIndex]?.original ? (
                              <Typography sx={{ fontSize: "14px" }}>
                                {editedFileNames[index][fileIndex]?.edited}
                              </Typography>
                            ) : null
                          )}
                        <Typography
                          sx={{
                            fontSize: "14px",
                              textDecoration:
                                editedFileNames[index] &&
                                  editedFileNames[index][fileIndex] &&
                                  editedFileNames[index]?.[fileIndex]?.edited !== editedFileNames[index]?.[fileIndex]?.original
                                  ? "line-through"
                                  : "",
                            opacity:
                              editedFileNames[index] &&
                                editedFileNames[index][fileIndex] &&
                                editedFileNames[index]?.[fileIndex]?.edited !== editedFileNames[index]?.[fileIndex]?.original
                                ? "0.5"
                                : "1",
                          }}
                        >
                          {fileUpload ? (
                            <>
                              {batchesName &&
                                batchesName[index]?.files &&
                                batchesName &&
                                batchesName[index].files[fileIndex]?.name}
                            </>
                          ) : (
                            <>
                              {editedFileNames[index]?.[fileIndex]?.original ||
                                (batchData && batchData[fileIndex]?.name)}
                            </>
                          )}
                        </Typography>
                      </>
                    </Box>
                  )}
                </Typography>

                {editingIndex[index] === fileIndex && (
                  <Link
                    onClick={() => handleSaveFileName(index, fileIndex)}
                    underline="none"
                    sx={{
                      paddingX: "20px",
                      bgcolor: "#FFA737",
                      borderRadius: "8px",
                      height: "44px",
                    }}
                    component={"button"}
                  >
                    <Typography color={"#333"} variant="cbutton">
                      Save
                    </Typography>
                  </Link>
                )}

                <Typography
                  sx={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {replacedFiles[fileIndex] && editingIndex[index] !== fileIndex && (
                    <Typography
                      sx={{
                        borderRadius: "4px",
                        fontSize: "12px",
                        fontWeight: "500",
                        paddingLeft: "4px",
                        paddingRight: "8px",
                        background: "#EBECF1",
                        display: "flex",
                        alignItems: "center",
                        marginRight: "8px",
                        height: "24px",
                        position: 'absolute',
                        right: '100px',
                      }}
                    >
                      <Icons
                        name="replace"
                        fill="#3B4478"
                        width={16}
                        height={16}
                        style={{
                          marginRight: "4px",
                        }}
                      />
                      New version
                    </Typography>
                  )}
                  {editingIndex[index] !== fileIndex && !file.deleted && (
                    <Typography
                      sx={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Icons
                        name="edit"
                        fill="#8E8D8A"
                        width={20}
                        height={20}
                        style={{
                          marginRight: "15px",
                          cursor: "pointer",
                        }}
                        onClick={() => handleEditFileName(index, fileIndex)}
                      />
                      <Icons
                        name="trash"
                        fill="#8E8D8A"
                        width={20}
                        height={20}
                        style={{
                          cursor: "pointer",
                        }}
                        onClick={() => handleDeleteFile(index, fileIndex)}
                      />
                    </Typography>
                  )}
                </Typography>

                {file.deleted && (
                  <Typography
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Icons name="undo" fill="#3B4478" width={16} height={16} />
                    <Link
                      onClick={() => handleUndoDelete(index, fileIndex)}
                      underline="none"
                      sx={{
                        borderBottom: "2px solid #FFA737",
                        height: "24px",
                        alignSelf: "center",
                      }}
                      component={"button"}
                    >
                      <Typography color={"#333"} variant="cbutton">
                        Undo
                      </Typography>
                    </Link>
                  </Typography>
                )}
              </Box>
            ))}
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "12px",
            }}
          >
            <Link
              onClick={() => {
                setAddMoreFile(!addMoreFile);
              }}
              underline="none"
              sx={{ display: "flex", alignItems: "center" }}
              component={"button"}
            >
              <Typography
                color={"#333"}
                variant="cbutton"
                sx={{
                  borderBottom: "2px solid #FFA737",
                  height: "21px",
                  alignSelf: "center",
                }}
              >
                Add Files{" "}
              </Typography>
              {addMoreFile ? (
                <Icons name="chevronUp" fill="#8E8D8A" width={20} height={20} />
              ) : (
                <Icons
                  name="chevronDown"
                  fill="#8E8D8A"
                  width={20}
                  height={20}
                />
              )}
            </Link>
            <Box>
              <Link
                onClick={() => {
                  window.location.reload()
                }}
                underline="none"
                sx={{
                  borderBottom: "2px solid #FFA737",
                  marginRight: "12px",
                  height: "24px",
                  alignSelf: "center",
                }}
                component={"button"}
              >
                <Typography color={"#333"} variant="cbutton">
                  Cancel
                </Typography>
              </Link>
              <Link
                onClick={() => {
                  handleFromDateFocusedChange(true, index);
                  setIsCalendarOpen((prev) => {
                    const newState = [...prev];
                    newState[index] = true;
                    return newState;
                  });
                }}
                underline="none"
                sx={{
                  paddingX: "20px",
                  borderRadius: "8px",
                  border: "1px solid #FFA737",
                  marginRight: "12px",
                  height: "44px",
                  opacity: batchData.every((file) => file.deleted)
                    ? "0.5"
                    : "1",
                }}
                component={"button"}
                disabled={batchData.every((file) => file.deleted)}
              >
                <Typography color={"#333"} variant="cbutton">
                  Upload & Schedule
                </Typography>
              </Link>
              <Link
                onClick={() => {
                  setBatches((prevBatches) => {
                    const newBatches = [...prevBatches];
                    if (
                      currentBatchIndex >= 0 &&
                      currentBatchIndex < newBatches.length
                    ) {
                      newBatches[currentBatchIndex] = {
                        ...currentBatch,
                        isPublished: true,
                      };
                    }
                    return newBatches;
                  });
                  handleFileUpload(index);
                }}
                underline="none"
                sx={{
                  paddingX: "20px",
                  bgcolor: "#FFA737",
                  borderRadius: "8px",
                  height: "44px",
                  opacity: batchData.every((file) => file.deleted)
                    ? "0.5"
                    : "1",
                }}
                component={"button"}
                disabled={batchData.every((file) => file.deleted)}
              >
                <Typography color={"#333"} variant="cbutton">
                  Upload & Publish
                </Typography>
              </Link>
            </Box>
          </Box>
          {addMoreFile && (
            <>
              {!showLoader ? (
                <UploadFileScreen
                  invalidType={invalidType}
                  isDragging={isDragging}
                  showLoader={showLoader}
                  handleDragEnter={handleDragEnter}
                  handleDragLeave={handleDragLeave}
                  handleDrop={handleDrop}
                  handleFileClick={handleFileClick}
                  handleFileChange={handleFileChange}
                  addFileTag={true}
                />
              ) : (
                <CircularProgressScreen
                  showLoader={showLoader}
                  uploadProgress={uploadProgress}
                  addFileTag={true}
                />
              )}
            </>
          )}
        </>
      ) : (
        <Box
          sx={{
            marginTop: "8px",
          }}
        >
          <Link
            onClick={() => {
              setIsCalendarOpen((prev) => {
                const newState = [...prev];
                newState[index] = false;
                return newState;
              });
            }}
            underline="none"
            sx={{
              display: "flex",
              alignItems: "center",
              height: "21px",
              alignSelf: "center",
              marginLeft: "-7px",
              marginBottom: "10px",
            }}
            component={"button"}
          >
            <Icons name="chevronLeft" fill="#8E8D8A" width={18} height={18} />
            <Typography
              color={"#333"}
              variant="cbutton"
              sx={{
                borderBottom: "2px solid #FFA737",
                height: "24px",
                alignSelf: "center",
              }}
            >
              Back
            </Typography>
          </Link>
          <Typography
            sx={{
              fontSize: "16px",
              fontWeight: "600",
            }}
          >
            Schedule files after upload
          </Typography>
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: "400",
              marginBottom: "10px",
            }}
          >
            Select date and time for scheduling
          </Typography>
          <Box>
            <UploadCalendarComponent
              selectedStartDate={batchStates[index].selectedStartDate}
              handleDateChange={(dates) => handleDateChange(dates, index)}
              fromDateFocused={batchStates[index].fromDateFocused}
              setFromDateFocused={(value) =>
                handleFromDateFocusedChange(value, index)
              }
            />
          </Box>
          <Typography
            sx={{
              marginBottom: "10px",
              marginTop: "4px",
            }}
          >
            <label
              style={{
                display: "block",
                fontSize: "12px",
                fontWeight: "500",
                color: "#333",
              }}
            >
              Time
            </label>
            <FormControl
              sx={{
                "&.MuiFormControl-root": {
                  margin: "0px",
                  width: "100%",
                },
                "&.MuiFormControl-root .MuiInputBase-root": {
                  borderRadius: "8px",
                },
                "&.MuiFormControl-root .MuiSelect-select": {
                  padding: "12px 16px",
                },
              }}
            >
              <Select
                value={batchStates[index].time}
                onChange={(event) => handleTimeChange(event, index)}
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
                sx={{
                  ".MuiSvgIcon-root": {
                    color: "#8E8D8A !important",
                    fill: "#8E8D8A !important",
                  },
                }}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {timeValue.map((tm: { value: string; time: string }, index) => (
                  <MenuItem key={index} value={tm.value}>
                    {tm.time}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "end",
            }}
          >
            <Link
              onClick={() => {
                if (
                  batchStates[index].selectedStartDate &&
                  batchStates[index].time !== ""
                ) {
                  setBatches((prevBatches) => {
                    const newBatches = [...prevBatches];
                    if (
                      currentBatchIndex >= 0 &&
                      currentBatchIndex < newBatches.length
                    ) {
                      newBatches[currentBatchIndex] = {
                        ...currentBatch,
                        isPublished: true,
                      };
                    }
                    return newBatches;
                  });
                  handleFileUpload(index);
                  setUploadingFile(true);
                  setIsCalendarOpen((prev) => {
                    const newState = [...prev];
                    newState[index] = false;
                    return newState;
                  });
                  const formattedDateTime = formatDateTime(
                    batchStates[index].selectedStartDate,
                    batchStates[index].time,
                  );
                  setPublishedDateTime((prevPublishedDateTime) => {
                    const newPublishedDateTime = [...prevPublishedDateTime];
                    newPublishedDateTime[index] = formattedDateTime;
                    return newPublishedDateTime;
                  });
                  const dateTimeParts = `${batchStates[index].selectedStartDate} ${batchStates[index].time}`;
                  const dateObject = new Date(dateTimeParts);
                  const timestampObject = dateObject.getTime();
                  setTimestamp && setTimestamp(timestampObject);
                }
              }}
              underline="none"
              sx={{
                paddingX: "20px",
                bgcolor: "#FFA737",
                opacity:
                  batchStates[index].selectedStartDate &&
                  batchStates[index].time !== ""
                    ? "1"
                    : "0.5",
                borderRadius: "8px",
                height: "44px",
              }}
              component={"button"}
            >
              <Typography color={"#333"} variant="cbutton">
                Confirm & upload
              </Typography>
            </Link>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default FilesForUpload;
