import React, { useState, MouseEvent } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Popover from "@mui/material/Popover";
import Checkbox from "@mui/material/Checkbox";
import IconComponent from "../Icons";
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

type GetStudent = {
  campus_name?: string;
  subject_name?: string;
  enrolment_status?: string;
  visit_by?: string;
  global_search_keyword?: string;
  page?: number;
  limit?: number;
};
interface enrlOption {
  label: string;
}

interface Option {
  sub_code: string;
  id: number;
}

interface campOption {
  campus_name: string;
  id: number;
}
interface FilterComponentProps {
  setFilters: React.Dispatch<React.SetStateAction<GetStudent>>;
  campusData: campOption[];
  subjectData: Option[];
}

interface CustomCheckboxProps extends SvgIconProps {
  checked: boolean;
}

const EnrolmentOptions: enrlOption[] = [
  { label: "Enrolled" },
  { label: "Unenrolled" },
  { label: "Trialing" },
  { label: "Requires invoice" },
];

const CustomCheckbox: React.FC<CustomCheckboxProps> = ({
  checked,
  ...props
}) => (
  <SvgIcon {...props}>
    {checked ? (
      <>
        <rect width="20" height="20" rx="6" fill="#FFA737" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.7535 5.20711C16.0605 5.50374 16.0839 6.00965 15.8058 6.3371L9.01173 14.3371C8.86955 14.5045 8.66765 14.6 8.45588 14.6C8.24412 14.6 8.04222 14.5045 7.90004 14.3371L4.19416 9.97346C3.91607 9.64601 3.9395 9.1401 4.24648 8.84347C4.55346 8.54684 5.02776 8.57182 5.30585 8.89927L8.45588 12.6084L14.6942 5.26291C14.9722 4.93546 15.4465 4.91048 15.7535 5.20711Z"
          fill="#333333"
        />
      </>
    ) : (
      <rect
        x="0.5"
        y="0.5"
        width="19"
        height="19"
        rx="5.5"
        fill="white"
        stroke="#D2D2D0"
      />
    )}
  </SvgIcon>
);

const FilteredComponentDD: React.FC<FilterComponentProps> = ({
  setFilters,
  campusData,
  subjectData,
}) => {
  const [anchorEl1, setAnchorEl1] = useState<null | HTMLElement>(null);
  const [selectedOptions1, setSelectedOptions1] = useState<string[]>([]);
  const [anchorEl2, setAnchorEl2] = useState<null | HTMLElement>(null);
  const [selectedOptions2, setSelectedOptions2] = useState<string[]>([]);
  const [anchorEl3, setAnchorEl3] = useState<null | HTMLElement>(null);
  const [selectedOptions3, setSelectedOptions3] = useState<string[]>([]);

  const handleClick1 = (event: MouseEvent<HTMLDivElement>) => {
    setAnchorEl1(event.currentTarget);
  };

  const handleClose1 = () => {
    setFilters((prevFilters) => {
      const updatedFilters = { ...prevFilters };
      if (selectedOptions1.length > 0) {
        updatedFilters.subject_name = selectedOptions1
          .map((option) => encodeURIComponent(option))
          .join(",");
      } else {
        delete updatedFilters.subject_name;
      }
      return updatedFilters;
    });
    setAnchorEl1(null);
  };

  const handleOptionClick1 = (optionLabel: string) => {
    if (selectedOptions1.includes(optionLabel)) {
      setSelectedOptions1(
        selectedOptions1.filter((item) => item !== optionLabel)
      );
    } else {
      setSelectedOptions1([...selectedOptions1, optionLabel]);
    }
  };

  const handleClearAll1 = () => {
    setSelectedOptions1([]);
  };

  const handleClick2 = (event: MouseEvent<HTMLDivElement>) => {
    setAnchorEl2(event.currentTarget);
  };

  const handleClose2 = () => {
    setFilters((prevFilters) => {
      const updatedFilters = { ...prevFilters };
      if (selectedOptions2.length > 0) {
        updatedFilters.campus_name = selectedOptions2
          .map((option) => encodeURIComponent(option))
          .join(",");
      } else {
        delete updatedFilters.campus_name;
      }
      return updatedFilters;
    });
    setAnchorEl2(null);
  };

  const handleOptionClick2 = (optionLabel: string) => {
    if (selectedOptions2.includes(optionLabel)) {
      setSelectedOptions2(
        selectedOptions2.filter((item) => item !== optionLabel)
      );
    } else {
      setSelectedOptions2([...selectedOptions2, optionLabel]);
    }
  };

  const handleClearAll2 = () => {
    setSelectedOptions2([]);
  };

  const handleClick3 = (event: MouseEvent<HTMLDivElement>) => {
    setAnchorEl3(event.currentTarget);
  };

  const handleClose3 = () => {
    setFilters((prevFilters) => {
      const updatedFilters = { ...prevFilters };
      if (selectedOptions3.length > 0) {
        updatedFilters.enrolment_status = selectedOptions3
          .map((option) => encodeURIComponent(option))
          .join(",");
      } else {
        delete updatedFilters.enrolment_status;
      }
      return updatedFilters;
    });
    setAnchorEl3(null);
  };

  const handleOptionClick3 = (optionLabel: string) => {
    if (selectedOptions3.includes(optionLabel)) {
      setSelectedOptions3(
        selectedOptions3.filter((item) => item !== optionLabel)
      );
    } else {
      setSelectedOptions3([...selectedOptions3, optionLabel]);
    }
  };

  const handleClearAll3 = () => {
    setSelectedOptions3([]);
  };

  const handleClearAll = () => {
    setSelectedOptions1([]);
    setSelectedOptions2([]);
    setSelectedOptions3([]);
    setFilters((prevFilters) => ({
      ...prevFilters,
      subject_name: "",
      campus_name: "",
      enrolment_status: "",
    }));
  };

  const open1 = Boolean(anchorEl1);
  const open2 = Boolean(anchorEl2);
  const open3 = Boolean(anchorEl3);
  const id1 = open1 ? "filter-popover1" : undefined;
  const id2 = open2 ? "filter-popover2" : undefined;
  const id3 = open3 ? "filter-popover3" : undefined;

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
      }}
    >
      <Box
        onClick={handleClick1}
        sx={{
          width: "110px",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          borderRadius: "8px",
          border:
            anchorEl1 !== null ? "1px solid #FFA737" : "1px solid #D2D2D0",
          padding: "4.5px 12px 4.5px 12px",
          fontSize: "14px",
          fontWeight: "400",
          marginRight: "8px",
          color: "#696969",
          cursor: "pointer",
          backgroundColor:
            anchorEl1 !== null
              ? "#FFF8EC"
              : selectedOptions1.length > 0
                ? "#F4F6FF"
                : "transparent",
          "&:hover": {
            backgroundColor: selectedOptions1.length > 0 ? "" : "#ffa7371f",
          },
        }}
      >
        Subject {selectedOptions1.length > 0 && `(${selectedOptions1.length})`}
        <Box
          sx={{
            marginTop: "-3px",
          }}
        >
          <IconComponent name="userArrowDown" fill={"#8E8D8A"} />
        </Box>
      </Box>

      <Popover
        id={id1}
        open={open1}
        anchorEl={anchorEl1}
        onClose={handleClose1}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        sx={{
          ".MuiPaper-root": {
            minWidth: "165px",
            border: "1px solid #D2D2D0",
            maxHeight: "318px",
            top: "406px",
          },
          ".MuiButtonBase-root": {
            padding: "0px !important",
            marginRight: "8px",
            marginTop: "3px",
          },
          ".css-3bmhjh-MuiPaper-root-MuiPopover-paper": {
            width: "154px",
            border: "1px solid #D2D2D0",
            borderRadius: "8px",
            overflowY: "scroll",
          },
          ".css-1s8ndmz": {
            padding: "8px 16px 8px 12px",
            borderBottom: "1px solid #D2D2D0",
            cursor: "pointer",
            "&:hover": {
              backgroundColor: "#ffa7371f",
            },
          },
        }}
      >
        <Box>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              background: "#F4F6FF",
              padding: "12px 16px 12px 12px",
            }}
          >
            <Typography
              sx={{
                fontSize: "12px",
                fontWeight: "500",
                color: "#696969",
              }}
            >
              {selectedOptions1.length > 0
                ? `${selectedOptions1.length} Selected`
                : "0 Selected"}
            </Typography>
            {selectedOptions1.length > 0 && (
              <button
                style={{
                  padding: "0px",
                  background: "transparent",
                  border: "none",
                  fontSize: "12px",
                  fontWeight: "600",
                  color: "#333",
                  borderBottom: "2px solid #FFA737",
                  cursor: "pointer",
                }}
                onClick={handleClearAll1}
              >
                Clear All
              </button>
            )}
          </div>

          {subjectData.map((option: Option) => (
            <Box
              key={option.id}
              sx={{
                ".MuiBox-root": {
                  padding: "12px 12px 16px 12px",
                },
              }}
              style={{
                background: selectedOptions1.includes(option.sub_code)
                  ? "#FFF8EC"
                  : "",
              }}
              onClick={() => handleOptionClick1(option.sub_code)}
            >
              <Checkbox
                checked={selectedOptions1.includes(option.sub_code)}
                onChange={() => handleOptionClick1(option.sub_code)}
                icon={<CustomCheckbox checked={false} />}
                checkedIcon={
                  <CustomCheckbox checked style={{ fill: "##333333" }} />
                }
              />
              <span
                style={{
                  fontSize: "14px",
                  fontWeight: "400",
                  color: "#333",
                  textTransform: "capitalize",
                }}
              >
                {option.sub_code}
              </span>
            </Box>
          ))}
        </Box>
      </Popover>

      <Box
        onClick={handleClick2}
        sx={{
          width: "150px",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          borderRadius: "8px",
          border:
            anchorEl2 !== null ? "1px solid #FFA737" : "1px solid #D2D2D0",
          padding: "4.5px 12px 4.5px 12px",
          fontSize: "14px",
          fontWeight: "400",
          marginRight: "8px",
          color: "#696969",
          cursor: "pointer",
          backgroundColor:
            anchorEl2 !== null
              ? "#FFF8EC"
              : selectedOptions2.length > 0
                ? "#F4F6FF"
                : "transparent",
          "&:hover": {
            backgroundColor: selectedOptions2.length > 0 ? "" : "#ffa7371f",
          },
        }}
      >
        Campus {selectedOptions2.length > 0 && `(${selectedOptions2.length})`}
        <Box
          sx={{
            marginTop: "-3px",
          }}
        >
          <IconComponent name="userArrowDown" fill={"#8E8D8A"} />
        </Box>
      </Box>

      <Popover
        id={id2}
        open={open2}
        anchorEl={anchorEl2}
        onClose={handleClose2}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        sx={{
          ".MuiPaper-root": {
            minWidth: "182px",
            border: "1px solid #D2D2D0",
            maxHeight: "318px",
            top: "406px",
          },
          ".MuiButtonBase-root": {
            padding: "0px !important",
            marginRight: "8px",
            marginTop: "3px",
          },
          ".css-3bmhjh-MuiPaper-root-MuiPopover-paper": {
            border: "1px solid #D2D2D0",
            borderRadius: "8px",
            overflowY: "scroll",
          },
          ".css-1s8ndmz": {
            padding: "8px 16px 8px 12px",
            borderBottom: "1px solid #D2D2D0",
            cursor: "pointer",
            "&:hover": {
              backgroundColor: "#ffa7371f",
            },
          },
        }}
      >
        <div>
          <Box
            sx={{
              ".MuiBox-root": {
              },
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                background: "#F4F6FF",
                padding: "12px 16px 12px 12px",
              }}
            >
              <Typography
                sx={{
                  fontSize: "12px",
                  fontWeight: "500",
                  color: "#696969",
                }}
              >
                {selectedOptions2.length > 0
                  ? `${selectedOptions2.length} Selected`
                  : "0 Selected"}
              </Typography>
              {selectedOptions2.length > 0 && (
                <button
                  style={{
                    padding: "0px",
                    background: "transparent",
                    border: "none",
                    fontSize: "12px",
                    fontWeight: "600",
                    color: "#333",
                    borderBottom: "2px solid #FFA737",
                    cursor: "pointer",
                  }}
                  onClick={handleClearAll2}
                >
                  Clear All
                </button>
              )}
            </div>
          </Box>

          {campusData.map((option: campOption) => (
            <Box
              key={option.id}
              sx={{
                ".MuiBox-root": {
                  padding: "12px 12px 16px 12px",
                },
              }}
              style={{
                background: selectedOptions2.includes(option.campus_name)
                  ? "#FFF8EC"
                  : "",
              }}
              onClick={() => handleOptionClick2(option.campus_name)}
            >
              <Checkbox
                checked={selectedOptions2.includes(option.campus_name)}
                onChange={() => handleOptionClick2(option.campus_name)}
                icon={<CustomCheckbox checked={false} />}
                checkedIcon={
                  <CustomCheckbox checked style={{ fill: "##333333" }} />
                }
              />
              <span
                style={{
                  fontSize: "14px",
                  fontWeight: "400",
                  color: "#333",
                  textTransform: "capitalize",
                }}
              >
                {option.campus_name}
              </span>
            </Box>
          ))}
        </div>
      </Popover>

      <Box
        onClick={handleClick3}
        sx={{
          width: "190px",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          borderRadius: "8px",
          border:
            anchorEl3 !== null ? "1px solid #FFA737" : "1px solid #D2D2D0",
          padding: "4.5px 12px 4.5px 12px",
          fontSize: "14px",
          fontWeight: "400",
          marginRight: "8px",
          color: "#696969",
          cursor: "pointer",
          backgroundColor:
            anchorEl3 !== null
              ? "#FFF8EC"
              : selectedOptions3.length > 0
                ? "#F4F6FF"
                : "transparent",
          "&:hover": {
            backgroundColor: selectedOptions3.length > 0 ? "" : "#ffa7371f",
          },
        }}
      >
        Enrolment status
        {selectedOptions3.length > 0 && `(${selectedOptions3.length})`}
        <Box
          sx={{
            marginTop: "-3px",
          }}
        >
          <IconComponent name="userArrowDown" fill={"#8E8D8A"} />
        </Box>
      </Box>

      <Popover
        id={id3}
        open={open3}
        anchorEl={anchorEl3}
        onClose={handleClose3}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        sx={{
          ".MuiPaper-root": {
            minWidth: "182px",
            border: "1px solid #D2D2D0",
            maxHeight: "318px",
            top: "406px",
          },
          ".MuiBox-root": {
            paddingRight: "12px",
          },
          ".css-0": {
            cursor: "pointer",
            borderBottom: "1px solid #D2D2D0",
            "&:hover": {
              background: "#ffa7371f",
            },
          },
          ".css-3bmhjh-MuiPaper-root-MuiPopover-paper": {
            border: "1px solid #D2D2D0",
            borderRadius: "8px",
          },
          ".css-1s8ndmz": {
            padding: "8px 16px 8px 12px",
            borderBottom: "1px solid #D2D2D0",
            cursor: "pointer",
            "&:hover": {
              backgroundColor: "#ffa7371f",
            },
          },
          ".css-12xgrea-MuiButtonBase-root-MuiCheckbox-root": {
            padding: "0px",
            marginRight: "8px",
          },
        }}
      >
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              background: "#F4F6FF",
              padding: "12px 16px 12px 12px",
            }}
          >
            <Typography
              sx={{
                fontSize: "12px",
                fontWeight: "500",
                color: "#696969",
              }}
            >
              {selectedOptions3.length > 0
                ? `${selectedOptions3.length} Selected`
                : "0 Selected"}
            </Typography>
            {selectedOptions3.length > 0 && (
              <button
                style={{
                  padding: "0px",
                  background: "transparent",
                  border: "none",
                  fontSize: "12px",
                  fontWeight: "600",
                  color: "#333",
                  borderBottom: "2px solid #FFA737",
                  cursor: "pointer",
                }}
                onClick={handleClearAll3}
              >
                Clear All
              </button>
            )}
          </div>

          {EnrolmentOptions.map((option) => (
            <Box
              key={option.label}
              style={{
                background: selectedOptions3.includes(option.label)
                  ? "#FFF8EC"
                  : "",
              }}
              onClick={() => handleOptionClick3(option.label)}
            >
              <Checkbox
                checked={selectedOptions3.includes(option.label)}
                onChange={() => handleOptionClick3(option.label)}
                icon={<CustomCheckbox checked={false} />}
                checkedIcon={
                  <CustomCheckbox checked style={{ fill: "##333333" }} />
                }
              />
              <span
                style={{
                  fontSize: "14px",
                  fontWeight: "400",
                  color: "#333",
                }}
              >
                {option.label}
              </span>
            </Box>
          ))}
        </>
      </Popover>
      <Typography
        onClick={handleClearAll}
        sx={{
          fontSize: "14px",
          fontWeight: "600",
          color: "#333",
          borderBottom: "2px solid #FFA737",
          cursor: "pointer",
        }}
      >
        Clear all
      </Typography>
    </Box>
  );
};

export default FilteredComponentDD;
